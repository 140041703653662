import React, { FC } from 'react';

import { RequiredFieldMarkerProps } from './RequiredFieldMarker.props';

const RequiredFieldMarkerView: FC<RequiredFieldMarkerProps> = (props) => {
  const { isRequired } = props;

  return (
    <>{isRequired ? <span className="text-red-500 ml-1">*</span> : <></>}</>
  );
};

export default RequiredFieldMarkerView;
