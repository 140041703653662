import React, { FC } from 'react';

import { CategorySectionProps } from './CategorySection.props';

import CategorySectionView from './CategorySection.view';
import { useTopCategory } from 'api/queries';

const CategorySectionContainer: FC<CategorySectionProps> = (props) => {
  const { data, isLoading } = useTopCategory();
  return <CategorySectionView data={data?.item ?? []} isLoading={isLoading} />;
};

export default CategorySectionContainer;
