import React, { FC } from 'react';

import { BadgeProps } from './Badge.props';

const BadgeView: FC<BadgeProps> = ({
  value,
  bgColor,
  textColor,
  className,
}) => {
  return (
    <div
      style={{
        backgroundColor: bgColor ?? 'primary',
      }}
      className={`px-2 py-1 ${className}  text-white flex justify-center items-center`}>
      <span
        className={`text-lg font-semibold text-center text-${textColor} capitalize`}
        style={{
          color: textColor ?? 'white',
        }}>
        {value}
      </span>
    </div>
  );
};

export default BadgeView;
