type DeleteIconProps = {
  w?: string;
  h?: string;
};

function DeleteIcon({ w, h }: DeleteIconProps) {
  return (
    <svg
      width={w ? w : '189'}
      height={h ? h : '80'}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="34" rx="8" fill="#FFE8E8" />
      <mask
        id="mask0_1093_3029"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="24"
        height="24">
        <rect x="5" y="5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1093_3029)">
        <path
          d="M12 26C11.45 26 10.9792 25.8042 10.5875 25.4125C10.1958 25.0208 10 24.55 10 24V11C9.71667 11 9.47917 10.9042 9.2875 10.7125C9.09583 10.5208 9 10.2833 9 10C9 9.71667 9.09583 9.47917 9.2875 9.2875C9.47917 9.09583 9.71667 9 10 9H14C14 8.71667 14.0958 8.47917 14.2875 8.2875C14.4792 8.09583 14.7167 8 15 8H19C19.2833 8 19.5208 8.09583 19.7125 8.2875C19.9042 8.47917 20 8.71667 20 9H24C24.2833 9 24.5208 9.09583 24.7125 9.2875C24.9042 9.47917 25 9.71667 25 10C25 10.2833 24.9042 10.5208 24.7125 10.7125C24.5208 10.9042 24.2833 11 24 11V24C24 24.55 23.8042 25.0208 23.4125 25.4125C23.0208 25.8042 22.55 26 22 26H12ZM15 22C15.2833 22 15.5208 21.9042 15.7125 21.7125C15.9042 21.5208 16 21.2833 16 21V14C16 13.7167 15.9042 13.4792 15.7125 13.2875C15.5208 13.0958 15.2833 13 15 13C14.7167 13 14.4792 13.0958 14.2875 13.2875C14.0958 13.4792 14 13.7167 14 14V21C14 21.2833 14.0958 21.5208 14.2875 21.7125C14.4792 21.9042 14.7167 22 15 22ZM19 22C19.2833 22 19.5208 21.9042 19.7125 21.7125C19.9042 21.5208 20 21.2833 20 21V14C20 13.7167 19.9042 13.4792 19.7125 13.2875C19.5208 13.0958 19.2833 13 19 13C18.7167 13 18.4792 13.0958 18.2875 13.2875C18.0958 13.4792 18 13.7167 18 14V21C18 21.2833 18.0958 21.5208 18.2875 21.7125C18.4792 21.9042 18.7167 22 19 22Z"
          fill="#F04747"
        />
      </g>
    </svg>
  );
}

export default DeleteIcon;
