import { Product } from 'types/item';

export const generateInitialDataFrom: any = (item: Product) => {
  return {
    title: item.title,
    category: item.categories[0]._id,
    subCategory: item.subCategories[0]._id,
    modelNumber: item.modelNumber,
    serialNumner: item.serialNumber,
    guidelines: item.guidelines,
    description: item.description,
    address: item.location ?? '',
    rentalPrice: item.price,
    state:
      typeof item.location !== 'string' ? item.location?.state?._id ?? '' : '',
    location: typeof item.location !== 'string' ? item.location?._id ?? '' : '',
    photos: item.photos ?? [],
    ownersManual: item.link,
    includedInRental: item.includedInRental ?? [],
    deliveryOptions: {
      pickup: item?.deliveryOptions?.pickup ?? false,
      delivery: item?.deliveryOptions?.delivery ?? false,
      publicMeetup: item?.deliveryOptions?.publicMeetup ?? false,
    },
  };
};
