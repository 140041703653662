import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useCheckoutInfoStore } from 'state/atoms';
import { Elements } from '@stripe/react-stripe-js';
import ErrorFallback from 'components/primitives/ErrorFallback';
import getStripe from 'utils/getStripe';
import { $user } from 'state/stores/user';
import Loading from 'components/primitives/Loading';

type Props = PropsWithChildren & {
  orderId: string;
  paymentId: string;
  ownersStripeId: string;
  isLoading: boolean;
  client_secret: string;
};

const StripeProvider: FC<Props> = ({
  children,
  orderId,
  paymentId,
  client_secret,
  ownersStripeId,
  isLoading,
}) => {
  if (isLoading) {
    return <Loading />;
  }

  if (!client_secret) {
    return <ErrorFallback />;
  }

  if (!ownersStripeId) {
    return (
      <ErrorFallback
        statusCode={500}
        message="Host Stripe Account is not setup"
      />
    );
  }

  const options = {
    clientSecret: client_secret,
  };

  return (
    <div>
      <Elements stripe={getStripe(ownersStripeId)} options={options}>
        {children}
      </Elements>
    </div>
  );
};

export default StripeProvider;
