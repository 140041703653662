import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const initialValues = {
  username: '',
  password: '',
};
