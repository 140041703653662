import { AccountCreatedProps } from './AccountCreated.props';

import useConnectToStripeMutation from 'api/mutations/useConnectToStripeMutation';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import $session from 'state/stores/session/store';
import { $user } from 'state/stores/user';
import AccountCreatedView from './AccountCreated.view';

const AccountCreatedContainer = (props: AccountCreatedProps) => {
  const navigate = useNavigate();

  const stripeId = $user.selectors.useStripeId();

  const doItLater = (): void => {
    $session.actions.setAuth();
    navigate(`${routesSettings.HOME.path}?onboarding=true`, {
      replace: true,
    });
  };

  const { mutate: connectStripeAccount } = useConnectToStripeMutation(
    stripeId!,
    window.location.origin + '/home?fromStripe=true&onboarding=true',
  );

  return (
    <AccountCreatedView
      doItLater={doItLater}
      connectStripeAccount={connectStripeAccount}
    />
  );
};

export default AccountCreatedContainer;
