import TabsLayout from 'components/primitives/TabsLayout';
import { FC } from 'react';
import { ProfileViewProps } from './Profile.props';
import ProfileTabContentContainer from './profileTab/ProfileTabContent.container';

const ProfileView: FC<ProfileViewProps> = (props) => {
  const tabs = [
    {
      id: 'profile',
      label: 'Profile',
      component: <ProfileTabContentContainer />,
    },
    // { id: 'cards', label: 'Cards', component: <CardsTabContent /> },
  ];

  return <TabsLayout tabs={tabs} />;
};

export default ProfileView;
