import { forwardRef, memo, useImperativeHandle, useRef } from 'react';

import { CommonModalProps, CommonModalRef } from './CommonModal.props';

const View = forwardRef<CommonModalRef, CommonModalProps>((props, ref) => {
  const {
    children,
    isDefaultOpen = false,
    isCloseButton = true,
    closeOnBackdropClick = false,
    className,
    onSuccessFullyClosed,
    autoClose = false,
  } = props;

  const dialogRef = useRef<HTMLDialogElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      onOpen: () => {
        dialogRef.current?.showModal?.();
      },
      onClose: () => {
        dialogRef.current?.close();
      },
    }),
    [],
  );

  const onClose = (e: any) => {
    dialogRef.current?.close();
    onSuccessFullyClosed && onSuccessFullyClosed(e);
  };

  return (
    <dialog
      ref={dialogRef}
      className="modal bg-[#00000050]"
      open={isDefaultOpen}>
      <div
        style={{
          background:
            'linear-gradient(331deg, rgba(253,234,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 70%, rgba(207,255,232,1) 100%)',
        }}
        className={`modal-box z-10 rounded-[25px] ${className} max-w-5xl scroll-m-7`}>
        {isCloseButton && (
          <div
            onClick={onClose}
            className="btn btn-sm btn-circle btn-ghost absolute cursor-pointer right-2 top-2">
            ✕
          </div>
        )}
        <div>{children}</div>
      </div>
      {closeOnBackdropClick && (
        <div className="modal-backdrop h-[100vh]">
          <div onClick={onClose}>close modal</div>
        </div>
      )}
    </dialog>
  );
});

const CommonModal = memo(View);

export default CommonModal;
