import { uploadItemImages } from 'api/services/item';

export class ImageUploadHelper {
  private photos: (string | File)[];
  private featuredPhoto: string;
  constructor(photos: (string | File)[], featuredPhoto: string) {
    this.photos = photos;
    this.featuredPhoto = featuredPhoto;
  }

  private setFeaturedImage = (data: string) => {
    this.featuredPhoto = data;
  };

  private segregatePhotos = () => {
    let photoList = [...this.photos];
    const oldPhotos = [];
    const newPhotos = [];
    let index = -1;
    for (let i = 0; i < photoList.length; i++) {
      const element = photoList[i];
      let fileName;
      if (typeof element === 'string') {
        fileName = element;
        if (this.featuredPhoto !== fileName) {
          oldPhotos.push(element);
        }
      } else {
        fileName = element.name;
        if (this.featuredPhoto !== fileName) {
          newPhotos.push(element);
        }
      }
      if (this.featuredPhoto === fileName) {
        index = i;
      }
    }
    const featuredPhoto = this.photos[index];
    return {
      newPhotos,
      oldPhotos,
      featuredPhoto,
    };
  };

  async upload(): Promise<UploadResponse | null> {
    try {
      const data = this.segregatePhotos();

      const uploadImages = await uploadItemImages(data.newPhotos as File[]);

      const featuredImagesToUpload =
        typeof data.featuredPhoto !== 'string' ? [data.featuredPhoto] : [];

      const uploadfeaturedPhoto = await uploadItemImages(
        featuredImagesToUpload,
      );

      if (uploadfeaturedPhoto.length > 0) {
        data.featuredPhoto = uploadfeaturedPhoto[0];
      }

      const photosWithoutFeatured = data.oldPhotos
        .concat(uploadImages)
        .filter((photo) => photo !== data.featuredPhoto);

      return Promise.resolve({
        photos: photosWithoutFeatured,
        featuredPhoto: data.featuredPhoto,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  validate(onFailed: (message: string) => void) {
    if (this.photos.length < 3) {
      onFailed('Please select at least 3 photos');
      return false;
    } else {
      if (this.featuredPhoto === '') {
        onFailed('Please select featured image');
        return false;
      }
    }
    return true;
  }
}

type UploadResponse = {
  photos: any[];
  featuredPhoto: any;
};
