import { useQuery } from '@tanstack/react-query';
import { fetchOwnerReviewRates } from 'api/services/rates';

const FETCH_OWNER_REVIEW_RATES = 'fetch-owner-review-rates';

const useOwnerReviewRatesQuery = () => {
  const query = useQuery({
    queryKey: [FETCH_OWNER_REVIEW_RATES],
    queryFn: () => fetchOwnerReviewRates(),
  });

  return query;
};

export default useOwnerReviewRatesQuery;

export { FETCH_OWNER_REVIEW_RATES };
