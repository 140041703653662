import { useQuery } from '@tanstack/react-query';
import { getItemsReviews, getOwnerReviews } from 'api/services/reviews';

const FETCH_OWNER_REVIEWS = 'fetch-owner-reviews';

const useOwnerReviewsQuery = (id: string) => {
  const query = useQuery({
    queryKey: [FETCH_OWNER_REVIEWS, id],
    queryFn: () => getOwnerReviews(id),
  });
  return query;
};

export default useOwnerReviewsQuery;

export { FETCH_OWNER_REVIEWS };
