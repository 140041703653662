import { SubCategoriesResponse, SubCategory } from 'types/subCategory';
import { api } from './instance';

export const fetchSubCategories = async (
  categoryId?: string,
): Promise<SubCategoriesResponse> => {
  const { data } = await api.get<SubCategoriesResponse>(
    `/sub-categories/${categoryId ?? ''}`,
  );
  return data;
};
