import { useCallback, useRef, useState } from 'react';

import { CreateItemProps } from './CreateItem.props';

import CreateItemView from './CreateItem.view';

import { useMutation } from '@tanstack/react-query';
import {
  QUERY_KEYS,
  refetchQuery,
  useCategories,
  useSubCategory,
} from 'api/queries';
import { createItem } from 'api/services/item';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import SuccessModal from 'components/modals/SuccessModal';
import { ImageUploadHelper } from 'components/modules/ImagePicker/helpers/imageUploadHelper';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router';
import $session from 'state/stores/session/store';
import { $user } from 'state/stores/user';
import { ItemPayload } from 'types/item';
import { initialValues } from './utils';

const CreateItemContainer = (props: CreateItemProps) => {
  const { data: categories, isLoading: isGetCategoriesLoading } =
    useCategories();

  const ownerName = $user.selectors.getFullName();

  const navigate = useNavigate();

  const successModalRef = useRef<CommonModalRef>(null);

  const [selectedId, setSelectedID] = useState<string | ''>('');

  const { data: subCategories } = useSubCategory(selectedId);

  const onChangeCategory = useCallback((categoryId: string) => {
    setSelectedID(categoryId);
  }, []);

  const { mutate, isPending } = useMutation({
    mutationFn: async (payload: typeof initialValues) => {
      // todo: add owner
      const ownerId = $session.actions.getUserId();

      const imageUploadHelper = new ImageUploadHelper(
        payload.photos,
        payload.featuredPhoto,
      );

      const imageUploadResponse = await imageUploadHelper.upload();

      const finalPayload: ItemPayload = {
        title: payload.title,
        owner: ownerName,
        ownerId: ownerId!,
        location: payload.location!,
        photos: imageUploadResponse?.photos,
        featuredPhoto: imageUploadResponse?.featuredPhoto,
        modelNumber: payload.modelNumber,
        serialNumber: payload.serialNumber,
        guidelines: payload.guidelines,
        deliveryOptions: payload.deliveryOptions,
        price: parseInt(payload.rentalPrice),
        link: payload.ownersManual,
        description: payload.description,
        categories: [payload.category],
        includedInRental: payload.includedInRental,
        subCategories: [payload.subCategory],
      };

      const response = await createItem(finalPayload);

      QUERY_KEYS.invalidate(QUERY_KEYS.FETCH_OWNER_ITEMS_KEY);
      return response;
    },
    onSuccess: () => {
      successModalRef.current?.onOpen?.();
      setTimeout(async () => {
        await refetchQuery(QUERY_KEYS.FETCH_OWNER_ITEMS_KEY);
        successModalRef.current?.onClose!();
        navigate(routesSettings.LEND.path);
      }, 3000);
    },
  });

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div>
      <CreateItemView
        subCategories={subCategories?.item ?? []}
        onChangeCategory={onChangeCategory}
        categories={categories?.items ?? []}
        isCategoryLoading={isGetCategoriesLoading}
        creationInProgress={isPending}
        onSubmit={mutate}
        onCancel={onCancel}
      />
      <SuccessModal
        title="Item successfully created!"
        ref={successModalRef}
        // onClose={onCloseSuccessModal}
      />
    </div>
  );
};

export default CreateItemContainer;
