import {
  LoginPayload,
  LoginResponse,
  RegisterPayload,
  RegisterResponse,
  User,
} from 'types/auth';
import { api } from './instance';

export const login = async (payload: LoginPayload) => {
  const { data } = await api.post<LoginResponse>(`/auth/login`, payload);
  return data?.item;
};

export const register = async (payload: RegisterPayload) => {
  const { data } = await api.post<RegisterResponse>(`/auth/register`, payload);

  const loginResponse = await login({
    username: payload.email,
    password: payload.password,
  });

  const response: User = {
    ...data.item,
    tokens: loginResponse.tokens,
    profileId: loginResponse.profileId,
  };

  return response;
};
