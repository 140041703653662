import { FC } from 'react';

import MobileMenu from 'components/modules/MobileMenu';
import Footer from 'components/primitives/Footer';
import useIsMobile from 'hooks/useIsMobile';
import { PrivacyPolicyViewProps } from './PrivacyPolicy.props';

const PrivacyPolicyView: FC<PrivacyPolicyViewProps> = (props) => {
  const isMobile = useIsMobile();
  return (
    <>
      <iframe
        style={{
          width: '100vw',
          height: '100vh',
        }}
        src="/privacy-policy.html"></iframe>
      {isMobile ? <MobileMenu /> : <Footer />}
    </>
  );
};

export default PrivacyPolicyView;
