import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { persist } from 'zustand/middleware';
import { UserDetail } from 'types/user';
import { City } from 'types/location';

type State = {
  user: UserDetail | null;
};

const STORAGE_KEY = 'RAPID-RENTALS-USER';

const useUserStore = create(
  persist(
    immer<State>(() => ({
      user: null,
    })),
    {
      name: STORAGE_KEY,
    },
  ),
);

const selectors = {
  useUser: () => useUserStore((state) => state.user),
  useStripeId: () => useUserStore((state) => state.user?.stripeConnectId),
  getFullName: () =>
    useUserStore.getState().user?.fname +
    ' ' +
    useUserStore.getState().user?.lname,
  useIsAddressSet: () => {
    const user = useUserStore((state) => state.user);
    return !!user?.userDetails?.address;
  },
  useLocation: () => {
    const user = useUserStore((state) => state.user);
    const address = user?.userDetails?.address;
    const stateId = typeof address !== 'string' ? address?.state?._id : '';
    const cityId = typeof address !== 'string' ? address?._id : '';
    return { stateId, cityId };
  },
};

const actions = {
  setUser: (user: UserDetail) => {
    const set = useUserStore.setState;
    set((state) => {
      state.user = { ...state.user, ...user };
    });
  },
  getStripeId: () => useUserStore.getState().user?.stripeConnectId,
  setAddress: (newAddress: City) => {
    const setState = useUserStore.setState;
    const currentUser = useUserStore.getState().user;

    if (currentUser) {
      setState((state) => ({
        user: { ...state.user, userDetails: { address: newAddress } },
      }));
    }
  },
  clearUserData: () => {
    const setState = useUserStore.setState;
    setState((state) => ({
      user: null,
    }));
  },
  getProfileId: () => {
    return useUserStore.getState().user?.profileId;
  },
};

/**
 * **User Store**
 *
 * this store is used to store user data
 *
 * **"$"** is a convention to indicate that this is a store
 */

const $user = {
  actions,
  selectors,
};

export default $user;
