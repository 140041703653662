import { FC } from 'react';

import CalendarIcon from 'components/icons/CalendarIcon';
import CheckIcon from 'components/icons/CheckIcon';
import ClockIcon from 'components/icons/ClockIcon';
import HandBagIcon from 'components/icons/HandBagIcon';
import { TimelineProps } from './Timeline.props';

const statusList = ['booked', 'rental_in_progress', 'returned', 'completed'];

const TimelineView: FC<TimelineProps> = ({ status }) => {
  const timeLineStatus: any = {
    booked: {
      icon: <CalendarIcon />,
      text: 'Item Booked',
      status: false,
      disabled: true,
    },
    rental_in_progress: {
      icon: <ClockIcon />,
      text: 'Rental In Progress',
      status: false,
      disabled: true,
    },
    returned: {
      icon: <HandBagIcon />,
      text: 'Item Returned',
      status: false,
      disabled: true,
    },
    completed: {
      icon: <CheckIcon />,
      text: 'Completed',
      status: false,
      disabled: true,
    },
  };

  const timeLineStatusObject = { ...timeLineStatus };
  //set all status falst

  // Check if the status is a valid key in timeLineStatusObject
  if (timeLineStatus.hasOwnProperty(status)) {
    let found = false; // flag to indicate if we've found the current status
    Object.keys(timeLineStatusObject).forEach((key) => {
      if (!found) {
        timeLineStatusObject[key].status = true;
        timeLineStatusObject[key].disabled = false;
        if (key === status) found = true;
      } else {
        timeLineStatusObject[key].status = false;
      }
    });
  }

  return (
    <div className="w-full flex flex-col items-start">
      <ul className="steps w-full steps-horizontal mt-28 overflow-visible py-8">
        {statusList?.map((status, index) => {
          const timeData = timeLineStatusObject[status];
          return (
            <li
              key={index}
              data-content=""
              style={{
                marginLeft: 0,
              }}
              className={`relative step ${timeData.status ? 'step-primary' : ''} z-1`}>
              <div className="absolute flex flex-col -top-28 justify-center items-center  overflow-visible gap-4">
                <div
                  className="icon-container z-1"
                  style={{
                    filter: timeData.disabled ? 'grayscale(1)' : 'grayscale(0)',
                  }}>
                  {timeData.icon}
                </div>
                <div className="text-center z-1 text-neutral-700 text-xs font-normal font-secondary capitalize">
                  {timeData.text}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TimelineView;
