import React from 'react';

function MailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20">
      <mask
        id="mask0_2610_101111"
        style={{ maskType: 'alpha' }}
        width="21"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse">
        <path fill="#515151" d="M0.438 0H20.438V20H0.438z"></path>
      </mask>
      <g mask="url(#mask0_2610_101111)">
        <path
          fill="#515151"
          d="M3.76 16.666c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 012.093 15V5c0-.459.164-.851.49-1.177.327-.327.72-.49 1.177-.49h13.334c.458 0 .85.163 1.177.49.326.326.49.718.49 1.177v10c0 .458-.164.85-.49 1.177-.327.326-.719.49-1.177.49H3.76zm6.667-5.833L3.76 6.666V15h13.334V6.666l-6.667 4.167zm0-1.667L17.094 5H3.76l6.667 4.166zm-6.667-2.5V5v10-8.334z"></path>
      </g>
    </svg>
  );
}

export default MailIcon;
