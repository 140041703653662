import { $user } from 'state/stores/user';
import { UserDetail } from 'types/user';
import * as Yup from 'yup';

export const deliveryOptions = [
  {
    name: 'deliveryOptions.pickup',
    label: `Pickup`,
  },
  {
    name: 'deliveryOptions.delivery',
    label: `Delivery`,
  },
  {
    name: 'deliveryOptions.publicMeetup',
    label: 'Public Meetup',
  },
];

export const generateInitialData = (userDetail: UserDetail) => {
  const location = $user.selectors.useLocation();
  return {
    title: '',
    category: '',
    subCategory: '',
    modelNumber: '',
    serialNumber: '',
    guidelines: '',
    address: userDetail?.userDetails?.address ?? '',
    description: '',
    rentalPrice: '',
    photos: [],
    state: location.stateId,
    location: location.cityId,
    ownersManual: '',
    featuredPhoto: '',
    includedInRental: [],
    termsAndConditions: false,
    deliveryOptions: {
      pickup: false,
      delivery: false,
      publicMeetup: false,
    },
  };
};

export const initialValues = {
  title: '',
  category: '',
  subCategory: '',
  modelNumber: '',
  serialNumber: '',
  guidelines: '',
  address: '',
  description: '',
  rentalPrice: '',
  photos: [],
  location: '',
  ownersManual: '',
  featuredPhoto: '',
  includedInRental: [],
  termsAndConditions: false,
  deliveryOptions: {
    pickup: false,
    delivery: false,
    publicMeetup: false,
  },
};

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const validationSchema = Yup.object().shape({
  title: Yup.string().max(70).required('Title is required'),
  category: Yup.string().required('Category is required'),
  state: Yup.string().required('State is required'),
  location: Yup.string().required('City is required'),
  subCategory: Yup.string().required('Sub category is required'),
  modelNumber: Yup.string().required('Model Number is required'),
  rentalPrice: Yup.number()
    .required('Rental Price is required')
    .min(5, 'Minimum price is $5')
    .max(250, 'Maximum price is $250'),
  deliveryOptions: Yup.object()
    .shape({
      pickup: Yup.boolean(),
      delivery: Yup.boolean(),
      publicMeetup: Yup.boolean(),
    })
    .test(
      'at-least-one-true',
      'At least one option should be selected',
      (options) => options.pickup || options.delivery || options.publicMeetup,
    ),
  description: Yup.string().max(500).required('Description is required'),
});
