import { FC } from 'react';

import View from 'components/core/View/View.view';
import ProductReviewsSection from 'components/modules/ProductReviewsSection';
import Questions from 'components/modules/Questions';
import RentedProductDetails from 'components/modules/RentedProductDetails';
import TimeLineTracker from 'components/modules/TimeLineTracker';
import CommonContainer from 'components/primitives/CommonContainer';
import Loading from 'components/primitives/Loading';
import { ItemHistoryViewProps } from './ItemHistory.props';

const ItemHistoryView: FC<ItemHistoryViewProps> = ({ isLoading, product }) => {
  if (isLoading) {
    return <Loading />;
  }

  return (
    <View.Container>
      <div className="space-y-8">
        <RentedProductDetails />
        <TimeLineTracker />
        <CommonContainer>
          <ProductReviewsSection productId={product._id} />
        </CommonContainer>
        <CommonContainer>
          <Questions product={product} />
        </CommonContainer>
      </div>
    </View.Container>
  );
};

export default ItemHistoryView;
