import { IconProps } from './type';

const ArrowLeft = (props: IconProps) => {
  return (
    <svg
      className="cursor-pointer"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.2998 11.2969L0.699804 6.69687C0.599804 6.59687 0.529138 6.48854 0.487804 6.37187C0.445804 6.25521 0.424805 6.13021 0.424805 5.99687C0.424805 5.86354 0.445804 5.73854 0.487804 5.62187C0.529138 5.50521 0.599804 5.39687 0.699804 5.29688L5.2998 0.696875C5.48314 0.513541 5.71647 0.421875 5.9998 0.421875C6.28314 0.421875 6.51647 0.513541 6.6998 0.696875C6.88314 0.880208 6.9748 1.11354 6.9748 1.39687C6.9748 1.68021 6.88314 1.91354 6.6998 2.09688L2.7998 5.99687L6.6998 9.89687C6.88314 10.0802 6.9748 10.3135 6.9748 10.5969C6.9748 10.8802 6.88314 11.1135 6.6998 11.2969C6.51647 11.4802 6.28314 11.5719 5.9998 11.5719C5.71647 11.5719 5.48314 11.4802 5.2998 11.2969Z"
        fill={props.color ?? '#075015'}
      />
    </svg>
  );
};

export default ArrowLeft;
