import React, { FC } from 'react';
import BadgeView from 'components/primitives/StatusBadge/Badge.view';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { getNetworkImage } from 'utils/assets';
import { RentedItemsHistoryViewProps } from './RentedItemsHistory.props';
import StarIcon from 'components/icons/StarIcon';
import { format, parseISO } from 'date-fns';
import { limitCharacters } from 'utils/limitCharacters';
import Loading from 'components/primitives/Loading';
import Table from 'components/primitives/Table';
import View from 'components/core/View/View.view';
import Pagination from 'components/primitives/Pagination';
import { $session } from 'state/stores';
import NoRentalHistoryFound from 'components/vectors/NoRentalHistoryFound';

const RentedItemsHistoryView: FC<RentedItemsHistoryViewProps> = ({
  products,
  filteredItems,
  totalPages,
  currentPage,
  isLoading,
  setPage,
}) => {
  const navigate = useNavigate();
  const ownerId = $session.actions.getUserId();

  const showProductDetails = (index: number) => {
    const product = filteredItems![index]._id;
    navigate(routesSettings.MAIN_ITEM_HISTORY.getPath(product!), {
      replace: false,
    });
  };

  const getRentedHistoryById = (id: string) => {
    const rentedItem = products?.find(
      (rentedProduct) => rentedProduct.item._id === id,
    );

    return rentedItem;
  };

  const getFormattedLendDates = (id: string) => {
    const start = getRentedHistoryById(id)?.startDate;
    const end = getRentedHistoryById(id)?.endDate;

    const parsedStartdate = parseISO(start!);
    const parsedEnddate = parseISO(end!);

    const startDate = format(parsedStartdate, 'MMM dd, yyyy');
    const endDate = format(parsedEnddate, 'MMM dd, yyyy');

    return `${startDate} - ${endDate}`;
  };

  return (
    <>
      <View.Container>
        <div className="space-y-8">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Table
                itemNotFoundComponent={
                  <NoRentalHistoryFound description="You haven’t rented anything yet. Your rental history will be displayed here once you complete a rental." />
                }
                headers={[
                  'Image',
                  'Name',
                  'Description',
                  // 'Rate',
                  'Price',
                  'Status',
                  'Lended Date',
                ]}
                itemCount={filteredItems?.length ?? 0}
                onClickItem={(index) => showProductDetails(index)}
                builder={function (index: number): React.ReactNode {
                  const product = filteredItems![index].item;
                  const history = filteredItems![index];
                  return (
                    <>
                      <td className="whitespace-nowrap  px-6 py-4 font-medium">
                        <img
                          className="w-10 h-10 rounded-md object-cover"
                          src={getNetworkImage(
                            product.featuredPhoto ?? product.photos[0]!,
                          )}
                          alt={product.title}
                        />
                      </td>
                      <td className="whitespace-nowrap text-sm text-black-700 font-medium font-primary capitalize  px-6 py-4">
                        {product.title}
                      </td>
                      <td className="whitespace-nowrap text-sm text-black-700 font-medium font-primary capitalize  px-6 py-4">
                        {limitCharacters(product.description, 60)}
                      </td>
                      {/* <td className="whitespace-nowrap text-sm text-gray-700 font-medium font-primary capitalize px-6 py-4 ">
                    <div className="flex flex-row justify-center items-end">
                      <StarIcon />
                      {product.rate.toFixed(1)}
                    </div>
                  </td> */}
                      <td className="whitespace-nowrap text-sm text-gray-700 font-medium font-primary capitalize px-6 py-4 ">
                        ${product.price} / Day
                      </td>
                      <td className="whitespace-nowrap py-4">
                        <BadgeView
                          value={
                            history.currentStatus == 'booked'
                              ? product.ownerId == ownerId
                                ? 'Rented'
                                : 'Booked'
                              : history.currentStatus
                          }
                          textColor="white"
                          bgColor={`${history.currentStatus === 'booked' ? 'rgba(234, 124, 22, 0.5)' : '#22CC7A'}`}
                          className="rounded-full w-24"
                        />
                      </td>
                      <td className="whitespace-nowrap  text-sm text-black-700 font-medium font-primary capitalize px-6 py-4 ">
                        <span>{getFormattedLendDates(product._id)}</span>
                      </td>
                    </>
                  );
                }}
              />

              <div className="flex justify-end items-center space-x-4">
                <Pagination
                  totalPages={totalPages!}
                  currentPageNo={currentPage!}
                  onPageChange={function (page: number): void {
                    setPage && setPage(page);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </View.Container>
    </>
  );
};

export default RentedItemsHistoryView;
