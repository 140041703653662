import React, { FC } from 'react';

import { LinearPercentageProps } from './LinearPercentage.props';

const LinearPercentageView: FC<LinearPercentageProps> = ({ percent }) => {
  return (
    <div className="w-full h-2 rounded-lg bg-[#d9d9d9] relative">
      <div
        style={{
          width: `${percent}%`,
        }}
        className="h-2 rounded-lg bg-primary-500 absolute"></div>
    </div>
  );
};

export default LinearPercentageView;
