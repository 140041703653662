import { IconProps } from './type';

const RrIcon = (props: IconProps) => {
  return (
    <svg
      width="250"
      viewBox="0 0 31 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4483 12.3865C29.768 11.113 30.5038 9.40143 30.5201 7.56698C30.5363 5.73253 29.831 4.00794 28.5335 2.71044L28.4815 2.65843C27.2002 1.37718 25.4971 0.671875 23.6861 0.671875H4.24884L2.14982 4.05215H23.6861C24.5942 4.05215 25.4484 4.40578 26.0919 5.04933L26.1439 5.10133C26.7946 5.75203 27.1482 6.6166 27.1404 7.53708C27.132 8.4569 26.7628 9.31562 26.101 9.95462C25.4633 10.5696 24.6247 10.9083 23.7387 10.9083H15.1379H15.0625H14.9812H11.1589L11.0802 11.035L9.05858 14.2885L5.44234 20.1084H9.42131L13.0376 14.2885L13.0701 14.2365L13.1019 14.2885L16.7181 20.1084H20.6978L17.0815 14.2885H18.3673L21.9836 20.1084H25.9632L22.347 14.2885H23.7387C25.5049 14.2885 27.1775 13.6131 28.4483 12.3865ZM25.8372 7.53253V7.51043C25.8372 7.48377 25.8366 7.45517 25.8353 7.42462V7.42458C25.8353 7.42004 25.8353 7.41551 25.8346 7.41097L25.8307 7.35376C25.8041 6.98974 25.6747 6.44434 25.1963 5.9672L25.1332 5.9035C24.7185 5.48876 24.1692 5.26125 23.5861 5.26125H9.3454L9.08603 5.67728L6.8349 9.28312L6.57618 9.69915L2.71486 15.9137L0.108154 20.1084H4.08778L6.69449 15.9137L10.5558 9.69915H23.6505C24.2206 9.69915 24.7594 9.48139 25.169 9.08615C25.7072 8.56611 25.821 7.95701 25.8353 7.60013C25.8359 7.59298 25.8359 7.58518 25.8359 7.57803C25.8359 7.57307 25.8361 7.56811 25.8363 7.56324C25.8364 7.55853 25.8366 7.5539 25.8366 7.54943C25.8372 7.54358 25.8372 7.53773 25.8372 7.53253Z"
        fill="url(#paint0_linear_3690_7664)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3690_7664"
          x1="15.3142"
          y1="0.671875"
          x2="15.3142"
          y2="20.1084"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#22CC7A" />
          <stop offset="1" stopColor="#155239" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RrIcon;
