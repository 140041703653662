import { $session } from 'state/stores';

import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import { login } from 'api/services/auth';

import { LoginProps } from './Login.props';

import { $user } from 'state/stores/user';
import { UserDetail } from 'types/user';
import LoginView from './Login.view';

const LoginContainer = (props: LoginProps) => {
  const navigate = useNavigate();

  const { redirectUrl } = props;

  const goToRegister = () => {
    navigate(routesSettings.AUTH_SIGNUP.path, {
      replace: true,
    });
  };

  const { mutate, isPending } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      const id = data.id;
      const tokens = data.tokens;
      const username = data.username;
      $session.actions.setSession({
        id,
        tokens,
        username,
      });
      $user.actions.setUser(data as unknown as UserDetail);
      navigate(redirectUrl ?? routesSettings.HOME.path, {
        replace: true,
      });
    },
  });

  return (
    <LoginView
      {...props}
      goToRegister={goToRegister}
      isLoading={isPending}
      onSubmit={mutate}
    />
  );
};

export default LoginContainer;
