import {
  QuestionPayload,
  QuestionResponse,
  QuestionsListPayload,
  QuestionsResponse,
  QuestionUpdatePayload,
} from 'types/question';
import { api } from './instance';

export const createQuestion = async (payload: QuestionPayload) => {
  const { data } = await api.post<QuestionResponse>(`/questions`, payload);

  return data?.item;
};

export const deleteQuestion = async (questionId: string) => {
  const { data } = await api.delete<QuestionResponse>(
    `/questions/${questionId}`,
  );

  return data?.item;
};

export const getQuestionsByItemId = async (productId: string) => {
  const { data } = await api.get<QuestionsResponse>(`/questions/${productId}`);

  return data?.item;
};

export const updateQuestionsByQuestionId = async (
  payload: QuestionUpdatePayload,
  questionId: string,
) => {
  const { data } = await api.patch<QuestionResponse>(
    `/questions/${questionId}`,
    payload,
  );

  return data?.item;
};
