import React, { FC } from 'react';

import { FormikTextAreaProps } from './FormikTextArea.props';
import { Field, FieldProps, useField } from 'formik';

const FormikTextAreaView: FC<FormikTextAreaProps> = ({
  placeholder = '',
  name,
}) => {
  return (
    <div className="w-full">
      <Field name={name}>
        {({ field }: FieldProps) => {
          return (
            <textarea
              {...field}
              name={name}
              placeholder={placeholder}
              className="border border-[##EFEFEF] rounded-lg p-4"
              style={{ width: '100%', resize: 'none' }}
            />
          );
        }}
      </Field>
    </div>
  );
};

export default FormikTextAreaView;
