import { FC, useEffect } from 'react';

import View from 'components/core/View/View.view';
import CommonModal from 'components/modals/CommonModal';
import ProductToRentView from 'components/modules/ProductToRent/ProductToRentView';
import CommonContainer from 'components/primitives/CommonContainer';
import { routesSettings } from 'constants/routes';
import ModalReference from 'helperClasses/modalReference';
import { useParams } from 'react-router-dom';
import Login from 'routes/auth/Login';
import { useHeaderContext } from 'state/contexts/headerContext';
import { ProductDetailsViewProps } from './ProductDetails.props';

const ProductDetailsView: FC<ProductDetailsViewProps> = () => {
  const { hideAuthButtons } = useHeaderContext();

  const authModalRef = new ModalReference();

  useEffect(() => {
    hideAuthButtons();
  }, []);

  const handleClick = () => {
    authModalRef.open();
  };

  const { id } = useParams();

  const redirectUrl = routesSettings.MAIN_RENT_PRODUCT.getPath(id!);

  return (
    <View.Container>
      <div className="space-y-8">
        <CommonContainer>
          <ProductToRentView handleRentClick={handleClick} hideRating />
          <CommonModal
            className="w-[90vw] md:w-[70vw] lg:w-[40vw]"
            ref={authModalRef.getReference()}>
            <Login redirectUrl={redirectUrl} hideCoverImage />
          </CommonModal>
        </CommonContainer>
      </div>
    </View.Container>
  );
};

export default ProductDetailsView;
