import { useQuery } from '@tanstack/react-query';
import { getRentAvailabilty } from 'api/services/rent';

const FETCH_RENT_AVAILABILITY_KEY = 'fetch-rent-availability-key';

const useRentAvailabiltyQuery = (
  id: string,
  startDate: string,
  endDate: string,
) => {
  const query = useQuery({
    queryKey: [FETCH_RENT_AVAILABILITY_KEY, id, startDate, endDate],
    queryFn: () => getRentAvailabilty(id, startDate, endDate),
    gcTime: 0,
  });

  return query;
};

export default useRentAvailabiltyQuery;

export { FETCH_RENT_AVAILABILITY_KEY };
