import React, { FC, ReactElement } from 'react';

import useIsLenderView from 'hooks/useIsLenderView';

class View {
  static Lender: FC<{ children: ReactElement }> = ({ children }) => {
    const isLenderView = useIsLenderView();
    if (!isLenderView) return null;
    return children;
  };

  static Renter: FC<{ children: ReactElement }> = ({ children }) => {
    const isLenderView = useIsLenderView();
    if (isLenderView) return null;
    return children;
  };

  static Container: FC<{ children: ReactElement }> = ({ children }) => {
    return (
      <div className="w-full lg:px-28 lg:py-16 px-8 py-4 space-y-12">
        {children}
      </div>
    );
  };
}

export default View;
