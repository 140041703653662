export const IconsAndTitlesRow = ({ content }: any) => {
  return (
    <div className="flex flex-wrap gap-14 justify-start items-center">
      {content.map((item: any, index: number) => {
        return (
          <div
            key={`${item.title}-${index}`}
            className="max-w-[5rem] flex flex-col justify-center items-center">
            <div>{item.icon}</div>
            <div className="text-center w-[5rem] mt-2 text-neutral-700 text-md font-medium font-fourth">
              {item.title}
            </div>
          </div>
        );
      })}
    </div>
  );
};
