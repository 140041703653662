import { FC } from 'react';

type NotFoundProps = {
  hideButton?: boolean;
  description?: string;
};

const NotFound: FC<NotFoundProps> = ({ hideButton = false, description }) => {
  return (
    <div className="flex flex-1 items-center justify-center mt-20">
      <div className="card grid place-items-center shadow-var-sm  p-20 gap-2">
        <h1>404</h1>
        <p> {description ?? 'Not Found'}</p>
        {!hideButton && (
          <a className="btn btn-var-solid  mt-5" href="/">
            GO HOME
          </a>
        )}
      </div>
    </div>
  );
};

export default NotFound;
