import React, { FC } from 'react';

import { ListCardProps } from './ListCard.props';
import NoItemsFound from 'components/vectors/NoItemsFound';

const ListCardView: FC<ListCardProps> = ({
  title,
  children,
  itemNotFoundPlaceholder,
}) => {
  const count = children as unknown as any;
  const isEmpty = !count?.length;
  return (
    <div className="min-h-min w-full px-9 my-10 md:px-5 grid grid-flow-row place-items-center">
      <div className="w-fit">
        <div className="flex-between items-center">
          {title && (
            <p className="font-primary text-gray-700 text-lg justify-between font-bold md:text-3xl">
              {title}
            </p>
          )}
        </div>
        <div className="flex flex-wrap justify-center gap-10">
          {isEmpty ? itemNotFoundPlaceholder : children}
        </div>
      </div>
    </div>
  );
};

export default ListCardView;
