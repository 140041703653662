import { useQuery } from '@tanstack/react-query';
import { fetchItem } from 'api/services/item';

const FETCH_ITEM_KEY = 'fetch-item';

const useItemQuery = (id: string) => {
  const query = useQuery({
    queryKey: [FETCH_ITEM_KEY, id],
    queryFn: () => fetchItem(id),
    enabled: id != null ? true : false,
  });

  return query;
};

export default useItemQuery;

export { FETCH_ITEM_KEY };
