import { useQuery } from '@tanstack/react-query';
import { getRenterReviews } from 'api/services/reviews';

const FETCH_RENTER_REVIEWS = 'fetch-renter-reviews';

const useRenterReviewsQuery = (id: string) => {
  const query = useQuery({
    queryKey: [FETCH_RENTER_REVIEWS, id],
    queryFn: () => getRenterReviews(id),
  });
  return query;
};

export default useRenterReviewsQuery;

export { FETCH_RENTER_REVIEWS };
