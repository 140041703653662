import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phone: Yup.string()
    .matches(
      /^[0-9]{10}$/,
      'Phone number is invalid, please enter a valid phone number',
    )
    .required('Phone number is required'),
  idVerification: Yup.string().required('ID Verification is required').min(6),
  zipcode: Yup.string()
    .matches(/^(55|56)/, {
      message: `Initial launch is limited to Minnesota, we're excited to expand to additional markets in the near future. Please follow for updates`,
      excludeEmptyString: true,
    })
    .matches(/^\d{5}(-\d{4})?$/, {
      message: 'ZIP code should be 5 digits long.',
      excludeEmptyString: true,
    })
    .required('ZIP code is required'),
});

export const initialValues = {
  name: '',
  phone: '',
  idVerification: '',
  zipcode: '',
};
