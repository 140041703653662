import React, { FC } from 'react';

import { IntroModalProps } from './IntroModal.props';

import IntroModalView from './IntroModal.view';

const IntroModalContainer: FC<IntroModalProps> = (props) => {
  return <IntroModalView />;
};

export default IntroModalContainer;
