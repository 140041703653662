import { IconProps } from './type';

const ChevronRight = (props: IconProps) => {
  return (
    <svg
      width={props.w ?? '6'}
      height={props.h ?? '9'}
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91663 4.5882L1.07658 1.74815C0.818222 1.48979 0.818222 1.07091 1.07658 0.812553C1.33494 0.554194 1.75382 0.554194 2.01218 0.812553L5.32003 4.1204C5.57839 4.37876 5.57839 4.79764 5.32003 5.056L2.01218 8.36385C1.75382 8.62221 1.33494 8.62221 1.07658 8.36385C0.818223 8.10549 0.818223 7.68661 1.07658 7.42825L3.91663 4.5882Z"
        fill="#92929D"
      />
    </svg>
  );
};

export default ChevronRight;
