import { useItem, useOngoingRent, useRentDetails } from 'api/queries';
import { useState } from 'react';
import { $session } from 'state/stores';
import DateTimeZ from 'utils/dateTime';

const useIsItemRented = (id: string) => {
  const { data: item } = useItem(id);

  const [{ currentDate }] = useState(() => {
    const currentDate: string = new DateTimeZ().defaultPayloadStringFormat(); // Current date
    return { currentDate };
  });

  const isOwnersItem = item?.ownerId === $session.actions.getUserId();

  const { data: rentDetail, isLoading: rentDetailsLoading } = useRentDetails(
    id!,
    currentDate,
  );

  let rentDetails = rentDetail;

  const { data: onGoingRents, isLoading: onGoingRentsLoading } = useOngoingRent(
    id!,
    currentDate,
    {
      enabled: isOwnersItem,
    },
  );

  if (onGoingRents?.length! > 0) {
    rentDetails = onGoingRents![0];
  }

  const isItemRented =
    (rentDetails == null ? false : true) ||
    (onGoingRents?.length! > 0 ? true : false);

  const isLoading = rentDetailsLoading || onGoingRentsLoading;

  const renterId = rentDetails?.renterId;
  const ownerId = rentDetails?.ownerId;
  const itemId = rentDetails?.itemId;
  const rentId = rentDetails?._id;

  return {
    isItemRented,
    rentDetails,
    onGoingRents,
    renterId,
    itemId,
    ownerId,
    isLoading,
    rentId,
  };
};

export default useIsItemRented;
