import React, { FC } from 'react';

import { CommonContainerProps } from './CommonContainer.props';

import CommonContainerView from './CommonContainer.view';

const CommonContainerContainer: FC<CommonContainerProps> = ({
  children,
  w,
  h,
  removeShadow = false,
  padding,
  style,
  className,
}) => {
  return (
    <CommonContainerView
      children={children}
      w={w}
      h={h}
      removeShadow={removeShadow}
      padding={padding}
      style={style}
      className={className}
    />
  );
};

export default CommonContainerContainer;
