import { useState } from 'react';

import Avatar from 'components/primitives/Avatar';
import Button from 'components/primitives/Button';
import CommonContainer from 'components/primitives/CommonContainer';
import TextAreaInput from 'components/primitives/TextAreaInput';
import TextInput from 'components/primitives/TextInput';
import { Form, Formik } from 'formik';

import { IconsAndTitlesRow } from '../IconsAndTitleRow';

import { ProfileTabContentProps } from './ProfileTabContent.props';
import { badgesContent, validationSchema } from './utils';

import View from 'components/core/View/View.view';
import FormikRating from 'components/form/FormikRating';
import CallIcon from 'components/icons/CallIcon';
import EditIcon from 'components/icons/EditIcon';
import HomeOutlineIcon from 'components/icons/HomeOutlineIcon';
import MailIcon from 'components/icons/MailIcon';
import AlertModal from 'components/modals/AlertModal';
import SuccessModal from 'components/modals/SuccessModal';
import ProfileStripeSection from 'components/modules/ProfileStripeSection';
import UserLocationForm from 'components/modules/UserLocationForm';
import Blur from 'components/primitives/Blur';
import EditAvatar from 'components/primitives/EditAvatar';
import Loading from 'components/primitives/Loading';
import ModalReference from 'helperClasses/modalReference';
import { getNetworkImage } from 'utils/assets';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

const ProfileTabContent = ({
  user,
  userProfile,
  isLoading,
  isUpdatingProfile,
  initialValues,
  userRatings,
  successModalRef,
  showModal,
  setShowModal,
  toggleEditMode,
  isEditing = false,
  onProfileUpdate,
}: ProfileTabContentProps) => {
  const [localFile, setLocalFile] = useState<any>(userProfile?.avatar);

  const deleteAlert = new ModalReference();

  if (isLoading) {
    return <Loading />;
  }

  const userPhone = initialValues.phoneNumber
    ? formatPhoneNumber(initialValues.phoneNumber)
    : '';

  const userRating =
    userRatings?.ownerRatingDetails ??
    0 + userRatings?.renterRatingDetails! ??
    0;

  const state = user?.userDetails?.address?.state?.title ?? '';
  const city = user?.userDetails?.address?.title ?? '';

  return (
    <View.Container>
      <div className="space-y-8">
        <CommonContainer>
          {!isEditing ? (
            <div className="w-full flex lg:flex-row flex-col gap-2 justify-between items-start">
              <div className="flex gap-8">
                <Avatar
                  isLoading={isLoading}
                  imgSrc={
                    userProfile?.avatar
                      ? getNetworkImage(userProfile.avatar)
                      : ''
                  }
                  firstLetter={userProfile?.displayName?.charAt(0)}
                  className="w-24 h-24"
                />
                <div className="flex flex-col gap-6 items-start text-left mx-3">
                  <div className="w-full">
                    <div className="text-gray-700 text-3xl ml-1 font-medium font-tertiary leading-7 tracking-wide capitalize">
                      {user?.fname}
                    </div>
                    <div className="opacity-60 ml-1 text-neutral-700 text-md font-light font-secondary">
                      Member since {userProfile?.createdAt?.slice(0, 4)}
                    </div>
                  </div>
                  <FormikRating.RatingWidget
                    size="22"
                    value={userRating != null ? Math.round(userRating / 2) : 0}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4 items-start py-4 lg:py-0 lg:px-12 text-right w-full text-[#515151] font-secondary text-lg">
                <div className="flex gap-4">
                  <MailIcon />
                  <span>{user?.email}</span>
                </div>
                <div className="flex gap-4">
                  <CallIcon />
                  <span>{userPhone}</span>
                </div>
                <div className="flex gap-4">
                  <HomeOutlineIcon />
                  <span className="capitalize">
                    {' '}
                    {city + (city ? ', ' : '')} {state}
                  </span>
                </div>
              </div>
              <div className="w-full text-[#515151] font-secondary text-lg">
                <span>{userProfile?.about}</span>
              </div>
              <button
                type="button"
                onClick={() => {
                  setLocalFile(userProfile?.avatar);
                  toggleEditMode && toggleEditMode();
                }}>
                <div className="bg-white rounded-full border-solid border-2 border-[#E7C0ED] p-2 hover:text-white hover:bg-[#E7C0ED] transition-all duration-300">
                  <EditIcon w={'1.5rem'} h={'1.5rem'} />
                </div>{' '}
              </button>
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onProfileUpdate}>
              {({ values, setFieldValue }) => (
                <Form className="w-full">
                  <div className="w-full flex gap-8 md:flex-row flex-col">
                    <div className="gap-2 flex justify-center">
                      <Avatar
                        imgSrc={
                          typeof localFile === 'string'
                            ? getNetworkImage(localFile)
                            : localFile?.preview
                        }
                        className="w-[10.5rem] h-[10.5rem] relative"
                        firstLetter={userProfile?.displayName?.charAt(0)}>
                        <div className="absolute -bottom-1 right-0 p-1">
                          <EditAvatar
                            localFile={localFile}
                            setFieldValue={setFieldValue}
                            setLocalFile={setLocalFile}
                          />
                        </div>
                      </Avatar>
                    </div>
                    <div className="w-full grid lg:grid-cols-2 grid-col-1 gap-4">
                      <div className="cols-span-1 space-y-4">
                        <div className="flex gap-4">
                          <TextInput
                            name={'displayName'}
                            hideErrorComponent
                            placeholder="Full Name"
                            isReadOnly={true}
                            className={'ring-0 border-0'}
                          />
                          <TextInput
                            hideErrorComponent
                            name={'phoneNumber'}
                            placeholder="Phone Number"
                          />
                        </div>
                        <TextInput
                          hideErrorComponent
                          name={'email'}
                          placeholder="Email"
                          isReadOnly={true}
                        />
                        <div className="flex space-x-4">
                          <UserLocationForm formModeOff={true} hideLabels />
                        </div>
                      </div>

                      <div className="col-span-1">
                        <TextAreaInput
                          name={'about'}
                          placeholder="About You"
                          className={'resize-none h-[183px]'}
                          rows={10}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex sm:flex-row flex-col gap-10 justify-center items-center mt-4">
                    <Button
                      variant={'outline'}
                      className={'w-60'}
                      colorScheme={'secondary'}
                      type="button"
                      onClick={() => {
                        toggleEditMode && toggleEditMode();
                      }}>
                      Cancel
                    </Button>
                    <Button
                      className={'w-60'}
                      variant={'solid'}
                      colorScheme={'primary'}
                      type="submit"
                      isLoading={isUpdatingProfile}
                      onClick={() => {
                        setFieldValue('avatar', localFile);
                      }}>
                      Save Changes{' '}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </CommonContainer>
        <ProfileStripeSection />
        <div className="flex flex-col lg:flex-row gap-8 justify-between">
          <CommonContainer>
            <Blur>
              <div className="w-full flex flex-col gap-6">
                <div className="text-neutral-700 text-3xl font-bold font-primary">
                  Badges
                </div>{' '}
                <IconsAndTitlesRow content={badgesContent} />
              </div>
            </Blur>
          </CommonContainer>
          <CommonContainer>
            <Blur
              messageChild={
                <a className="text-xl" href="mailto:admin@rapidrentals.com">
                  Please email 
                  <span className="text-secondary-900">
                    admin@rapidrentals.com
                  </span>
                   for assistance
                </a>
              }>
              <div className="w-full flex flex-col gap-6">
                <div className="text-neutral-700 text-3xl font-bold font-primary mb-4">
                  Help
                </div>
                <ul className="list-disc ml-4">
                  <li>
                    <span className="text-neutral-700 text-md font-semibold font-fourth">
                      How can I list my items for rent?
                      <br />
                    </span>
                    <span className="text-neutral-700 text-md font-normal font-fourth">
                      To list your tools, you need to create an owner account,
                      and then you...
                    </span>
                  </li>
                  <li>
                    <span className="text-neutral-700 text-md font-semibold font-fourth">
                      How are the rental prices for my items determined?
                      <br />
                    </span>
                    <span className="text-neutral-700 text-md font-normal font-fourth">
                      You can set the rental price for your items based on their
                      value, deman...
                    </span>
                  </li>
                </ul>
              </div>
            </Blur>
            {/* <Blur>
              <div className="w-full flex flex-col gap-6 items-start">
                <div className="text-neutral-700 text-3xl font-bold font-primary">
                  Security
                </div>
                <IconsAndTitlesRow content={securityContent} />
              </div>
            </Blur> */}
          </CommonContainer>
        </div>

        <div className="flex flex-col lg:flex-row gap-8 justify-between">
          <div className="flex flex-col items-start justify-start w-full mx-5">
            <div className="divider" />
            <div className="text-neutral-700 text-xl font-bold font-primary mb-4">
              Delete Your Account
            </div>
            <div className="flex justify-between space-x-4">
              <div className="opacity-50 text-neutral-700 text-base font-normal font-secondary">
                When you delete your account, all your personal information and
                data will be permanently removed from our system. You'll lose
                access to your account and any associated services. This action
                is irreversible, so make sure to download any important data
                before proceeding.
              </div>
              <Button
                variant={'outline'}
                colorScheme={'secondary'}
                onClick={() => deleteAlert.open()}>
                Delete
              </Button>
            </div>
          </div>
          <div></div>
        </div>
        <SuccessModal
          ref={successModalRef}
          title="Profile Updated Successfully!"
          onClose={() => {
            toggleEditMode && toggleEditMode();
            setShowModal(false);
          }}
          isOpen={showModal}
        />
        <AlertModal
          onClickButton={() => deleteAlert.close()}
          ref={deleteAlert.getReference()}>
          <p className="text-center">
            To delete your account, please contact the administrator at
            <a href="mailto:admin@rapidrentals.com">
              <span className="font-bold text-secondary-900">
                {' '}
                admin@rapidrentals.com
              </span>
            </a>
          </p>
        </AlertModal>
      </div>
    </View.Container>
  );
};

export default ProfileTabContent;
