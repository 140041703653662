import { FC, useCallback, useState } from 'react';

import NotificationsIcon from 'components/icons/NotificationsIcon';
import { LendViewProps } from './Lend.props';

import homeImg from 'assets/images/home_new_image.jpeg';

import useIsMobile from 'hooks/useIsMobile';

import LendTableView from 'components/modules/LendTable';
import Button from 'components/primitives/Button';
import Loading from 'components/primitives/Loading';
import { routesSettings } from 'constants/routes';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { $stripe } from 'state/stores/stripe';

import MyRentalHistory from 'components/modules/MyRentalHistory';
import RentedItemsHistory from 'components/modules/RentedItemsHistory';
import TabsView from 'components/primitives/Tabs/Tabs.view';
import debounce from 'lodash.debounce';
import { $user } from 'state/stores/user';

enum Tab {
  HISTORY_ITMES = 'history-items',
  ALL_ITEMS = 'all-items',
}

const LendView: FC<LendViewProps> = ({ stripe }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = location.state?.activeTab || 0; // Default to index 0 if state is not provided

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const isHistorySelected = tab === 'history';

  const isAddressSet = $user.selectors.useIsAddressSet();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const isConnectedToStripe = $stripe.selectors.useIsDetailsSubmitted();

  const isMobile = useIsMobile();

  const [openTab, setOpenTab] = useState(
    isHistorySelected ? Tab.HISTORY_ITMES : Tab.ALL_ITEMS,
  );

  const onClickCreate = () => {
    navigate(routesSettings.MAIN_CREATE_ITEM.path);
  };

  const debouncedOnSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 500), // 500 milliseconds debounce time
    [],
  );

  // Function to update search parameters
  const updateSearchParams = (key: string, value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const clearSearchParams = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('tab');
    setSearchParams(newSearchParams);
  };

  const handleSearch = useCallback(
    (value: string) => {
      debouncedOnSearch(value);
    },
    [debouncedOnSearch],
  );

  return (
    <div className="h-full">
      <section>
        <div
          className="rent-header-img"
          style={{
            backgroundImage: `url(${homeImg})`,
          }}>
          <div className="w-full opacity-60 rounded-b-3xl h-28 md:h-72 md:rounded-none bg-cover bg-no-repeat bg-center bg-secondary-900"></div>

          <div className="lend-header-img">
            {isMobile ? (
              <div className="flex w-full justify-end px-9 py-4">
                <NotificationsIcon />
              </div>
            ) : (
              <></>
            )}
            {!stripe.isFetchingStripeStatus && isConnectedToStripe && (
              <div className="lend-search-bar">
                <form>
                  <label className="w-80 md:w-96">
                    <div className="bg-white w-full top-20 md:top-80 left-1/4 rounded-lg">
                      <input
                        onChange={(e) =>
                          handleSearch && handleSearch(e.target.value)
                        }
                        className="input w-full  border border-gray-300"
                        placeholder="Search"
                      />
                    </div>
                  </label>
                </form>
                {!isMobile ? (
                  <Button
                    colorScheme="primary"
                    variant="solid"
                    size="w-64"
                    onClick={onClickCreate}>
                    Create Item
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </section>

      {stripe.isFetchingStripeStatus ? (
        <Loading />
      ) : (
        <TabsView
          initialTab={activeTab}
          headings={['All my items', 'My Rentals', 'Rented Items']}
          views={[
            <LendTableView searchQuery={searchQuery} />,
            <MyRentalHistory searchQuery={searchQuery} />,
            <RentedItemsHistory searchQuery={searchQuery} />,
          ]}
        />
      )}
    </div>
  );
};

export default LendView;
