import { useQuery } from '@tanstack/react-query';
import { getOngoingRents } from 'api/services/rent';

const FETCH_ONGIONG_RENTS_KEY = 'fetch-ongoing-rents-key';

type Options = {
  enabled: boolean;
};

const useOngoingRentQuery = (
  itemId: string,
  date: string,
  options?: Options,
) => {
  const query = useQuery({
    queryKey: [FETCH_ONGIONG_RENTS_KEY, itemId, date],
    queryFn: () => getOngoingRents(itemId, date),
    enabled: options?.enabled ?? true,
    gcTime: 0,
  });
  return query;
};

export default useOngoingRentQuery;

export { FETCH_ONGIONG_RENTS_KEY };
