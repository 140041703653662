import { ProductRateInfo, Review } from 'types';
import { delayResult } from 'utils/delayResult';
import { products } from 'utils/mockData';
import { api } from './instance';
import {
  ItemReviewResponse as ReviewResponse,
  ReviewPayload,
  OverallUserRatingResponse,
} from 'types/reviews';

export const getRateInfoByProdId = (
  proId?: string,
): Promise<ProductRateInfo | undefined> => {
  const prod = products.find((product) => product.id === proId);

  return delayResult<ProductRateInfo | undefined>(prod?.productRateInfo);
}; //API simulation

export const rateRenter = async (payload: ReviewPayload) => {
  const { data } = await api.post(`/reviews/renter`, payload);
  return data?.item;
};

export const rateItem = async (payload: ReviewPayload) => {
  const { data } = await api.post(`/reviews/item`, payload);
  return data?.item;
};

export const rateOwner = async (payload: ReviewPayload) => {
  const { data } = await api.post(`/reviews/owner`, payload);
  return data?.item;
};

export const getItemsReviews = async (id: string) => {
  const { data } = await api.get<ReviewResponse>(`/reviews/item/${id}`);
  return data.item;
};

export const getOwnerReviews = async (id: string) => {
  const { data } = await api.get<ReviewResponse>(`/reviews/owner/${id}`);
  return data.item;
};

export const getRenterReviews = async (id: string) => {
  const { data } = await api.get<ReviewResponse>(`/reviews/renter/${id}`);
  return data.item;
};

export const getUserOverallRatings = async (id: string) => {
  const { data } = await api.get<OverallUserRatingResponse>(
    `/reviews/rating/${id}`,
  );
  return data;
};
