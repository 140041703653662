export const limitCharacters = (inputString: string, maxCharacters: number) => {
  if (inputString.length <= maxCharacters) {
    return inputString;
  }

  let truncatedString = inputString.substring(0, maxCharacters);
  truncatedString = truncatedString.replace(/\s\w*$/, '');

  return `${truncatedString}...`;
};
