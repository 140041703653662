import { FC } from 'react';

import { limitCharacters } from 'utils/limitCharacters';
import { CardProps } from './Card.props';

const CardView: FC<CardProps> = (props) => {
  const { description, img, id, title, onCardClick } = props;
  return (
    <div
      className="card card-item mt-6 w-16 rounded-lg overflow-hidden"
      onClick={() => onCardClick && onCardClick()}>
      <div className="object-cover">
        <img
          loading="lazy"
          src={img}
          alt={title}
          className="select-none object-cover h-32 w-full"
          draggable={false}
        />
      </div>
      <div className="card-body">
        <p className="font-primary truncate max-w-xs text-gray-700 text-sm font-bold md:text-lg md:text-start capitalize ...">
          {title}
        </p>
        {description && (
          <div className="flex items-start justify-start text-gray-500 text-sm font-normal font-secondary">
            {`${limitCharacters(description, 70)}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardView;
