import { useEffect } from 'react';

import { ProductDetailsProps } from './ProductDetails.props';

import { useItem } from 'api/queries';
import Loading from 'components/primitives/Loading';
import { routesSettings } from 'constants/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { $session } from 'state/stores';
import { $itemRentDetails } from 'state/stores/rent-details';
import ProductDetailsView from './ProductDetails.view';

const ProductDetailsContainer = (props: ProductDetailsProps) => {
  const { id } = useParams();

  const { data, isLoading } = useItem(id!);

  useEffect(() => {
    if (data) {
      $itemRentDetails.actions.setProduct(data);
    }
  }, [data]);

  const isAuth = $session.selectors.useIsAuth();

  const navigate = useNavigate();

  const isOwnersProduct = data?.ownerId === $session.actions.getUserId();

  useEffect(() => {
    if (isAuth) {
      $itemRentDetails.actions.reset();
      navigate(
        isOwnersProduct
          ? routesSettings.MAIN_LEND_PRODUCT.getPath(id!)
          : routesSettings.MAIN_RENT_PRODUCT.getPath(id!),
        {
          replace: true,
        },
      );
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return <ProductDetailsView />;
};

export default ProductDetailsContainer;
