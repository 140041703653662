export const calculatePrice = (
  price: number = 0,
  startDate: Date,
  endDate: Date,
) => {
  const startDateTime = new Date(startDate!);
  const endDateTime = new Date(endDate!);

  const differenceInMs = startDateTime.getTime() - endDateTime.getTime();

  // Convert milliseconds to days
  const daysDifference = Math.abs(
    Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)),
  );

  // Define the price per day, service charge, and tax
  const pricePerDay = price!; // $100 per day
  const serviceCharge = 50; // $50 service charge

  // Calculate the total cost
  const actualRentalPrice = daysDifference * pricePerDay;

  // calculate tax, 15 percent of the actual rentla price

  const tax = actualRentalPrice * 0.15; // $10 tax

  const totalPrice = actualRentalPrice + serviceCharge + tax;

  return {
    totalPrice,
    tax,
    serviceCharge,
    daysDifference,
    actualRentalPrice,
  };
};
