import { FC, useEffect } from 'react';

import CheckIcon from 'components/icons/CheckIcon';
import Wallet from 'components/icons/Wallet';
import UserLocationForm from 'components/modules/UserLocationForm';
import Button from 'components/primitives/Button';
import { useHeaderContext } from 'state/contexts/headerContext';
import { $user } from 'state/stores/user';
import { AccountCreatedViewProps } from './AccountCreated.props';

const AccountCreatedView: FC<AccountCreatedViewProps> = ({
  doItLater,
  connectStripeAccount,
}) => {
  const isAddressSet = $user.selectors.useIsAddressSet();
  const { hideAuthButtons } = useHeaderContext();
  useEffect(() => {
    hideAuthButtons();
  }, []);

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center mx-4 my-16 space-y-6 sm:w-2/3">
        <div className="flex flex-col items-center w-full">
          <CheckIcon w="80" h="80" />
          <div className="mt-2"></div>
          <h2 className="my-2">Account successfully created!</h2>
          <p className="text-center font-secondary mt-1 md:w-96">
            Fantastic! Your account is all set. Dive into a world of tools,
            accessories, gadgets, and more. Start exploring and experiencing the
            possibilities today!
          </p>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <div className="w-full flex flex-col justify-center items-center mt-4">
            <h4 className="text-center text-[18px] my-4">Select your city</h4>
            <div className="w-full">
              <UserLocationForm hideLabels />
            </div>
            <p className="text-center px-8 text-[#848484] text-[14px] md:w-96">
              Initial launch is limited to Minnesota. We're excited to expand to
              additional markets in the near future. Please follow for updates.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <Wallet />
            <h2 className="my-2">Connect to stripe</h2>
            <p className="text-center font-secondary mt-1 md:w-96">
              To become an owner and rent your items you need to be connected to
              Stripe. This ensures smooth and easy transactions when receiving
              and sending payments.
            </p>
          </div>
          <div className="flex flex-col w-full">
            <button
              disabled={!isAddressSet}
              onClick={connectStripeAccount}
              className="btn btn-var-stripe capitalize w-full md:w-96">
              Connect Stripe Account
            </button>
            <Button
              disabled={!isAddressSet}
              onClick={doItLater}
              variant="outline"
              colorScheme="secondary"
              className="mt-5 w-full md:w-96">
              I Will Do It Later
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCreatedView;
