import React, { FC, ChangeEvent, useRef } from 'react';
import { FormikInputNumberProps } from './FormikInputNumber.props';
import Label from 'components/primitives/Label';
import { ErrorMessage, Field, isString, useField } from 'formik';
import debounce from 'lodash.debounce';

const FormikInputNumberView: FC<FormikInputNumberProps> = ({
  labelProps,
  type,
  label,
  isRequired,
  name,
  disabled,
  placeholder,
  tooltipMessage,
  children,
  hideErrorComponent = false,
  innerRef,
  min,
  max,
}) => {
  const [field, _, helpers] = useField(name);
  const inputRef = useRef<HTMLInputElement>(null); // Ref for input element

  const formatCurrency = (value: number | string): string => {
    const numericValue = Number(value);

    if (isNaN(numericValue)) {
      return '';
    }

    return numericValue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Remove any non-numeric characters except for the decimal point
    const numericValueString = inputValue.replace(/[^0-9.]/g, '');
    const numericValue = parseFloat(numericValueString);

    // If the numeric value is NaN, try to parse the currency string
    if (isNaN(numericValue)) {
      const newCurrency = formatCurrency(numericValueString).replace(
        /[^0-9.]/g,
        '',
      );
      const numericCurrency = parseFloat(newCurrency);
      helpers.setValue(isNaN(numericCurrency) ? 0 : numericCurrency);
    } else {
      helpers.setValue(numericValue);
    }
  };

  // Adjust the debounce timing based on your application's requirements
  const debounceTime = 700;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e) {
      return '';
    }
    handleChange(e);
  };

  const debouncedInputChange = debounce(handleInputChange, debounceTime);

  const formattedInput =
    typeof field?.value === 'string'
      ? field?.value
      : formatCurrency(field?.value || '');

  const currencyValue = type === 'currency' ? formattedInput : field.value;

  return (
    <div>
      <Label
        name={name}
        isRequired={isRequired}
        toolTipMessage={tooltipMessage}
        title={label!}
      />
      <Field
        id={label}
        type="number"
        name={name}
        min={min}
        max={max}
        innerRef={innerRef}
        placeholder={placeholder ? placeholder : ''}
        className={`text-input input focus:outline-primary`}
        disabled={disabled}
        labelprops={labelProps}
        value={field?.value || ''}
        component={() => (
          <input
            name={name}
            className={`text-input input focus:outline-primary`}
            defaultValue={currencyValue}
            onChange={debouncedInputChange}
          />
        )}
      />
      {children}
      {!hideErrorComponent && (
        <div className={`h-6 mt-2`}>
          <ErrorMessage
            component="div"
            className="text-red-500 text-sm"
            name={name}
          />
        </div>
      )}
    </div>
  );
};

export default FormikInputNumberView;
