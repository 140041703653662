import React, { FC, ReactNode } from 'react';

import { TimeLineTrackerProps } from './TimeLineTracker.props';
import CommonContainer from 'components/primitives/CommonContainer';
import Timeline from 'components/primitives/Timeline';
import { useNavigate, useParams } from 'react-router-dom';
import Ratings from 'components/icons/Ratings';
import { RentalStatusEnum } from 'utils/timeline';
import DateTimeZ from 'utils/dateTime';
import { format } from 'date-fns';
import RateRenter from '../RateRenter';
import Button from 'components/primitives/Button';
import RateItem from '../RateItem';
import View from 'components/core/View/View.view';
import RateOwner from '../RateOwner';
import ModalReference from 'helperClasses/modalReference';
import RentHelper from 'helperClasses/rent';
import AlertModal from 'components/modals/AlertModal';
import { $itemRentDetails } from 'state/stores/rent-details';
import { QUERY_KEYS } from 'api/queries';
import { ROUTES, routesSettings } from 'constants/routes';
const TimeLineTrackerView: FC<TimeLineTrackerProps> = ({}) => {
  const { id } = useParams();
  const rentDetails = $itemRentDetails.selectors.useRentDetails();
  const renterId = rentDetails?.renterId;
  const itemId = rentDetails?.itemId;
  const rentId = rentDetails?._id;
  const rentHelper = new RentHelper(rentDetails!);
  const rentStatus = rentHelper.getTimeLineStatus();
  const startDate = rentHelper.startDate;
  const endDate = rentHelper.endDate;
  const navigate = useNavigate();
  const isOwnersItem = $itemRentDetails.selectors.isOwnersItem();

  const reportProblemRef = new ModalReference();

  const navigateToRentalHistory = () => {
    navigate(routesSettings.LEND.path + '?tab=history');
  };

  const navigateToProduct = () => {
    $itemRentDetails.actions.reset();
    navigate(routesSettings.MAIN_RENT_PRODUCT.getPath(itemId!), {
      replace: false,
    });
  };

  const renderMessageComponents = () => {
    switch (rentStatus) {
      case RentalStatusEnum.PENDING:
        return <RentalPendingComponent />;
      case RentalStatusEnum.BOOKED:
        return <RentalBookedComponent />;
      case RentalStatusEnum.RENTAL_IN_PROGRESS:
        return <RentalInProgressComponent />;
      /**
       * This case is commented as it now not being used based on the business logic which is used now, that is based on the dates, time line status is shown
       */
      // case RentalStatusEnum.RETURNED:
      //   return <RentalReturnedComponent />;
      case RentalStatusEnum.COMPLETED:
        return <RentalCompleteComponent />;
      case RentalStatusEnum.CANCELED:
        return <RentalCanceled />;
      default:
        return null;
    }
  };

  const RentalCompleteComponent = () => {
    const rateOwnerRef = new ModalReference();
    return (
      <>
        <View.Lender>
          <RentalStatusInfoWrapper>
            <p className="text-lg">
              We are glad that this rental was concluded correctly.
            </p>
            <div className="flex flex-col justify-center items-center gap-2">
              <p className="text-lg">Would you like to rate the renter? </p>
              <div className="cursor-pointer"></div>
            </div>
            <RateRenter
              renterId={renterId!}
              itemId={itemId!}
              rentId={rentId!}
              child={<Ratings />}
            />
          </RentalStatusInfoWrapper>
        </View.Lender>

        <View.Renter>
          <RentalStatusInfoWrapper>
            <p className="text-lg">
              We are glad that this rental was concluded correctly.
            </p>
            <div className="flex flex-col justify-center items-center gap-2">
              <p className="text-lg">Would you like to rate the item? </p>
              <div className="cursor-pointer"></div>
            </div>
            <RateItem
              itemId={itemId!}
              renterId={renterId!}
              rentId={rentId!}
              child={<Ratings />}
              onSuccess={() => {
                rateOwnerRef.open();
              }}
            />
            <RateOwner
              itemId={itemId!}
              renterId={renterId!}
              rentId={rentId!}
              hideTriggerElement={true}
              externalRef={rateOwnerRef}
              child={<Ratings />}
            />
          </RentalStatusInfoWrapper>
        </View.Renter>
      </>
    );
  };

  const RentalBookedComponent = () => {
    return (
      <RentalStatusInfoWrapper>
        <p className="text-lg font-bold">
          Your renting was successfully booked!
        </p>
        <div className="flex flex-col justify-center items-center gap-2">
          <p className="text-lg">
            We’ll be in touch and let you know all the details of your renting,
            you can check anytime the status of it in your
            <span
              onClick={navigateToRentalHistory}
              className="text-primary-500 cursor-pointer">
              {' '}
              rental history
            </span>{' '}
            section
          </p>
        </div>
      </RentalStatusInfoWrapper>
    );
  };

  const RentalInProgressComponent = () => {
    const rateOwnerRef = new ModalReference();
    return (
      <RentalStatusInfoWrapper>
        <div className="space-y-2">
          <h2 className="text-center text-lg">Item Rented</h2>
          <p>
            Item marked as rented on <br />{' '}
            {format(startDate, "MMMM d, 'at' h a")}
          </p>
        </div>
        <div className="space-y-2">
          <h2 className="text-center text-lg">Return Item by:</h2>
          <p>{format(endDate, "EEEE, MMMM d, 'at' h a")}</p>
          <p>{new DateTimeZ(endDate.toString()).daysAndHoursLeft()}</p>
        </div>

        <View.Renter>
          <Button
            variant="outline"
            colorScheme="secondary"
            onClick={() => reportProblemRef.open()}>
            report a problem
          </Button>
        </View.Renter>
        <AlertModal
          ref={reportProblemRef.getReference()}
          onClickButton={() => {
            reportProblemRef.close();
          }}>
          <p className="text-center">
            To assist us in resolving your issue, please contact the
            administrator at{' '}
            <a href="mailto:admin@rapidrentals.com">
              <span className="font-bold text-secondary-900">
                admin@rapidrentals.com
              </span>
            </a>{' '}
            and provide photos of the item, highlighting any physical issues or
            concerns you may have encountered. Your cooperation helps us
            maintain the quality and safety of our platform. Thank you for your
            attention to this matter.
          </p>
        </AlertModal>
      </RentalStatusInfoWrapper>
    );
  };

  const RentalReturnedComponent = () => {
    return (
      <RentalStatusInfoWrapper>
        <div className="space-y-2">
          <h2 className="text-center text-lg">Item returned</h2>
          <p>
            Item collected on on <br />{' '}
            {format(endDate, "EEEE, MMMM d, 'at' h a")}
          </p>
        </div>
      </RentalStatusInfoWrapper>
    );
  };

  const RentalCanceled = () => {
    return (
      <RentalStatusInfoWrapper>
        <div className="space-y-2">
          <h2 className="text-center text-lg">Rental Canceled</h2>
        </div>
        <div className="flex flex-col justify-center items-center gap-2">
          <p className="text-lg">
            It seems there was a problem processing your payment.
          </p>
        </div>
        {!isOwnersItem && (
          <Button
            variant="outline"
            colorScheme="secondary"
            onClick={navigateToProduct}>
            Try Again
          </Button>
        )}
      </RentalStatusInfoWrapper>
    );
  };

  const RentalPendingComponent = () => {
    return (
      <RentalStatusInfoWrapper>
        <div className="space-y-2">
          <h2 className="text-center text-lg">Rental Pending</h2>
        </div>
        <div className="flex flex-col justify-center items-center gap-2">
          <p className="text-lg">
            It seems there was a problem processing your payment.
          </p>
        </div>
        {!isOwnersItem && (
          <Button
            variant="outline"
            colorScheme="secondary"
            onClick={navigateToProduct}>
            Try Again
          </Button>
        )}
      </RentalStatusInfoWrapper>
    );
  };

  return (
    <CommonContainer>
      <div className="flex flex-col w-full justify-between">
        <div className="grid lg:grid-flow-col md:grid-cols-12 grid-cols-1">
          <div className="col-span-8">
            <div className="text-neutral-700 text-2xl font-bold font-primary mb-8">
              Timeline
            </div>
            <Timeline status={rentStatus!} />
          </div>
          {renderMessageComponents()}
        </div>
      </div>
    </CommonContainer>
  );
};

const RentalStatusInfoWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="col-span-4 text-center h-full flex-col lg:px-24 flex justify-center items-center space-y-4">
      {children}
    </div>
  );
};

export default TimeLineTrackerView;
