import { useMutation } from '@tanstack/react-query';
import { updateUserProfile } from 'api/services/userProfile';
import { UserProfileUpdatePayload } from 'types/userProfile';
import { MutationParams } from './mutationTypes';
import { fetchUserDetail } from 'api/services/user';

const USER_PROFILE_UPDATE_KEY = 'user_profile_update_key';

const useUserProfileUpdateMutation = (id: string, options?: MutationParams) => {
  return useMutation({
    mutationKey: [USER_PROFILE_UPDATE_KEY],
    mutationFn: async (payload: UserProfileUpdatePayload) => {
      const res = await updateUserProfile(id, payload);
      return { ...res, ...payload };
    },
    onSuccess: options?.onSuccess,
  });
};

export default useUserProfileUpdateMutation;
