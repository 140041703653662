import { IconProps } from './type';

const Star = (props: IconProps) => {
  return (
    <svg
      width={props.size ?? '33'}
      height={props.size ?? '32'}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1180_99988)">
        <path
          d="M30.6126 14.9097C30.9473 14.6636 31.1955 14.3203 31.3226 13.9279C31.4496 13.5354 31.449 13.1136 31.3209 12.7216C31.1928 12.3295 30.9437 11.9868 30.6083 11.7416C30.273 11.4964 29.8683 11.3609 29.4511 11.3542L21.3472 11.0519C21.3073 11.0492 21.2691 11.0352 21.2372 11.0115C21.2052 10.9879 21.1809 10.9556 21.1671 10.9186L18.3668 3.45194C18.2261 3.07206 17.9705 2.74404 17.6347 2.51229C17.2989 2.28054 16.8991 2.15625 16.4894 2.15625C16.0796 2.15625 15.6798 2.28054 15.344 2.51229C15.0082 2.74404 14.7527 3.07206 14.612 3.45194L11.8206 10.9453C11.8068 10.9823 11.7825 11.0145 11.7505 11.0382C11.7186 11.0619 11.6804 11.0759 11.6405 11.0786L3.53663 11.3808C3.11937 11.3876 2.71471 11.523 2.37939 11.7682C2.04406 12.0135 1.79489 12.3561 1.6668 12.7482C1.53871 13.1403 1.53814 13.5621 1.66516 13.9545C1.79217 14.3469 2.04042 14.6903 2.37507 14.9364L8.73213 19.8697C8.76394 19.8944 8.78772 19.9278 8.80053 19.9657C8.81333 20.0036 8.81459 20.0444 8.80416 20.0831L6.61611 27.7364C6.50273 28.1261 6.51371 28.5409 6.64757 28.9242C6.78143 29.3075 7.03166 29.6409 7.36423 29.8789C7.6968 30.1168 8.09556 30.2479 8.50626 30.2542C8.91696 30.2605 9.31965 30.1418 9.65957 29.9142L16.3768 25.4697C16.4099 25.4472 16.4491 25.4351 16.4894 25.4351C16.5296 25.4351 16.5688 25.4472 16.6019 25.4697L23.3191 29.9142C23.6545 30.1496 24.0557 30.2761 24.4672 30.2761C24.8787 30.2761 25.2799 30.1496 25.6152 29.9142C25.9479 29.6784 26.1984 29.3467 26.3322 28.9647C26.466 28.5827 26.4767 28.1691 26.3626 27.7808L24.1565 20.1008C24.1449 20.0623 24.1455 20.0211 24.1584 19.9829C24.1713 19.9447 24.1958 19.9114 24.2286 19.8875L30.6126 14.9097Z"
          fill="#FFCB45"
        />
      </g>
      <defs>
        <clipPath id="clip0_1180_99988">
          <rect width="32.4156" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Star;
