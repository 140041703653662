import React from 'react';

function NotificationsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <g>
        <mask
          id="mask0_694_8256"
          style={{ maskType: 'alpha' }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_694_8256)">
          <path
            fill="#E2A837"
            d="M4 19v-2h2v-7c0-1.383.417-2.612 1.25-3.688.833-1.075 1.917-1.779 3.25-2.112v-.7c0-.417.146-.77.438-1.063A1.447 1.447 0 0112 2c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v.7c1.333.333 2.417 1.037 3.25 2.112C17.583 7.388 18 8.617 18 10v7h2v2H4zm8 3c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0110 20h4c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0112 22z"></path>
        </g>
      </g>
    </svg>
  );
}

export default NotificationsIcon;
