import { RateDataResponse } from 'types/rates';
import { api } from './instance';
import { CitiesResponse, StatesResponse } from 'types/location';

export const fetchStates = async () => {
  const { data } = await api.get<StatesResponse>(`/location/get-states`);
  return data?.item;
};

export const fetchCities = async (stateId?: string) => {
  if (!stateId) return;
  const { data } = await api.get<CitiesResponse>(
    `/location/get-cities/${stateId}`,
  );
  return data?.item;
};
