import React, { FC } from 'react';

import { LabelProps } from './Label.props';
import RequiredFieldMarker from '../RequiredFieldMarker';
import Tooltip from '../Tooltip';
import InfoIcon from 'components/icons/InfoIcon';

const LabelView: FC<LabelProps> = ({
  title,
  name,
  isRequired = false,
  toolTipMessage,
  icon,
}) => {
  const htmlFor = `${name}-input`;

  return (
    <label
      htmlFor={htmlFor}
      className={`flex text-gray-700 items-center gap-2 mb-2`}>
      {title ? title : null}
      <RequiredFieldMarker isRequired={isRequired} />
      {toolTipMessage != null && (
        <Tooltip message={toolTipMessage}>
          {icon ?? <InfoIcon fill="#682082" />}
        </Tooltip>
      )}
    </label>
  );
};

export default LabelView;
