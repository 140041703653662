import { useQuery } from '@tanstack/react-query';
import { getItemsReviews } from 'api/services/reviews';

const FETCH_ITEM_REVIEWS = 'fetch-item-reviews';

const useItemReviewsQuery = (id: string) => {
  const query = useQuery({
    queryKey: [FETCH_ITEM_REVIEWS, id],
    queryFn: () => getItemsReviews(id),
  });
  return query;
};

export default useItemReviewsQuery;

export { FETCH_ITEM_REVIEWS };
