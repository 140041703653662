import { SignupProps } from './Signup.props';

import { useMutation } from '@tanstack/react-query';
import { register } from 'api/services/auth';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { $session } from 'state/stores';
import { $user } from 'state/stores/user';
import { RegisterPayload } from 'types/auth';
import { UserDetail } from 'types/user';
import SignupView from './Signup.view';

const SignupContainer = (props: SignupProps) => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(routesSettings.AUTH_LOGIN.path, {
      replace: true,
    });
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: RegisterPayload) => {
      payload.role = 'customer';
      payload.username = payload.email;
      return register(payload);
    },
    onSuccess: (data) => {
      const id = data.id ?? data._id;
      const tokens = data.tokens;
      const username = data.username;
      $session.actions.setSession({
        id,
        tokens,
        username,
        isAuth: false,
      });
      $user.actions.setUser(data as unknown as UserDetail);
      navigate(routesSettings.AUTH_ACCOUNT_CREATED.path, {
        replace: true,
      });
    },
  });

  return (
    <SignupView isLoading={isPending} onSubmit={mutate} goToLogin={goToLogin} />
  );
};

export default SignupContainer;
