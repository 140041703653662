import React, { FC, ReactElement, ReactNode } from 'react';

import { FormikRatingProps } from './FormikRating.props';
import { useField } from 'formik';
import Star from 'components/icons/Star';
import BorderedStar from 'components/icons/BorderedStar';

type RatingWidgetProps = {
  value: number;
  onClick?: (rating: number) => void;
  size?: string;
};

const RatingWidget: FC<RatingWidgetProps> = ({
  value,
  onClick,
  size = '32',
}) => {
  const ratings = [1, 2, 3, 4, 5];
  return (
    <div className="flex gap-2">
      {ratings.map((rating, index) =>
        rating <= value ? (
          <RateItem
            onClick={() => onClick && onClick(rating)}
            key={index + rating.toLocaleString()}>
            <Star size={size} />
          </RateItem>
        ) : (
          <RateItem
            onClick={() => onClick && onClick(rating)}
            key={index + rating.toLocaleString()}>
            <BorderedStar size={size} />
          </RateItem>
        ),
      )}
    </div>
  );
};

const FormikRatingView: FC<FormikRatingProps> & {
  RatingWidget: FC<RatingWidgetProps>;
} = ({ name }) => {
  const [field, meta, helper] = useField(name);
  const handleRating = (rating: number) => {
    let curentRating = field.value;
    if (curentRating == rating) {
      curentRating--;
    } else {
      curentRating = rating;
    }
    helper.setValue(curentRating);
  };

  return <RatingWidget value={field.value} onClick={handleRating} />;
};

const RateItem: FC<{ children: ReactElement; onClick?: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      {children}
    </div>
  );
};

FormikRatingView.RatingWidget = RatingWidget;

export default FormikRatingView;
