import React from 'react';
import { IconProps } from './type';

const TrophyIcon = (props: IconProps) => {
  const { w, h } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${w ? w : '37'}`}
      height={`${h ? h : '37'}`}
      fill="none"
      viewBox="0 0 37 37">
      <g clipPath="url(#clip0_1496_29849)">
        <g>
          <path
            fill="#22CC7A"
            d="M25.192 28.926h-13.61v6.805h13.61v-6.805z"></path>
          <path
            fill="#22CC7A"
            d="M13.282 29.493v-.567h-1.7v6.805h13.61V34.03h-7.373a4.537 4.537 0 01-4.537-4.537z"></path>
          <path
            fill="#fff"
            d="M21.792 31.191h-6.805a1.134 1.134 0 100 2.269h6.805a1.134 1.134 0 100-2.269z"></path>
          <path
            fill="#FFCB45"
            d="M20.658 15.313H16.12l-2.268 13.61h9.073l-2.268-13.61z"></path>
          <path
            fill="#FFCB45"
            d="M18.387.566l2.836 7.373h7.94l-6.806 4.537 3.403 7.372-7.373-4.537-7.372 4.537 3.403-7.372-6.806-4.537h7.94L18.387.566z"></path>
          <path
            fill="#FFCB45"
            d="M21.233 18.764l-.575-3.451H16.12l-.575 3.451-1.694 10.16h1.702l1.81-10.86a1.044 1.044 0 011.026-.869c.192 0 .38.053.544.154l2.299 1.415z"></path>
          <path
            fill="#FFCB45"
            d="M18.386 13.613l-6.275 3.862-1.097 2.377 7.372-4.537 7.373 4.537-1.097-2.377-6.276-3.862z"></path>
          <path
            fill="#682082"
            d="M14.986 30.625a1.701 1.701 0 100 3.403h6.805a1.701 1.701 0 000-3.403h-6.806zm7.372 1.701a.567.567 0 01-.567.568h-6.806a.567.567 0 010-1.135h6.806a.567.567 0 01.567.567z"></path>
          <path
            fill="#682082"
            d="M29.705 7.776a.567.567 0 00-.542-.403h-7.55L18.916.364a.567.567 0 00-1.06 0l-2.695 7.01h-7.55a.567.567 0 00-.315 1.038L13.7 12.68l-3.2 6.932a.567.567 0 00.813.721l3.786-2.328-1.728 10.352h-1.788a.567.567 0 00-.567.567v6.805a.567.567 0 00.567.567h13.61a.567.567 0 00.568-.567v-6.805a.567.567 0 00-.567-.567h-1.788l-1.726-10.352 3.784 2.328a.567.567 0 00.812-.72l-3.2-6.933 6.402-4.268a.567.567 0 00.228-.636zm-5.08 27.386H12.15v-5.671h12.477v5.671zm-2.37-6.805H14.52l1.856-11.14 2.011-1.238 2.011 1.237 1.857 11.14zm-.213-16.351a.567.567 0 00-.2.71l2.616 5.67-5.773-3.556a.567.567 0 00-.595 0l-5.77 3.553 2.615-5.67a.567.567 0 00-.2-.71L9.484 8.508h6.068a.567.567 0 00.53-.364l2.305-5.996 2.306 5.996a.568.568 0 00.53.364h6.068l-5.249 3.498z"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1496_29849">
          <path
            fill="#fff"
            d="M0 0H36.295V36.295H0z"
            transform="translate(.234)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrophyIcon;
