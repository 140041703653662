import { MutationFunction } from '@tanstack/react-query';
import { UpdateUserResponse, UserDetail, UserDetailResponse } from 'types/user';
import { api } from './instance';

export const fetchUserDetail = async (id: string) => {
  const { data } = await api.get<UserDetailResponse>(`/users/detail/${id}`);

  return data?.item;
};

export const updateUser = async (id: string, user: Partial<UserDetail>) => {
  // if (!id) {
  //   throw new Error(
  //     'updateUserProfile: The id is mandatory to update the user profile data',
  //   );
  // }

  const { data } = await api.patch<UpdateUserResponse>(`/users/${id}`, user);

  return data?.item;
};
