import React, { FC } from 'react';

import { TextAreaInputProps } from './TextAreaInput.props';
import Label from '../Label';
import { ErrorMessage, Field, FieldProps } from 'formik';

const TextAreaInputView: FC<TextAreaInputProps> = (props) => {
  const {
    name,
    label,
    placeholder,
    tooltipMessage,
    isRequired,
    className,
    rows,
    hideErrorComponent = false,
    cols,
  } = props;

  return (
    <div className="w-full flex flex-col">
      {label && (
        <Label
          name={name}
          isRequired={isRequired}
          toolTipMessage={tooltipMessage}
          title={label!}
        />
      )}

      <Field name={name}>
        {({ field }: FieldProps) => (
          <textarea
            {...field}
            title={label}
            placeholder={placeholder}
            rows={rows}
            cols={cols}
            className={`rounded-lg border textarea bg-white border-indigo-100 p-3 text-lg ${className} focus:border-zinc-300`}
          />
        )}
      </Field>

      {!hideErrorComponent && (
        <div className={`h-5 my-1`}>
          <ErrorMessage
            component="div"
            className="text-red-500 text-sm"
            name={name}
          />
        </div>
      )}
    </div>
  );
};

export default TextAreaInputView;
