export const ROUTES = {
  // AUTH ROUTE ENTRY
  AUTH_PRODUCT_DETAILS: 'AUTH_PRODUCT_DETAILS',
  AUTH_ACCOUNT_CREATED: 'AUTH_ACCOUNT_CREATED',
  AUTH_CONFIRM_IDENTITY: 'AUTH_CONFIRM_IDENTITY',
  AUTH_SIGNUP: 'AUTH_SIGNUP',
  AUTH_LOGIN: 'AUTH_LOGIN',

  // MAIN ROUTE ENTRY
  MAIN_TERMS_AND_CONDITIONS: 'MAIN_TERMS_AND_CONDITIONS',
  MAIN_PRIVACY_POLICY: 'MAIN_PRIVACY_POLICY',
  MAIN_ITEM_HISTORY: 'MAIN_ITEM_HISTORY',
  MAIN_EDIT_ITEM: 'MAIN_EDIT_ITEM',
  MAIN_CREATE_ITEM: 'MAIN_CREATE_ITEM',
  MAIN_PRODUCT_QUESTIONS: 'MAIN_PRODUCT_QUESTIONS',
  MAIN_OWNER_PROFILE: 'MAIN_OWNER_PROFILE',
  MAIN_PAYMENT_SUCCESSFULL: 'MAIN_PAYMENT_SUCCESSFULL',
  MAIN_PAYMENT: 'MAIN_PAYMENT',
  MAIN_PROFILE: 'MAIN_PROFILE',
  MAIN_RENT_PRODUCT_RENT_DETAILS: 'MAIN_RENT_PRODUCT_RENT_DETAILS',
  MAIN_RENT_PRODUCT: 'MAIN_RENT_PRODUCT',
  MAIN_LEND_PRODUCT_RENT_DETAILS: 'MAIN_LEND_PRODUCT_RENT_DETAILS',
  MAIN_LEND_PRODUCT: 'MAIN_LEND_PRODUCT',
  RENT: 'RENT',
  HOME: 'HOME',
  LEND: 'LEND',

  NOT_FOUND: 'NOT_FOUND',
} as const;

export const routesSettings = {
  // AUTH ROUTE SETTINGS ENTRY
  [ROUTES.AUTH_PRODUCT_DETAILS]: {
    name: 'ProductDetails',
    path: '/item/details/:id',
    getPath: (id?: string) => `/item/details/${id}`,
  },
  [ROUTES.AUTH_ACCOUNT_CREATED]: {
    name: 'AccountCreated',
    path: '/account-created',
    getPath: () => `/account-created`,
  },
  [ROUTES.AUTH_CONFIRM_IDENTITY]: {
    name: 'ConfirmIdentity',
    path: '/confirm-identity',
    getPath: () => `/confirm-identity`,
  },
  [ROUTES.AUTH_LOGIN]: {
    name: 'Login',
    path: '/login',
    getPath: () => `/login`,
  },
  [ROUTES.AUTH_SIGNUP]: {
    name: 'Signup',
    path: '/signup',
    getPath: () => `/signup`,
  },

  // MAIN ROUTE SETTINGS ENTRY
  [ROUTES.MAIN_TERMS_AND_CONDITIONS]: {
    name: 'TermsAndConditions',
    path: '/terms-and-conditions',
    getPath: () => `/terms-and-conditions`,
  },
  [ROUTES.MAIN_PRIVACY_POLICY]: {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    getPath: () => `/privacy-policy`,
  },
  [ROUTES.MAIN_ITEM_HISTORY]: {
    name: 'ItemHistory',
    path: '/item/history/:id',
    getPath: (id: string) => `/item/history/${id}`,
  },
  [ROUTES.MAIN_EDIT_ITEM]: {
    name: 'EditItem',
    path: '/lend/edit/:id',
    getPath: (id: string) => `/lend/edit/${id}`,
  },
  [ROUTES.MAIN_CREATE_ITEM]: {
    name: 'CreateItem',
    path: '/lend/create',
    getPath: () => `/lend/create`,
  },
  // [ROUTES.MAIN_PRODUCT_QUESTIONS]: {
  //   name: 'ProductQuestions',
  //   path: '/product-questions',
  //   getPath: () => `/product-questions`,
  // },
  [ROUTES.MAIN_OWNER_PROFILE]: {
    name: 'OwnerProfile',
    path: '/owner-profile/:ownerId',
    getPath: (ownerId: string) => `/owner-profile/${ownerId}`,
  },
  [ROUTES.MAIN_PAYMENT_SUCCESSFULL]: {
    name: 'PaymentSuccessfull',
    path: '/rent/rent-details/payment-successfull/:id',
    getPath: (id: string) => `/rent/rent-details/payment-successfull/${id}`,
  },
  [ROUTES.MAIN_PAYMENT]: {
    name: 'Payment',
    path: '/rent/rent-details/payment/:rentId',
    getPath: (rentId: string) => `/rent/rent-details/payment/${rentId}`,
  },
  [ROUTES.MAIN_PROFILE]: {
    name: 'Profile',
    path: '/profile',
    getPath: () => `/profile`,
  },
  [ROUTES.MAIN_RENT_PRODUCT_RENT_DETAILS]: {
    name: 'RentDetails',
    path: '/rent/rent-details/:id',
    getPath: (id: string) => `/rent/rent-details/${id}`,
  },
  [ROUTES.MAIN_RENT_PRODUCT]: {
    name: 'RentalProduct',
    path: '/rent/item/:id',
    getPath: (id: string) => `/rent/item/${id}`,
  },
  [ROUTES.MAIN_PRODUCT_QUESTIONS]: {
    name: 'ProductQuestions',
    path: '/rent/rent-details/:id/product-questions',
    getPath: (id: string) => `/rent/rent-details/${id}/product-questions`,
  },
  [ROUTES.MAIN_LEND_PRODUCT_RENT_DETAILS]: {
    name: 'RentDetails',
    path: '/lend/rent-details/:id',
    getPath: (id: string) => `/lend/rent-details/${id}`,
  },
  [ROUTES.MAIN_LEND_PRODUCT]: {
    name: 'RentalProduct',
    path: '/lend/item/:id',
    getPath: (id: string) => `/lend/item/${id}`,
  },

  [ROUTES.LEND]: {
    name: 'Lend',
    path: '/lend',
    getPath: () => `/lend`,
  },
  [ROUTES.RENT]: {
    name: 'Rent',
    path: '/rent',
    getPath: () => `/rent`,
  },
  [ROUTES.HOME]: {
    name: 'Home',
    path: '/home',
    getPath: () => `/home`,
  },

  [ROUTES.NOT_FOUND]: {
    name: 'Not Found',
    path: '/*',
    getPath: () => '/*',
  },
} as const;
