import { FC, useEffect, useState } from 'react';

import RrIcon from 'components/icons/RrIcon';
import CommonModal from 'components/modals/CommonModal';
import Button from 'components/primitives/Button';
import PasswordInput from 'components/primitives/PasswordInput';
import { Form, Formik } from 'formik';
import ModalReference from 'helperClasses/modalReference';
import { $session } from 'state/stores';
import { SiteBlockerProps } from './SiteBlocker.props';

const SiteBlockerView: FC<SiteBlockerProps> = (props) => {
  const authModal = new ModalReference();
  const isAuthenticated = $session.selectors.useIsAuthenticatedBySiteBlocker();
  const savedPassword = process.env.REACT_APP_SITE_BLOCKER_PASS;
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      authModal.open();
    }
  }, []);

  const onSubmit = (value: any) => {
    if (value.password === savedPassword) {
      $session.actions.setIsAuthenticatedBySiteBlocker();
      authModal.close();
    } else {
      setErrorMessage('Incorrect Password');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    }
  };

  return (
    <CommonModal ref={authModal.getReference()}>
      <div className="flex flex-col">
        <div className="p-20 flex justify-center items-center">
          <div className="w-full">
            <RrIcon />
          </div>
          <div className="w-full">
            <Formik onSubmit={onSubmit} initialValues={{ password: '' }}>
              <Form className="flex flex-col justify-center ">
                <PasswordInput name="password" placeholder="Password" />
                <span className="text-red-500">{errorMessage}</span>

                <Button
                  id="open-btn"
                  type="submit"
                  variant="solid"
                  colorScheme="primary"
                  className="w-full mt-5">
                  Submit
                </Button>
              </Form>
            </Formik>
          </div>
        </div>
        <p className="text-sm text-slate-500 text-center">
          Currently in an invite-only beta test limited to 100 users. Please
          email
          <a
            href="mailto:admin@rapidrentals.com"
            className="text-primary hover:underline text-secondary-900">
             admin@rapidrentals.com 
          </a>
          to join our waitlist.
        </p>
      </div>
    </CommonModal>
  );
};

export default SiteBlockerView;
