import { FC } from 'react';
import { ProductToRentProps } from './ProductToRent.props';
import ProductToRentView from './ProductToRentView';
import { $itemRentDetails } from 'state/stores/rent-details';
import useItemReviews from 'hooks/useItemReviews';
import { ReviewHelper } from 'helperClasses/reviews';

const ProductToRent: FC<ProductToRentProps> = ({ handleRentClick }) => {
  return (
    <ProductToRentView
      handleRentClick={handleRentClick}
      isGetCategoriesLoading={false}
    />
  );
};

export default ProductToRent;
