import { PaymentSuccessfullProps } from './PaymentSuccessfull.props';

import { useItem } from 'api/queries';
import { useParams } from 'react-router-dom';
import PaymentSuccessfullView from './PaymentSuccessfull.view';

const PaymentSuccessfullContainer = (props: PaymentSuccessfullProps) => {
  const productId = useParams().id!;

  const { data } = useItem(productId);

  return <PaymentSuccessfullView product={data} />;
};

export default PaymentSuccessfullContainer;
