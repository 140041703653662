import React from 'react';

function ClockIcon({ width = '64', height = '64', disabled = false }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      style={{
        filter: disabled ? 'grayscale(1)' : '',
      }}
      viewBox="0 0 64 64">
      <circle cx="32" cy="34" r="24" fill="#EEE"></circle>
      <circle cx="32" cy="32" r="22" fill="#22CC7A"></circle>
      <circle
        cx="32"
        cy="32"
        r="26"
        stroke="#737373"
        strokeDasharray="4 4"
        strokeWidth="2"></circle>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M31.919 30.022a1.199 1.199 0 01-.024-.22V20.36a1.18 1.18 0 012.361 0v9.443a1.199 1.199 0 01-.023.22 3.149 3.149 0 11-2.4 5.82c-.042.058-.09.111-.142.16l-5.65 5.65a1.166 1.166 0 01-.834.347 1.164 1.164 0 01-.835-.347 1.18 1.18 0 010-1.668l5.65-5.65c.05-.052.103-.1.16-.143a3.149 3.149 0 011.736-4.17z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default ClockIcon;
