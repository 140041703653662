import { useMutation } from '@tanstack/react-query';
import { getRentAvailabilty } from 'api/services/rent';
import { MutationParams } from './mutationTypes';

const RENT_AVAILABILITY_MUTATION_KEY = 'rent_availability_mutation_key';

type Option = {
  id: string;
  startDate: string;
  endDate: string;
};

const useRentAvailabilityMutation = (options?: MutationParams) => {
  return useMutation({
    mutationKey: [RENT_AVAILABILITY_MUTATION_KEY],
    mutationFn: async (payload: Option) =>
      getRentAvailabilty(payload.id, payload.startDate, payload.endDate),
    onSuccess: options?.onSuccess,
  });
};

export default useRentAvailabilityMutation;
