import { queryClient } from 'utils/queryClient';
import { FETCH_CATEGORIES_KEY } from './useCategoriesQuery';
import { FETCH_ITEMS_KEY } from './useItemsQuery';
import { FETCH_ONGIONG_RENTS_KEY } from './useOngoingRentQuery';
import { FETCH_RENT_AVAILABILITY_KEY } from './useRentAvailabilityQuery';
import { FETCH_RENT_DETAILS_KEY } from './useRentDetailsQuery';
import { FETCH_RENT_PAYMENT_DETAILS_KEY } from './useRentPaymentDetailsQuery';
import { FETCH_SUB_CATEGORIES_KEY } from './useSubCategoryQuery';
import { FETCH_USER_DETAIL_KEY } from './useUserQuery';
import { FETCH_TOP_CATEGORIES_KEY } from './useTopCategoryQuery';
import { FETCH_TOP_RENTS_KEY } from './useTopRents';
import { FETCH_ITEM_KEY } from './useItemQuery';
import { FETCH_OWNER_ITEMS_KEY } from './useOwnerItemQuery';
import { FETCH_RENT_HISTORY_KEY } from './useRentHistoryQuery';
import { FETCH_OWNER_REVIEW_RATES } from './useOwnerReviewRates';
import { FETCH_ITEM_REVIEW_RATES } from './useItemReviewRates';
import { FETCH_RENTER_REVIEW_RATES } from './useRenterReviewRates';
import { FETCH_ITEM_REVIEWS } from './useItemReviewsQuery';
import { FETCH_RENTER_REVIEWS } from './useRenterReviewsQuery';
import { FETCH_OWNER_REVIEWS } from './useOwnerReviewsQuery';
import { FETCH_RENT_DETAILS_BY_ID_KEY } from './useRentDetailsByIdQuery';
import { FETCH_USER_OVERALL_RATINGS } from './useOverallUserRatingQuery';

export { default as useCategory } from './useCategoryQuery';
export { default as useCategories } from './useCategoriesQuery';
export { default as useTopCategory } from './useTopCategoryQuery';
export { default as useTopRents } from './useTopRents';
export { default as useItems } from './useItemsQuery';
export { default as useOwnerItem } from './useOwnerItemQuery';
export { default as useItem } from './useItemQuery';
export { default as useOngoingRent } from './useOngoingRentQuery';
export { default as useRentAvailability } from './useRentAvailabilityQuery';
export { default as useRentDetails } from './useRentDetailsQuery';
export { default as useRentDetailsById } from './useRentDetailsByIdQuery';
export { default as useSubCategory } from './useSubCategoryQuery';
export { default as useUser } from './useUserQuery';
export { default as useRentHistory } from './useRentHistoryQuery';
export { default as useRentPaymentDetails } from './useRentPaymentDetailsQuery';
export { default as userItemReviewRates } from './useItemReviewRates';
export { default as userOwnerReviewRates } from './useOwnerReviewRates';
export { default as userRenterReviewRates } from './useRenterReviewRates';
export { default as userItemReviews } from './useItemReviewsQuery';
export { default as useOwnerReviews } from './useOwnerReviewsQuery';
export { default as useRenterReviews } from './useRenterReviewsQuery';
export { default as useUserOverallRating } from './useOverallUserRatingQuery';

export const QUERY_KEYS = {
  async invalidate(key: string) {
    await queryClient.invalidateQueries({ queryKey: [key] });
  },
  async invalidateAll() {
    queryClient.invalidateQueries();
  },
  FETCH_CATEGORIES_KEY,
  FETCH_ITEMS_KEY,
  FETCH_ITEM_KEY,
  FETCH_OWNER_ITEMS_KEY,
  FETCH_ONGIONG_RENTS_KEY,
  FETCH_RENT_AVAILABILITY_KEY,
  FETCH_RENT_DETAILS_KEY,
  FETCH_SUB_CATEGORIES_KEY,
  FETCH_USER_DETAIL_KEY,
  FETCH_RENT_PAYMENT_DETAILS_KEY,
  FETCH_TOP_CATEGORIES_KEY,
  FETCH_TOP_RENTS_KEY,
  FETCH_RENT_HISTORY_KEY,
  FETCH_OWNER_REVIEW_RATES,
  FETCH_ITEM_REVIEW_RATES,
  FETCH_RENTER_REVIEW_RATES,
  FETCH_ITEM_REVIEWS,
  FETCH_OWNER_REVIEWS,
  FETCH_RENTER_REVIEWS,
  FETCH_RENT_DETAILS_BY_ID_KEY,
  FETCH_USER_OVERALL_RATINGS,
  // All query keys go here
} as const;

export const refetchQuery = async (key: string) => {
  await queryClient.refetchQueries({
    queryKey: [key],
    exact: true,
  });
};
