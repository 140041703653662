import { useQuery } from '@tanstack/react-query';
import { fetchCategories } from 'api/services/category';
import { CategoriesResponse, Category } from 'types/category';

const FETCH_CATEGORIES_KEY = 'fetch-categories';

type Payload = {
  onSuccess: (categories: CategoriesResponse) => void;
};

const useCategories = (payload?: Payload) => {
  return useQuery({
    queryKey: [FETCH_CATEGORIES_KEY],
    queryFn: async () => {
      const response = await fetchCategories();
      payload?.onSuccess && payload?.onSuccess(response);
      return response;
    },
  });
};

export default useCategories;

export { FETCH_CATEGORIES_KEY };
