import useUserProfile from 'api/queries/useUserProfile';
import { routesSettings } from 'constants/routes';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { OwnerProfileProps } from './OwnerProfile.props';

import { useCategories, useOwnerItem } from 'api/queries';
import useOverallUserRatings from 'api/queries/useOverallUserRatingQuery';
import debounce from 'lodash.debounce';
import { $session } from 'state/stores';
import OwnerProfileView from './OwnerProfile.view';

const OwnerProfileContainer = (props: OwnerProfileProps) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null,
  );
  const navigate = useNavigate();
  const { ownerId } = useParams();
  const userId = $session.actions.getUserId();
  const { data: userRatings } = useOverallUserRatings(userId!);
  const { data: categories, isLoading: isGetCategoryLoading } = useCategories();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const { profileQuery: ownerProfileQuery } = useUserProfile({
    userId: ownerId,
  });

  const { data, isLoading: isOwnerItemsLoading } = useOwnerItem(ownerId || '');

  // Filter items based on selected category ID and seachQuery
  const filteredItems = data?.items.filter(
    (item) =>
      (selectedCategoryId
        ? item.categories[0]._id == selectedCategoryId
        : true) &&
      item.title.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()),
  );

  const onCardClick = (id?: string) => {
    navigate(routesSettings.MAIN_RENT_PRODUCT.getPath(id!), {
      replace: false,
    });
  };

  const debouncedOnSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 500), // 500 milliseconds debounce time
    [],
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedOnSearch(value);
    },
    [debouncedOnSearch],
  );

  return (
    <OwnerProfileView
      onCardClick={onCardClick}
      filteredItems={filteredItems}
      products={data?.items}
      categories={categories?.items}
      owner={ownerProfileQuery}
      userRatings={userRatings}
      selectedCategoryId={selectedCategoryId}
      onCategoryChange={setSelectedCategoryId}
      onSearch={handleSearch}
    />
  );
};

export default OwnerProfileContainer;
