import { useMutation } from '@tanstack/react-query';
import { rateItem } from 'api/services/reviews';

const useRateItemMutation = (onSuccess?: any) =>
  useMutation({
    mutationFn: rateItem,
    onSuccess: onSuccess,
  });

export default useRateItemMutation;
