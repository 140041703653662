import { OnGoingRent } from 'types/rent';
import DateTimeZ from 'utils/dateTime';
import { RentalStatusEnum } from 'utils/timeline';

class RentHelper {
  rentDetails: OnGoingRent;
  startDate: DateTimeZ;
  endDate: DateTimeZ;
  currentDate: DateTimeZ = new DateTimeZ();
  constructor(rentDetails: OnGoingRent) {
    this.rentDetails = rentDetails;
    this.startDate = new DateTimeZ(rentDetails.startDate);
    this.endDate = new DateTimeZ(rentDetails.endDate);
  }

  isRentCanceled() {
    return (
      this.rentDetails.currentStatus === RentalStatusEnum.CANCELED ||
      this.rentDetails.currentStatus === RentalStatusEnum.PENDING
    );
  }

  getTimeLineStatus() {
    const startDate = this.startDate;
    startDate.setTimeToMidnight();
    const endDate = this.endDate;
    endDate.setTimeToEndOfDay();
    const rentStatus = this.rentDetails.currentStatus;
    const currentDate = new DateTimeZ();

    if (rentStatus === RentalStatusEnum.PENDING) {
      return RentalStatusEnum.PENDING;
    } else if (rentStatus === RentalStatusEnum.CANCELED) {
      return RentalStatusEnum.CANCELED;
    }

    if (this.rentDetails)
      if (!startDate.isDateExpired()) {
        if (rentStatus === RentalStatusEnum.BOOKED) {
          return RentalStatusEnum.BOOKED;
        }
      }

    if (currentDate.isDateInRange(startDate, endDate)) {
      return RentalStatusEnum.RENTAL_IN_PROGRESS;
    }

    if (endDate.isDateExpired()) {
      return RentalStatusEnum.COMPLETED;
    }

    return RentalStatusEnum.PENDING;
  }

  isCurrentRenter() {
    return this.currentDate.isDateInRange(this.startDate, this.endDate);
  }

  rentalPeriod() {
    return this.startDate.differenceInDays(this.endDate);
  }

  isRentalExpired() {
    //add a day to the end date
    let tempEndDate = new DateTimeZ(this.endDate.toString());
    tempEndDate.addDays(1);
    return tempEndDate.isDateExpired();
  }
}

export default RentHelper;
