import EditIcon from 'components/icons/EditIcon';
import React, { FC } from 'react';

import { EditAvatarViewProps } from './EditAvatar.props';
import { useDropzone } from 'react-dropzone';

const EditAvatarView: FC<EditAvatarViewProps> = ({
  setFieldValue,
  localFile,
  setLocalFile,
}) => {
  const {
    getRootProps,
    getInputProps,
    open: onOpenFilesChose,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });

      setLocalFile(file);
      setFieldValue('avatar', file);
    },
    noClick: true,
  });

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} className="input" />
      <button
        type="button"
        onClick={onOpenFilesChose}
        onChange={(e) => {
          setFieldValue('avatar', localFile);
        }}>
        <div className="bg-white rounded-full border-solid border-2 border-[#E7C0ED] p-2 hover:text-white hover:bg-[#E7C0ED] transition-all duration-300">
          <EditIcon w={'1.5rem'} h={'1.5rem'} />
        </div>{' '}
      </button>
    </div>
  );
};

export default EditAvatarView;
