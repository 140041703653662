import { FC } from 'react';

import CommonContainer from 'components/primitives/CommonContainer';
import { RentDetailsViewProps } from './RentDetails.props';

import PoweredByStripeIcon from 'components/icons/PoweredByStripeIcon';
import SuccessModal from 'components/modals/SuccessModal';
import TotalPurchase from 'components/modules/TotalPurchase';
import Button from 'components/primitives/Button';

import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';

const RentDetailsView: FC<RentDetailsViewProps> = ({
  product,
  isLoading,
  onCloseSuccessModal,
  successModalRef,
  onClickPayment,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-8 justify-center items-center md:py-14">
      <div className="text-gray-700 text-3xl font-bold font-primary">
        Review your renting details!
      </div>
      <div className="w-2/3">
        <CommonContainer w={'w-32'}>
          <TotalPurchase product={product} />
        </CommonContainer>
      </div>
      {/* <div className="w-2/3">
        <CommonContainer w={'w-32'}>
          <AddressForm />
        </CommonContainer>
      </div> */}
      {/* <div className="w-2/3">
        <CommonContainer w={'w-32'}>
          <div className="w-auto">
            <div className="text-neutral-700 text-base font-semibold font-['Montserrat']">
              Add your payment information
            </div>
            <div className="divider">Express Checkout</div>
            <div className="flex gap-4">
              <button>
                <img alt="img" src={shopImg} />
              </button>
              <button>
                <img alt="img" src={gpayImg} className="cursor-pointer" />
              </button>
              <button>
                <img alt="img" src={paypalImg} className="cursor-pointer" />
              </button>
              <button>
                <img alt="img" src={applePayImg} className="cursor-pointer" />
              </button>
              <button>
                <img alt="img" src={amazonPayImg} className="cursor-pointer" />
              </button>
            </div>
            <div className="divider">or use a saved card</div>
            <div className="text-center">
              <span className="text-neutral-700 text-sm font-normal font-['Open Sans']">
                Or add a{' '}
              </span>
              <span className="text-blue-600 text-sm font-normal font-['Open Sans']">
                new payment method
              </span>
            </div>
          </div>
        </CommonContainer>
      </div> */}
      <PoweredByStripeIcon />
      <div>
        <span className="text-neutral-700 text-xs font-normal font-secondary">
          By sending the rental request you are agree to our{' '}
        </span>
        <a href={routesSettings.MAIN_TERMS_AND_CONDITIONS.path} target="_blank">
          See <span className="text-[#3769CC]">terms and conditions</span>
        </a>
      </div>

      <div className="flex gap-5">
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="outline"
          colorScheme="secondary"
          className="w-56">
          Back
        </Button>
        <Button
          variant="solid"
          colorScheme="primary"
          isLoading={isLoading}
          onClick={() => onClickPayment()}>
          Continue with payment
        </Button>
      </div>
      <SuccessModal
        ref={successModalRef}
        title="Item rented successfully!"
        onClose={onCloseSuccessModal}
      />
    </div>
  );
};

export default RentDetailsView;
