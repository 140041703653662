import { FC } from 'react';

import CheckBox from 'components/primitives/CheckBox';
import FileInput from 'components/primitives/FileInput';
import { FormInputProps } from './FormInput.props';
import TextInput from 'components/primitives/TextInput';
import TextAreaInput from 'components/primitives/TextAreaInput';
import SelectInput from 'components/primitives/SelectInput';

const FormInput: FC<FormInputProps> = ({
  type,
  name,
  label,
  disabled,
  isRequired,
  placeholder,
  hideErrorComponent,
  labelProps,
  leftIcon,
  children,
  tooltipMessage,
  selectOptions,
}) => {
  const commonProps = { name, label, labelProps, isRequired, leftIcon };

  const InputField = () => {
    switch (type) {
      case 'checkbox':
        return <CheckBox {...commonProps}>{children}</CheckBox>;
      case 'file':
        return <FileInput {...commonProps} />;
      case 'text':
        return (
          <TextInput
            {...commonProps}
            tooltipMessage={tooltipMessage}
            placeholder={placeholder}
            disabled={disabled}
            type={type}>
            {children}
          </TextInput>
        );
      case 'email':
        return (
          <TextInput
            {...commonProps}
            tooltipMessage={tooltipMessage}
            placeholder={placeholder}
            disabled={disabled}
            type={type}>
            {children}
          </TextInput>
        );
      case 'select':
        return (
          <SelectInput
            {...commonProps}
            tooltipMessage={tooltipMessage}
            placeholder={placeholder}
            disabled={disabled}
          />
        );
      case 'password':
        return (
          <TextInput
            {...commonProps}
            tooltipMessage={tooltipMessage}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
            hideErrorComponent={hideErrorComponent}>
            {children}
          </TextInput>
        );
      case 'textArea':
        return (
          <TextAreaInput
            {...commonProps}
            label={label}
            placeholder={placeholder}
          />
        );
      default:
        return null; // or throw an error if an unknown type is encountered
    }
  };
  return <InputField />;
};

export default FormInput;
