import { useQuery } from '@tanstack/react-query';
import { fetchItem } from 'api/services/item';
import { fetchItemReviewRates } from 'api/services/rates';

const FETCH_ITEM_REVIEW_RATES = 'fetch-item-review-rates';

const useItemReviewRatesQuery = () => {
  const query = useQuery({
    queryKey: [FETCH_ITEM_REVIEW_RATES],
    queryFn: () => fetchItemReviewRates(),
  });

  return query;
};

export default useItemReviewRatesQuery;

export { FETCH_ITEM_REVIEW_RATES };
