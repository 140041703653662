import { FC, useMemo } from 'react';

import { $session } from 'state/stores';

import AuthRoutes from './auth';
import MainRoutes from './main';

const RootRoutes: FC = () => {
  const isAuth = $session.selectors.useIsAuth();
  const Routes = useMemo(() => {
    if (isAuth) {
      return MainRoutes;
    }
    return AuthRoutes;
  }, [isAuth]);

  return <Routes />;
};

export default RootRoutes;
