import React from 'react';

type InfoIconProps = {
  fill?: string | undefined;
};

const InfoIcon = (props: InfoIconProps) => {
  const { fill } = props;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1412_15551"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1412_15551)">
        <path
          d="M7.33398 11.332H8.66732V7.33203H7.33398V11.332ZM8.00065 5.9987C8.18954 5.9987 8.34787 5.93481 8.47565 5.80703C8.60343 5.67925 8.66732 5.52092 8.66732 5.33203C8.66732 5.14314 8.60343 4.98481 8.47565 4.85703C8.34787 4.72925 8.18954 4.66536 8.00065 4.66536C7.81176 4.66536 7.65343 4.72925 7.52565 4.85703C7.39787 4.98481 7.33398 5.14314 7.33398 5.33203C7.33398 5.52092 7.39787 5.67925 7.52565 5.80703C7.65343 5.93481 7.81176 5.9987 8.00065 5.9987ZM8.00065 14.6654C7.07843 14.6654 6.21176 14.4904 5.40065 14.1404C4.58954 13.7904 3.88398 13.3154 3.28398 12.7154C2.68398 12.1154 2.20898 11.4098 1.85898 10.5987C1.50898 9.78759 1.33398 8.92092 1.33398 7.9987C1.33398 7.07648 1.50898 6.20981 1.85898 5.3987C2.20898 4.58759 2.68398 3.88203 3.28398 3.28203C3.88398 2.68203 4.58954 2.20703 5.40065 1.85703C6.21176 1.50703 7.07843 1.33203 8.00065 1.33203C8.92287 1.33203 9.78954 1.50703 10.6007 1.85703C11.4118 2.20703 12.1173 2.68203 12.7173 3.28203C13.3173 3.88203 13.7923 4.58759 14.1423 5.3987C14.4923 6.20981 14.6673 7.07648 14.6673 7.9987C14.6673 8.92092 14.4923 9.78759 14.1423 10.5987C13.7923 11.4098 13.3173 12.1154 12.7173 12.7154C12.1173 13.3154 11.4118 13.7904 10.6007 14.1404C9.78954 14.4904 8.92287 14.6654 8.00065 14.6654Z"
          fill="#682082"
        />
      </g>
    </svg>
  );
};

export default InfoIcon;
