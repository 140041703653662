import { useMutation } from '@tanstack/react-query';
import { rateRenter } from 'api/services/reviews';

const useRateRenterMutation = (onSuccess?: any) =>
  useMutation({
    mutationFn: rateRenter,
    onSuccess: onSuccess,
  });

export default useRateRenterMutation;
