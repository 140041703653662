import React, { FC } from 'react';

import { ProfileStripeSectionProps } from './ProfileStripeSection.props';

import ProfileStripeSectionView from './ProfileStripeSection.view';
import { $stripe } from 'state/stores/stripe';
import { $user } from 'state/stores/user';
import useConnectToStripeMutation from 'api/mutations/useConnectToStripeMutation';
import { getStripeDashboardUrl } from 'api/services/stripe';

const ProfileStripeSectionContainer: FC<ProfileStripeSectionProps> = (
  props,
) => {
  const stripeId = $user.selectors.useStripeId();
  const isFetchingStripeStatus = $stripe.selectors.useIsFetchingData();
  const isConnectedToStripe = $stripe.selectors.useIsDetailsSubmitted();

  const { mutate: connectStripeAccount, isPending } =
    useConnectToStripeMutation(stripeId!);

  const openStripeDashboard = async () => {
    if (!stripeId) return;
    const dashboardUrl = await getStripeDashboardUrl(stripeId!);
    window.open(dashboardUrl, '_self');
  };

  return (
    <ProfileStripeSectionView
      isFetching={isFetchingStripeStatus}
      isConnected={isConnectedToStripe}
      onClickConnect={connectStripeAccount}
      onClickChangeInformation={openStripeDashboard}
    />
  );
};

export default ProfileStripeSectionContainer;
