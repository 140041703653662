import useQuestions, { FETCH_QUESTIONS_KEY } from 'api/queries/useQuestions';
import { FC, useRef, useState } from 'react';
import { QuestionsProps } from './Questions.props';

import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import Loading from 'components/primitives/Loading';
import { queryClient } from 'utils';
import QuestionsView from './Questions.view';

const QuestionsContainer: FC<QuestionsProps> = ({
  product,
  hideSeeAll = false,
  showAllQuestions = false,
  enableBackButton = false,
}) => {
  const productId = product?._id;
  const [selectedQuestion, setselectedQuestion] = useState({
    id: '',
  });
  const successModalRef = useRef<CommonModalRef>(null);

  const [askInput, setAskInput] = useState(false);

  const onSuccess = () => {
    setselectedQuestion({
      id: '',
    });
    queryClient.invalidateQueries({ queryKey: [FETCH_QUESTIONS_KEY] });
    setAskInput(false);
    successModalRef.current?.onOpen();
    setInterval(() => {
      successModalRef.current?.onClose!();
    }, 3000);
  };

  const {
    questionsData,
    isQuestionsLoading,
    createItemQuestion,
    updateQuestion,
    isLoadingCreate,
    mutateDelete,
    isLoadingUpdate,
    isLoadingDelete,
  } = useQuestions(productId, onSuccess);

  const onAskAQuestion = (replyId?: string) => {
    setAskInput(true);
    setselectedQuestion((prevState) => ({
      ...prevState,
      id: replyId || '',
    }));
  };

  const sortedQuestions = questionsData?.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const questionsByItem = showAllQuestions
    ? sortedQuestions
    : sortedQuestions?.slice(0, 4); //to limit the visible questions.

  if (isQuestionsLoading) return <Loading />;

  return (
    <QuestionsView
      product={product}
      enableBackButton={enableBackButton}
      onCreateQuestion={createItemQuestion}
      onUpdateQuestion={updateQuestion}
      askInput={askInput}
      onAskAQuestion={onAskAQuestion}
      questions={questionsByItem}
      isLoading={isQuestionsLoading}
      isLoadingDelete={isLoadingDelete}
      isLoadingCreate={isLoadingCreate}
      selectedQuestion={selectedQuestion}
      mutateDelete={mutateDelete}
      hideSeeAll={hideSeeAll}
      isLoadingUpdate={isLoadingUpdate}
      ref={successModalRef}
    />
  );
};

export default QuestionsContainer;
