import * as Yup from 'yup';

export const initialData = {
  value: 0,
  title: [],
  description: '',
};

export const validationSchema = Yup.object().shape({
  value: Yup.number().min(1),
  title: Yup.array()
    .of(Yup.boolean())
    .test('atLeastOneTrue', 'Select at least one', (value) =>
      value!.includes(true),
    )
    .required(),
});
