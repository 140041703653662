import Avatar from 'components/primitives/Avatar';
import Button from 'components/primitives/Button';
import TextInput from 'components/primitives/TextInput';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React, { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNetworkImage } from 'utils/assets';

import ArrowLeft from 'components/icons/ArrowLeft';
import AlertModal from 'components/modals/AlertModal';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import SuccessModal from 'components/modals/SuccessModal';
import NoQuetionsFound from 'components/vectors/NoQuetionsFound';
import { routesSettings } from 'constants/routes';
import ModalReference from 'helperClasses/modalReference';
import { $session } from 'state/stores';
import { QuestionsViewProps } from './Questions.props';

const QuestionsView = forwardRef<CommonModalRef, QuestionsViewProps>(
  (
    {
      product,
      askInput,
      questions,
      isLoading,
      selectedQuestion,
      isLoadingCreate,
      isLoadingDelete,
      isLoadingUpdate,
      onCreateQuestion,
      onUpdateQuestion,
      mutateDelete,
      onAskAQuestion,
      hideSeeAll = false,
      enableBackButton = false,
    },
    ref,
  ) => {
    const alertModalRef = new ModalReference();
    const reportModal = new ModalReference();
    const userId = $session.actions.getUserId();
    const isOwnersProduct = product?.ownerId === userId;
    const [currentQuestionId, setCurrentQuestionId] = useState<string | null>();
    const navigate = useNavigate();

    const [showQuestionNotFound, setShowQuestionNotFound] = useState<boolean>(
      questions?.length == 0,
    );

    useEffect(() => {
      setShowQuestionNotFound(questions?.length == 0);
    }, []);

    const navigateToAllQuestions = () => {
      navigate(routesSettings.MAIN_PRODUCT_QUESTIONS.getPath(product?._id!));
    };

    const initialValues = {
      question: '',
      itemId: product?._id,
    };

    const showAskButtom = !askInput && !isOwnersProduct;
    const showAnswerButtom =
      !askInput && isOwnersProduct && questions && questions?.length > 0;

    return showQuestionNotFound ? (
      <div className="w-full flex justify-center items-center gap-[10%]">
        <div className="space-y-4">
          <h3 className="font-[500] leading-5">
            No questions have been asked <br /> for this item.
          </h3>

          {!isOwnersProduct && (
            <div className="space-y-4">
              <h3 className="text-secondary-900 font-[700]">
                Be the first
                <br /> to share your feedback!
              </h3>
              <div className="flex w-full items-start justify-start">
                <Button
                  variant={'solid'}
                  type={'button'}
                  colorScheme={'primary'}
                  onClick={() => {
                    setShowQuestionNotFound(false);
                    onAskAQuestion();
                    //
                  }}>
                  Ask a Question
                </Button>
              </div>
            </div>
          )}
        </div>
        <NoQuetionsFound />
      </div>
    ) : (
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-col">
          <div
            className={`flex items-start mb-4 ${askInput ? 'flex-col gap-2' : 'flex-row'} placeholder:`}>
            <div className="flex flex-row gap-2 justify-start items-center">
              {enableBackButton && (
                <button onClick={() => navigate(-1)}>
                  <ArrowLeft color="#22CC7A" size="40" />
                </button>
              )}
              <h1>Questions</h1>
              {!hideSeeAll && questions && questions?.length >= 4 && (
                <button
                  onClick={navigateToAllQuestions}
                  className="hover:text-primary-500 w-20">
                  see all
                </button>
              )}
            </div>

            {askInput && !selectedQuestion?.id && (
              <div className="w-full my-4">
                <div className="my-5 justify-center font-primary text-lg">
                  <p className="mb-2">Ask The Owner</p>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => onCreateQuestion(values)}>
                    <Form className="flex gap-8 justify-between items-start w-full">
                      <TextInput
                        name={'question'}
                        hideErrorComponent
                        className="w-full bg-white min-w-full"
                      />
                      <Button
                        isLoading={isLoadingCreate}
                        variant={'solid'}
                        type={'submit'}
                        colorScheme={'primary'}>
                        Send Question
                      </Button>
                    </Form>
                  </Formik>
                </div>
              </div>
            )}

            {showAskButtom && (
              <div className="flex w-full  items-end justify-end">
                <Button
                  variant={'solid'}
                  type={'button'}
                  colorScheme={'primary'}
                  onClick={() => onAskAQuestion()}>
                  Ask a Question
                </Button>
              </div>
            )}
          </div>
        </div>
        {questions?.map((question, index) => {
          const isQuestionAuthor = question.ownerId === userId;
          return (
            <React.Fragment key={`${question?._id}-${index}`}>
              <div className="gap-4 ">
                {/* Question */}
                <div className="h-10 group flex cursor-pointer justify-between rounded-full">
                  <div className="flex items-center gap-8">
                    <Avatar
                      className="w-10 h-10"
                      noBorder
                      imgSrc={getNetworkImage(question.profile.avatar) || ''}
                    />
                    <div className="flex gap-2 items-end">
                      <span className="font-semibold text-lg font-secondary capitalize">
                        {question.question}
                      </span>
                      <span className="font-normal text-sm font-secondary capitalize">
                        {question.replies && question.replies.createdAt
                          ? format(
                              new Date(question.replies.createdAt),
                              'MM/dd/yyyy',
                            )
                          : ''}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-between items-end cursor-pointer">
                    {isQuestionAuthor ? (
                      <span
                        className="text-gray-400 invisible opacity-0 cursor-pointer group-hover:visible group-hover:opacity-100 transition-opacity duration-300"
                        onClick={() => {
                          setCurrentQuestionId(question._id);
                          alertModalRef.open();
                        }}>
                        Delete
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          reportModal.open();
                        }}
                        className="text-gray-400 invisible opacity-0 cursor-pointer group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                        Report
                      </span>
                    )}
                  </div>
                </div>

                {/* Replies */}
                <div className="flex w-full flex-col gap-7">
                  {question?.replies && !selectedQuestion?.id && (
                    <div className="flex justify-between group cursor-pointer">
                      <div className="flex ml-32 items-end gap-2">
                        <span className="font-normal text-lg font-secondary capitalize">
                          {`${question.replies.question}`}
                        </span>
                        <span className="font-normal font-secondary capitalize text-sm">
                          {question.replies.createdAt
                            ? format(
                                new Date(question.replies.createdAt),
                                'MM/dd/yyyy',
                              )
                            : ''}
                        </span>
                      </div>
                      {/* IF THE USER IS THE OWNER OF THE PRODUCT CAN EDIT HIS REPLY */}
                      {isOwnersProduct && (
                        <span
                          className="text-gray-400 invisible opacity-0 cursor-pointer group-hover:visible group-hover:opacity-100 transition-opacity duration-300"
                          onClick={() =>
                            onAskAQuestion(question?.replies?._id)
                          }>
                          Edit
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* SEND REPLY IF THE USER IS THE OWNER AND THERE IS NO REPLY */}
              {showAnswerButtom &&
                !question.replies &&
                !selectedQuestion?.id && (
                  <div className="ml-32 justify-center font-primary text-lg">
                    <Formik
                      initialValues={{
                        question: '',
                        itemId: product?._id,
                        replyId: question?._id,
                      }}
                      onSubmit={(values) => {
                        setCurrentQuestionId(question._id);
                        onCreateQuestion(values);
                      }}>
                      {({ values }) => (
                        <Form className="flex gap-8 justify-between items-start w-full">
                          <TextInput
                            name={'question'}
                            className="w-full bg-white min-w-full"
                            hideErrorComponent
                          />
                          <Button
                            isLoading={
                              isLoadingCreate &&
                              question._id == currentQuestionId
                            }
                            variant={'solid'}
                            type={'submit'}
                            colorScheme={'primary'}>
                            Send Reply
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              {/* EDIT A REPLY */}
              {selectedQuestion?.id === question?.replies?._id && (
                <div className="ml-32 justify-center font-primary text-lg">
                  <Formik
                    initialValues={{
                      question: question?.replies.question,
                    }}
                    onSubmit={(values) =>
                      onUpdateQuestion({
                        questionId: question.replies._id,
                        payload: values,
                      })
                    }>
                    {({ values }) => {
                      return (
                        <Form className="flex gap-8 justify-between items-start w-full">
                          <TextInput
                            name={'question'}
                            className="w-full bg-white min-w-full"
                            hideErrorComponent
                          />
                          <Button
                            isLoading={isLoadingUpdate}
                            variant={'solid'}
                            type={'submit'}
                            colorScheme={'primary'}>
                            Send Reply
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}
              {questions.length > 1 && <div className="divider m-0 p-0" />}
            </React.Fragment>
          );
        })}
        <SuccessModal
          ref={ref}
          onClose={() => {
            alertModalRef.close();
          }}
        />
        <AlertModal
          ref={alertModalRef.getReference()}
          onClickButton={async () => {
            if (currentQuestionId) {
              await mutateDelete(currentQuestionId);
              alertModalRef.close();
            }
          }}
          title="Are you sure you want to delete the selection?"
          description="You will not be able to recover its contents. If you want to republish it you will have to create it from scratch."
          buttonTitle="Delete Selection"
          buttonIsLoading={isLoadingDelete}
        />

        <AlertModal
          ref={reportModal.getReference()}
          onClickButton={() => reportModal.close()}>
          <p className="text-center">
            Thank you for reporting this question. To address your concern,
            please contact the administrator at 
            <a href="mailto:admin@rapidrentals.com">
              <span className="font-bold text-secondary-900">
                admin@rapidrentals.com
              </span>
            </a>
             and provide a screenshot of this message for reference. Your
            feedback helps us maintain the quality of our platform. We
            appreciate your assistance in keeping our community safe and
            constructive
          </p>
        </AlertModal>
      </div>
    );
  },
);

export default QuestionsView;
