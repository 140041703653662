import React, { FC, useRef } from 'react';

import { RateItemProps } from './RateItem.props';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import { initialData, validationSchema } from './RateItem.utils';
import SuccessModal from 'components/modals/SuccessModal';
import CommonModal from 'components/modals/CommonModal';
import { Form, Formik } from 'formik';
import FormikRating from 'components/form/FormikRating';
import FormikSelection from 'components/form/FormikSelection';
import FormikTextArea from 'components/form/FormikTextArea';
import Button from 'components/primitives/Button';
import ModalReference from 'helperClasses/modalReference';
import { userItemReviewRates } from 'api/queries';
import Loading from 'components/primitives/Loading';
import useRateItemMutation from 'api/mutations/useRateItemMutation';
import { ReviewPayload } from 'types/reviews';

const RateItemView: FC<RateItemProps> = ({
  child,
  onSuccess,
  rentId,
  renterId,
  itemId,
}) => {
  const modalRef = new ModalReference();
  const successModalRef = new ModalReference();

  const { data: options, isLoading: isOptionsLoading } = userItemReviewRates();

  const showRatingDialog = () => {
    modalRef.open();
  };

  const { mutate: rate, isPending } = useRateItemMutation((data: any) => {
    onSuccess && onSuccess();
    handleModalsOnSuccess();
  });

  const onSubmit = (data: typeof initialData) => {
    //mutation will go here

    const renterCaracteristics: any = [];

    data.title.forEach((isChecked, index) => {
      if (isChecked) {
        renterCaracteristics.push(options![index]._id);
      }
    });

    const payload: ReviewPayload = {
      reviewerId: renterId,
      itemId: itemId,
      rentId: rentId,
      description: data.description,
      rate: data.value,
      renterCaracteristics: renterCaracteristics,
    };

    rate(payload);
  };

  const handleModalsOnSuccess = () => {
    modalRef.close();
  };

  return (
    <div>
      <div className="cursor-pointer" onClick={showRatingDialog}>
        {child}
      </div>
      <SuccessModal
        title="Feedback Sent!"
        description={`We're thrilled to have you on board. Rapid Rentals is all about making renting and lending easier and more enjoyable. Let's take you through the options you have on our platform.`}
        ref={successModalRef.getReference()}
      />
      <CommonModal ref={modalRef.getReference()}>
        <div className="px-8 lg:px-60 py-10 w-full flex flex-col justify-center items-center space-y-8">
          <h1 className="text-3xl">Rate the item</h1>
          <Formik
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm();
            }}
            initialValues={initialData}
            validationSchema={validationSchema}>
            {({ isValid }) => {
              return (
                <Form className="space-y-8 flex flex-col justify-center items-center">
                  <FormikRating name="value" />
                  {isOptionsLoading ? (
                    <Loading />
                  ) : (
                    <FormikSelection
                      name="title"
                      isLoading={isOptionsLoading}
                      itemCount={options?.length ?? 0}
                      enableMultiSelect={true}
                      builder={(index) => {
                        return (
                          <span className="">{options![index].title}</span>
                        );
                      }}
                    />
                  )}

                  <FormikTextArea
                    name="description"
                    placeholder="Here you can explain better"
                  />
                  <Button
                    isDisabled={!isValid}
                    isLoading={isPending}
                    variant="solid"
                    colorScheme="primary">
                    Send feedback
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </CommonModal>
    </div>
  );
};

export default RateItemView;
