import { FC, useEffect } from 'react';

import { Form, Formik } from 'formik';
import { ConfirmIdentityViewProps } from './ConfirmIdentity.props';

import FormInput from 'components/form/FormInput';
import Button from 'components/primitives/Button';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { useHeaderContext } from 'state/contexts/headerContext';
import { initialValues, validationSchema } from './ConfirmIdentity.utils';
// import ConfirmIdentityIcon from 'components/icons/ConfirmIdentityIcon';

const ConfirmIdentityView: FC<ConfirmIdentityViewProps> = (props) => {
  const { hideAuthButtons } = useHeaderContext();

  useEffect(() => {
    hideAuthButtons();
  }, []);

  const navigate = useNavigate();

  const nextStep = () => {
    navigate(routesSettings.AUTH_ACCOUNT_CREATED.path, {
      replace: false,
    });
  };

  return (
    <div className="h-screen flex flex-col items-center py-16">
      {/* <ConfirmIdentityIcon w="80" h="80" /> */}
      <h2 className="my-2">Confirm your identity</h2>
      <p className="text-center font-secondary mt-1 w-1/3">
        To keep our platform secure, we need your identity confirmation. You can
        start now or do it later. You can still browse and create items, but
        posting or renting will resume after verification. Thank you for your
        cooperation and understanding.
      </p>

      <div className="w-96 my-2">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, formikHelpers) => {
            nextStep();
            // await createAccount();
            formikHelpers.resetForm();
          }}
          validationSchema={validationSchema}>
          <Form>
            <div>
              <FormInput type="text" name="name" placeholder="Name" />
              <FormInput type="text" name="phone" placeholder="Phone Number" />
              <FormInput
                type="text"
                name="idVerification"
                placeholder="ID Verification Number"
              />
              <FormInput
                type="text"
                name="zipcode"
                placeholder="city / zip code"
              />
              <Button
                id="open-btn"
                type="submit"
                variant="solid"
                colorScheme="primary"
                className="w-full mt-5">
                Next Step
              </Button>
            </div>
          </Form>
        </Formik>
        {/* <Button
          variant="outline"
          colorScheme="secondary"
          onClick={goBack}
          className="w-full mt-5">
          Back
        </Button> */}
        {/* <Button
          type="button"
          variant="outline"
          onClick={nextStep}
          colorScheme="secondary"
          className="w-full mt-5">
          Do it Later
        </Button> */}
      </div>
    </div>
  );
};

export default ConfirmIdentityView;
