import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

interface HideContextType {
  isAuthButtonVisible: boolean;
  isMainHeaderEnabled: boolean;
  hideAuthButtons: () => void;
  hideMainHeader: () => void;
}

export const HeaderContext = createContext<HideContextType>({
  isAuthButtonVisible: true,
  isMainHeaderEnabled: true,
  hideAuthButtons: () => {},
  hideMainHeader: () => {},
});

export const useHeaderContext = () => useContext(HeaderContext);

const HeaderContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isMainHeaderEnabled, setMainHeaderEnabled] = useState(true);
  const [isAuthHeaderEnabled, setAuthButtonEnabled] = useState(true);

  const hideAuthButtons = () => {
    setAuthButtonEnabled(false);
  };

  const hideMainHeader = () => {
    setMainHeaderEnabled(false);
  };

  const location = useLocation();

  useEffect(() => {
    // Reset the state to default values when the path changes
    setMainHeaderEnabled(true);
  }, [location.pathname]);

  return (
    <HeaderContext.Provider
      value={{
        isMainHeaderEnabled,
        isAuthButtonVisible: isAuthHeaderEnabled,
        hideAuthButtons,
        hideMainHeader,
      }}>
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderContextProvider;
