import React, { FC } from 'react';

import { MenuHeaderProps } from './MenuHeader.props';

import MenuHeaderView from './MenuHeader.view';

import { $user } from 'state/stores/user';
import useUserProfile from 'api/queries/useUserProfile';
import { $session } from 'state/stores';

const MenuHeaderContainer: FC<MenuHeaderProps> = (props) => {
  const fullName = $user.selectors.getFullName();
  const userId = $session.actions.getUserId();

  const { profileQuery } = useUserProfile({
    userId,
  });

  $user.actions.setAddress(profileQuery?.address!);

  return <MenuHeaderView fullName={fullName} userProfile={profileQuery} />;
};

export default MenuHeaderContainer;
