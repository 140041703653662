import React, { FC } from 'react';

import { FileInputProps } from './FileInput.props';

import FileInputView from './FileInput.view';

const FileInputContainer: FC<FileInputProps> = (props) => {
  return <FileInputView {...props} />;
};

export default FileInputContainer;
