import React, { FC } from 'react';

import { NavbarLogoProps } from './NavbarLogo.props';

const NavbarLogoView: FC<NavbarLogoProps> = ({ width = 38, height = 38 }) => {
  return (
    <div className="flex flex-row items-center">
      <svg
        width={width}
        height={height}
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4472 21.3813C32.7668 20.1079 33.5027 18.3963 33.5189 16.5619C33.5352 14.7274 32.8299 13.0028 31.5324 11.7053L31.4804 11.6533C30.1991 10.3721 28.496 9.66675 26.6849 9.66675H7.2477L5.14868 13.047H26.6849C27.593 13.047 28.4472 13.4006 29.0908 14.0442L29.1428 14.0962C29.7935 14.7469 30.1471 15.6115 30.1393 16.532C30.1308 17.4518 29.7616 18.3105 29.0999 18.9495C28.4622 19.5644 27.6236 19.9031 26.7376 19.9031H18.1367H18.0614H17.9801H14.1578L14.0791 20.0299L12.0574 23.2834L8.4412 29.1033H12.4202L16.0364 23.2834L16.0689 23.2314L16.1008 23.2834L19.717 29.1033H23.6966L20.0804 23.2834H21.3662L24.9824 29.1033H28.9621L25.3458 23.2834H26.7376C28.5038 23.2834 30.1764 22.608 31.4472 21.3813ZM28.8389 16.5274V16.5053C28.8389 16.4786 28.8383 16.45 28.837 16.4195V16.4195C28.837 16.4149 28.837 16.4104 28.8363 16.4058L28.8324 16.3486C28.8058 15.9846 28.6764 15.4392 28.198 14.9621L28.1349 14.8984C27.7202 14.4836 27.1709 14.2561 26.5878 14.2561H12.3471L12.0877 14.6722L9.83661 18.278L9.57788 18.694L5.71657 24.9085L3.10986 29.1033H7.08948L9.69619 24.9085L13.5575 18.694H26.6522C27.2223 18.694 27.7612 18.4763 28.1707 18.081C28.7089 17.561 28.8227 16.9519 28.837 16.595C28.8374 16.5909 28.8375 16.5865 28.8376 16.5821C28.8376 16.579 28.8376 16.5759 28.8376 16.5729C28.8376 16.5679 28.8378 16.563 28.838 16.5581C28.8381 16.5534 28.8383 16.5488 28.8383 16.5443C28.8389 16.5385 28.8389 16.5326 28.8389 16.5274V16.5274Z"
          fill="url(#paint0_linear_564_3096)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_564_3096"
            x1="18.3145"
            y1="9.66675"
            x2="18.3145"
            y2="29.1033"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#22CC7A" />
            <stop offset="1" stopColor="#155239" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="107"
        height="15"
        viewBox="0 0 107 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.335562 11.7763L2.32338 1.94836H6.56949C7.83771 1.94836 8.82269 2.22585 9.52442 2.77577C10.2262 3.32821 10.5757 4.09507 10.5757 5.07887C10.5757 5.90376 10.3742 6.62016 9.97098 7.23314C9.5678 7.84613 9.00131 8.32037 8.26641 8.65839C7.5315 8.99642 6.6588 9.16291 5.64576 9.16291H2.43565L3.92588 7.96974L3.14504 11.7738H0.333008L0.335562 11.7763ZM3.85698 8.30776L2.8618 7.00107H5.78866C6.40363 7.00107 6.88081 6.8598 7.21509 6.5798C7.55192 6.29979 7.72033 5.88609 7.72033 5.34374C7.72033 4.92247 7.58509 4.61724 7.3146 4.42301C7.04412 4.23129 6.6588 4.13543 6.15611 4.13543H3.45635L4.96189 2.81613L3.85443 8.30524L3.85698 8.30776ZM6.5848 11.7763L4.5383 8.19677H7.44985L9.52442 11.7763H6.5848Z"
          fill="#155239"
        />
        <path
          d="M14.2125 11.9022C13.6639 11.9022 13.1408 11.7659 12.6432 11.496C12.1456 11.2236 11.7424 10.8326 11.4362 10.3155C11.1275 9.80086 10.9744 9.17274 10.9744 8.43362C10.9744 7.79794 11.0841 7.2127 11.301 6.67792C11.5179 6.14313 11.819 5.67898 12.2018 5.28041C12.5845 4.88185 13.0311 4.57409 13.5363 4.35463C14.0416 4.13516 14.58 4.02417 15.149 4.02417C15.7742 4.02417 16.3101 4.14526 16.7541 4.38995C17.1981 4.63463 17.5273 5.02059 17.7416 5.55537C17.956 6.09016 18.0044 6.79647 17.8896 7.67433C17.8054 8.54462 17.6038 9.29635 17.2849 9.92699C16.9659 10.5576 16.5499 11.0445 16.0345 11.3876C15.519 11.7281 14.9092 11.8996 14.21 11.8996L14.2125 11.9022ZM15.1643 9.75293C15.5241 9.75293 15.8406 9.66464 16.1161 9.48554C16.3917 9.30644 16.6061 9.05922 16.7617 8.74138C16.9174 8.42353 16.9965 8.04767 16.9965 7.61883C16.9965 7.19 16.8689 6.83936 16.6137 6.57197C16.3586 6.30457 16.0039 6.17088 15.5497 6.17088C15.1899 6.17088 14.8709 6.25917 14.5902 6.43827C14.3121 6.61737 14.0926 6.86711 13.937 7.19C13.7813 7.51289 13.7022 7.8837 13.7022 8.30497C13.7022 8.72624 13.8298 9.09706 14.085 9.35689C14.3401 9.61671 14.6999 9.75041 15.1643 9.75041V9.75293ZM16.3152 11.7735L16.5704 10.4946L17.0807 7.96695L17.4354 5.46708L17.6906 4.14778H20.3903L18.8567 11.771H16.3152V11.7735Z"
          fill="#155239"
        />
        <path
          d="M20.0323 14.5004L22.1197 4.15282H24.6612L24.406 5.44438L23.9085 7.95686L23.5538 10.4567L22.7295 14.5004H20.0298H20.0323ZM25.8274 11.9022C25.22 11.9022 24.6918 11.7836 24.2376 11.544C23.7834 11.3043 23.4491 10.9184 23.2373 10.3861C23.0255 9.85383 22.9745 9.14247 23.0893 8.252C23.1838 7.3918 23.3879 6.64512 23.6992 6.01196C24.0105 5.38131 24.429 4.89193 24.9496 4.54382C25.4701 4.19823 26.0774 4.02417 26.7664 4.02417C27.3252 4.02417 27.8509 4.16291 28.3434 4.43788C28.8359 4.71284 29.2365 5.10635 29.5427 5.61844C29.8489 6.13052 30.0046 6.75359 30.0046 7.4927C30.0046 8.12839 29.8949 8.71616 29.678 9.25599C29.4611 9.79329 29.16 10.26 28.7772 10.6535C28.3944 11.047 27.9479 11.3522 27.4426 11.5742C26.9348 11.7937 26.399 11.9047 25.8299 11.9047L25.8274 11.9022ZM25.4293 9.75293C25.7993 9.75293 26.1208 9.66464 26.3964 9.48554C26.672 9.30644 26.8863 9.05922 27.042 8.74138C27.1977 8.42354 27.2768 8.04767 27.2768 7.61883C27.2768 7.19 27.1492 6.83936 26.894 6.57197C26.6388 6.30458 26.279 6.17088 25.8146 6.17088C25.465 6.17088 25.1486 6.25917 24.8705 6.43827C24.5923 6.61737 24.3729 6.86711 24.2172 7.19C24.0616 7.51289 23.9825 7.8837 23.9825 8.30497C23.9825 8.72624 24.11 9.09706 24.3652 9.35689C24.6204 9.61671 24.9751 9.75041 25.4293 9.75041V9.75293Z"
          fill="#155239"
        />
        <path
          d="M30.4614 11.7759L31.995 4.15268H34.6948L33.1459 11.7759H30.4614ZM33.6996 3.32276C33.225 3.32276 32.8524 3.19411 32.5768 2.9368C32.3012 2.6795 32.166 2.36923 32.166 2.00346C32.166 1.5721 32.3242 1.21641 32.6432 0.92884C32.9596 0.64379 33.3832 0.5 33.9139 0.5C34.3784 0.5 34.7509 0.616042 35.0367 0.850641C35.32 1.08524 35.4629 1.38543 35.4629 1.74868C35.4629 2.20778 35.3021 2.58365 34.9806 2.87879C34.6591 3.17393 34.2329 3.32024 33.7022 3.32024L33.6996 3.32276Z"
          fill="#155239"
        />
        <path
          d="M38.4454 11.9021C37.8968 11.9021 37.3711 11.7658 36.8684 11.4959C36.3657 11.2235 35.9625 10.8325 35.6538 10.3154C35.345 9.80075 35.1919 9.17263 35.1919 8.43352C35.1919 7.79783 35.3042 7.2126 35.5262 6.67781C35.7482 6.14303 36.0518 5.67887 36.4346 5.2803C36.8174 4.88174 37.2614 4.57398 37.7615 4.35452C38.2642 4.13505 38.8026 4.02406 39.3793 4.02406C39.956 4.02406 40.4281 4.12245 40.8491 4.31921C41.2702 4.51597 41.5968 4.8212 41.829 5.23238C42.0612 5.64355 42.176 6.18843 42.176 6.86195C42.176 7.53548 42.0944 8.17369 41.9336 8.77911C41.7729 9.382 41.533 9.91931 41.2166 10.386C40.9002 10.8552 40.5098 11.2235 40.0453 11.4959C39.5809 11.7684 39.045 11.9021 38.4403 11.9021H38.4454ZM39.3819 9.75282C39.7417 9.75282 40.0581 9.66454 40.3337 9.48543C40.6093 9.30633 40.8236 9.05912 40.9793 8.74127C41.1349 8.42343 41.214 8.04756 41.214 7.61872C41.214 7.18989 41.0864 6.83925 40.8313 6.57186C40.5761 6.30447 40.2214 6.17077 39.7672 6.17077C39.4074 6.17077 39.091 6.25906 38.8154 6.43816C38.5398 6.61727 38.3255 6.867 38.1698 7.18989C38.0141 7.51278 37.935 7.88359 37.935 8.30486C37.935 8.72613 38.0601 9.09695 38.3101 9.35678C38.5602 9.6166 38.9175 9.7503 39.3819 9.7503V9.75282ZM40.5174 11.7734L40.7879 10.4945L41.2855 7.96684L41.6402 5.46697L42.4644 1.35266H45.1769L43.0743 11.7709H40.5174V11.7734Z"
          fill="#155239"
        />
        <path
          d="M49.1302 11.7121L51.1181 1.88416H55.3642C56.6324 1.88416 57.6174 2.16164 58.3191 2.71156C59.0208 3.264 59.3704 4.03086 59.3704 5.01467C59.3704 5.83955 59.1688 6.55596 58.7657 7.16894C58.3625 7.78193 57.796 8.25617 57.0611 8.59419C56.3262 8.93222 55.4535 9.09871 54.4404 9.09871H51.2303L52.7206 7.90553L51.9397 11.7096H49.1277L49.1302 11.7121ZM52.6517 8.24356L51.6565 6.93686H54.5833C55.1983 6.93686 55.6755 6.7956 56.0098 6.5156C56.3466 6.23559 56.515 5.82189 56.515 5.27954C56.515 4.85827 56.3798 4.55303 56.1093 4.3588C55.8388 4.16708 55.4535 4.07123 54.9508 4.07123H52.251L53.7566 2.75192L52.6491 8.24104L52.6517 8.24356ZM55.3795 11.7121L53.333 8.13257H56.2445L58.3191 11.7121H55.3795Z"
          fill="#155239"
        />
        <path
          d="M63.8595 11.8387C63.0353 11.8387 62.3157 11.6949 61.7007 11.4099C61.0857 11.1248 60.6086 10.7212 60.2743 10.2016C59.9375 9.68192 59.769 9.07146 59.769 8.37019C59.769 7.52765 59.9655 6.77339 60.3585 6.10996C60.7515 5.44652 61.2975 4.92183 61.9993 4.5384C62.701 4.15497 63.5099 3.96326 64.4285 3.96326C65.2145 3.96326 65.8907 4.10704 66.4597 4.39209C67.0288 4.67714 67.4626 5.06814 67.7662 5.56508C68.0699 6.06203 68.2204 6.64979 68.2204 7.33341C68.2204 7.54026 68.2051 7.74963 68.172 7.96405C68.1388 8.17847 68.1031 8.38028 68.0648 8.56695H61.887L62.1422 7.16187H66.8144L65.6917 7.568C65.7759 7.20223 65.7657 6.897 65.6559 6.64727C65.5462 6.40006 65.3778 6.20834 65.1507 6.07212C64.9236 5.9359 64.6403 5.86779 64.2984 5.86779C63.8621 5.86779 63.5048 5.97879 63.2267 6.19825C62.9485 6.41771 62.7367 6.70781 62.5938 7.06854C62.4509 7.42927 62.382 7.83035 62.382 8.26928C62.382 8.79397 62.53 9.17993 62.8286 9.42714C63.1272 9.67436 63.5686 9.80048 64.1555 9.80048C64.4974 9.80048 64.8292 9.74751 65.1558 9.63904C65.4824 9.53057 65.7682 9.37922 66.0157 9.18245L67.1513 10.8524C66.669 11.2081 66.1433 11.4603 65.5743 11.6117C65.0052 11.763 64.4336 11.8362 63.857 11.8362L63.8595 11.8387Z"
          fill="#155239"
        />
        <path
          d="M74.9204 3.96326C75.5634 3.96326 76.1146 4.09947 76.5739 4.36939C77.0333 4.64182 77.3599 5.03535 77.5538 5.555C77.7477 6.07465 77.7656 6.72295 77.6048 7.4999L76.7526 11.7126H74.0528L74.849 7.76729C74.9536 7.27034 74.9255 6.89196 74.7647 6.62961C74.604 6.36726 74.2952 6.23609 73.841 6.23609C73.3868 6.23609 72.9913 6.37735 72.6825 6.65736C72.3738 6.93737 72.1594 7.36872 72.0369 7.94892L71.2688 11.7126H68.5691L70.1027 4.08939H72.6442L72.2181 6.23861L71.8915 5.62058C72.2513 5.05805 72.7029 4.64183 73.249 4.37191C73.7925 4.09947 74.3488 3.96577 74.9179 3.96577L74.9204 3.96326Z"
          fill="#155239"
        />
        <path
          d="M78.7136 6.37698L79.1245 4.38415H84.6924L84.2943 6.37698H78.7136ZM81.8676 11.8383C81.2603 11.8383 80.7474 11.7223 80.3263 11.4953C79.9053 11.2657 79.6042 10.9277 79.4256 10.4837C79.2444 10.0397 79.2163 9.50749 79.3414 8.88946L80.6479 2.38879H83.3476L82.0411 8.87433C81.9926 9.16442 82.0258 9.3864 82.1406 9.54028C82.2555 9.69416 82.4494 9.77236 82.7224 9.77236C82.8653 9.77236 82.998 9.7547 83.1282 9.71687C83.2583 9.67903 83.3859 9.62353 83.5186 9.54785L83.8886 11.3742C83.5951 11.5432 83.2736 11.6618 82.9215 11.7324C82.5719 11.803 82.2197 11.8383 81.8701 11.8383H81.8676Z"
          fill="#155239"
        />
        <path
          d="M88.0724 11.839C87.5238 11.839 87.0007 11.7028 86.5031 11.4329C86.0055 11.1605 85.6023 10.7695 85.2961 10.2523C84.9873 9.73775 84.8342 9.10963 84.8342 8.37051C84.8342 7.73483 84.944 7.14959 85.1609 6.6148C85.3778 6.08002 85.6789 5.61587 86.0616 5.2173C86.4444 4.81874 86.8909 4.51098 87.3962 4.29152C87.9014 4.07205 88.4399 3.96106 89.0089 3.96106C89.6341 3.96106 90.1699 4.08215 90.6139 4.32684C91.058 4.57152 91.3871 4.95748 91.6015 5.49226C91.8158 6.02705 91.8643 6.73336 91.7495 7.61122C91.6653 8.4815 91.4637 9.23323 91.1447 9.86387C90.8257 10.4945 90.4098 10.9814 89.8944 11.3244C89.3789 11.665 88.769 11.8365 88.0698 11.8365L88.0724 11.839ZM89.0242 9.68981C89.384 9.68981 89.7004 9.60152 89.976 9.42242C90.2516 9.24332 90.4659 8.99611 90.6216 8.67827C90.7773 8.36042 90.8564 7.98456 90.8564 7.55572C90.8564 7.12688 90.7288 6.77625 90.4736 6.50886C90.2184 6.24146 89.8637 6.10777 89.4095 6.10777C89.0497 6.10777 88.7308 6.19606 88.4501 6.37516C88.1719 6.55426 87.9525 6.80399 87.7968 7.12688C87.6412 7.44977 87.5621 7.82059 87.5621 8.24186C87.5621 8.66313 87.6896 9.03394 87.9448 9.29377C88.2 9.55359 88.5598 9.6873 89.0242 9.6873V9.68981ZM90.175 11.7104L90.4302 10.4314L90.9406 7.90384L91.2953 5.40397L91.5504 4.08466H94.2502L92.7166 11.7079H90.175V11.7104Z"
          fill="#155239"
        />
        <path
          d="M94.45 11.7122L96.5526 1.29395H99.2524L97.1497 11.7122H94.45Z"
          fill="#155239"
        />
        <path
          d="M101.897 11.839C101.226 11.839 100.585 11.7659 99.9805 11.6221C99.3732 11.4758 98.9062 11.3017 98.5745 11.0949L99.5543 9.22819C99.9141 9.46279 100.335 9.63936 100.817 9.76297C101.3 9.88658 101.769 9.9446 102.223 9.9446C102.678 9.9446 102.986 9.89162 103.175 9.78315C103.364 9.67468 103.459 9.54098 103.459 9.38206C103.459 9.23323 103.362 9.12224 103.168 9.0516C102.974 8.98097 102.724 8.92296 102.423 8.87503C102.119 8.8271 101.797 8.76908 101.455 8.69845C101.113 8.62781 100.792 8.51934 100.488 8.37556C100.185 8.23177 99.9396 8.02996 99.7508 7.77266C99.562 7.51536 99.4676 7.17229 99.4676 6.74093C99.4676 6.13299 99.6462 5.62344 100.009 5.20973C100.368 4.79856 100.858 4.48575 101.478 4.27638C102.098 4.06701 102.798 3.96106 103.573 3.96106C104.122 3.96106 104.653 4.01403 105.163 4.1225C105.673 4.23097 106.12 4.38233 106.498 4.57909L105.546 6.44579C105.168 6.22128 104.775 6.07245 104.375 5.99678C103.971 5.9211 103.601 5.88326 103.259 5.88326C102.813 5.88326 102.497 5.93623 102.308 6.0447C102.119 6.15318 102.024 6.28183 102.024 6.43066C102.024 6.57949 102.121 6.693 102.315 6.76868C102.509 6.84436 102.757 6.90994 103.053 6.96544C103.349 7.02094 103.673 7.084 104.02 7.15463C104.364 7.22527 104.686 7.33121 104.979 7.46996C105.273 7.61122 105.518 7.80798 105.712 8.06024C105.906 8.31249 106.003 8.65556 106.003 9.0844C106.003 9.69234 105.821 10.2019 105.457 10.6156C105.092 11.0268 104.602 11.3345 103.987 11.5363C103.372 11.7381 102.675 11.839 101.899 11.839H101.897Z"
          fill="#155239"
        />
      </svg>
    </div>
  );
};

export default NavbarLogoView;
