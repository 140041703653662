import React, { FC } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import { AuthWrapperViewProps } from './AuthWrapper.props';
import loginSignupImg from 'assets/images/login-signup.png';

const AuthWrapperView: FC<AuthWrapperViewProps> = (props) => {
  const isMobile = useIsMobile();
  return (
    <div className=" items-center px-14">
      <div className="py-20 flex flex-row justify-center items-center gap-x-0.5">
        {!isMobile ? (
          <figure className="max-w-xl">
            <img
              className="h-auto max-w-[95%] rounded-[1.3rem]"
              src={loginSignupImg}
            />
          </figure>
        ) : null}
        <div className="justify-center items-center w-96">{props.children}</div>
      </div>
    </div>
  );
};

export default AuthWrapperView;
