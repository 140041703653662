import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchUserProfile,
  patchUserProfile,
  uploadProfileImage,
} from 'api/services/profile';
import { UserProfile } from 'types/userProfile';
import { queryClient } from 'utils';

const FETCH_USER_PROFILE_KEY = 'fetch-user-profile-key';
const UPDATE_USER_PROFILE_KEY = 'fetch-user-profile-key';
const FETCH_USERS_PROFILES_KEY = 'fetch-users-profiles-key';

type Props = {
  userId?: string | null;
  onUserProfileUpdateSuccess?: () => void;
};

const useUserProfile = ({ userId, onUserProfileUpdateSuccess }: Props) => {
  const { data: profileQuery, isLoading } = useQuery({
    queryKey: [FETCH_USER_PROFILE_KEY, userId],
    queryFn: () => {
      if (userId) return fetchUserProfile(userId);
    },
    retry: 3,
    enabled: !!userId,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const { mutate: updateProfile, isPending: isUpdatingProfile } = useMutation({
    mutationKey: [UPDATE_USER_PROFILE_KEY],
    mutationFn: async (payload: Partial<UserProfile>) => {
      let response;
      if (payload.avatar && typeof payload.avatar != 'string') {
        const imageUploadResponse = await uploadProfileImage(
          payload.avatar,
          payload._id,
        );
        payload.avatar = imageUploadResponse;
        response = await patchUserProfile(payload);
        return response;
      }
      response = await patchUserProfile(payload);
      return response;
    },
    onSuccess: () => {
      if (onUserProfileUpdateSuccess) {
        onUserProfileUpdateSuccess();
      }

      queryClient.invalidateQueries({ queryKey: [FETCH_USER_PROFILE_KEY] });
    },
  });

  return {
    profileQuery,
    isLoading,
    isUpdatingProfile,
    updateProfile,
  };
};

export default useUserProfile;

export { FETCH_USER_PROFILE_KEY, FETCH_USERS_PROFILES_KEY };
