import { useQuery } from '@tanstack/react-query';
import { getTopRents } from 'api/services/rent';

const FETCH_TOP_RENTS_KEY = 'fetch-top-rents';

const useTopRentsQuery = () => {
  const query = useQuery({
    queryKey: [FETCH_TOP_RENTS_KEY],
    queryFn: getTopRents,
  });

  return query;
};

export default useTopRentsQuery;

export { FETCH_TOP_RENTS_KEY };
