import React, { FC } from 'react';

import { TopItemSectionProps } from './TopItemSection.props';

import TopItemSectionView from './TopItemSection.view';
import useTopRentsQuery from 'api/queries/useTopRents';

const TopItemSectionContainer: FC<TopItemSectionProps> = (props) => {
  const { data, isLoading } = useTopRentsQuery();
  return <TopItemSectionView data={data ?? []} isLoading={isLoading} />;
};

export default TopItemSectionContainer;
