import React, { FC } from 'react';
import { IconProps } from './type';

const CheckIcon: FC<IconProps> = (props) => {
  const { fill, w, h, color, disabled = false } = props;
  return (
    <svg
      width={`${w ? w : '64'}`}
      height={`${h ? h : '64'}`}
      style={{
        filter: disabled ? 'grayscale(1)' : '',
      }}
      viewBox="0 0 96 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="47.9997" cy="52.4987" r="42.6667" fill="white" />
      <circle cx="48" cy="48.5" r="40" fill={color ?? '#22CC7A'} />
      <circle
        cx="48"
        cy="48.5"
        r="46.6667"
        stroke="#682082"
        strokeWidth="2.66667"
      />
      <mask
        id="mask0_1975_17254"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="24"
        width="54"
        height="54">
        <rect
          x="21.334"
          y="24.5"
          width="53.3333"
          height="53.3333"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1975_17254)">
        <path
          d="M42.5548 58.168L61.3882 39.3346C61.8326 38.8902 62.3604 38.668 62.9715 38.668C63.5826 38.668 64.1104 38.8902 64.5548 39.3346C64.9993 39.7791 65.2215 40.3069 65.2215 40.918C65.2215 41.5291 64.9993 42.0569 64.5548 42.5013L44.1104 62.9457C43.6659 63.3902 43.1474 63.6124 42.5548 63.6124C41.9622 63.6124 41.4437 63.3902 40.9993 62.9457L31.4437 53.3902C30.9993 52.9457 30.7863 52.418 30.8048 51.8069C30.8233 51.1957 31.0548 50.668 31.4993 50.2235C31.9437 49.7791 32.4715 49.5569 33.0826 49.5569C33.6937 49.5569 34.2215 49.7791 34.6659 50.2235L42.5548 58.168Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default CheckIcon;
