import { FC, ReactElement, useEffect, useState } from 'react';
import { PaginationProps } from './Pagination.props';
import ArrowRight from 'components/icons/ArrowRight';
import ArrowLeft from 'components/icons/ArrowLeft';
import ReactPaginate from 'react-paginate';

const PaginationView: FC<PaginationProps> = ({
  totalPages,
  onPageChange,
  currentPageNo,
}) => {
  return (
    <div className="w-full">
      <ReactPaginate
        containerClassName={'pagination'}
        pageClassName={'pagination-item'}
        activeClassName={'pagination-item-active'}
        onPageChange={(page) => {
          onPageChange(page.selected + 1);
        }}
        initialPage={currentPageNo - 1}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        breakLabel={<PaginationItem value="..." />}
        nextLabel={<PaginationItem child={<ArrowRight />} />}
        previousLabel={<PaginationItem child={<ArrowLeft />} />}
      />
    </div>
  );
};

type PaginationItemProps = {
  value?: string;
  child?: ReactElement;
  onTap?: () => void;
  highlight?: boolean;
  hide?: boolean;
};

const PaginationItem: FC<PaginationItemProps> = ({
  value: title,
  child,
  onTap,
  highlight = false,
  hide = false,
}) => {
  if (hide) return null;
  if (child) {
    return <div onClick={onTap}>{child}</div>;
  }
  return (
    <div
      onClick={onTap}
      color="white"
      className={`${highlight ? 'bg-[#22CC7A]' : 'bg-[#D2F5E1]'} w-9 h-9 ${highlight ? 'text-[#fff]' : 'text-[#075015]'} rounded-md flex justify-center items-center cursor-pointer select-none font-bold`}>
      {title}
    </div>
  );
};

export default PaginationView;
