import React from 'react';
import { IconProps } from './type';

const MedalIcon = (props: IconProps) => {
  const { w, h } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${w ? w : '37'}`}
      height={`${h ? h : '37'}`}
      fill="none"
      viewBox="0 0 37 37">
      <g>
        <g>
          <path
            fill="#FF5959"
            d="M28.34 3.5H8.49a.945.945 0 00-.945.945v1.89c0 .523.423.946.945.946h19.85a.945.945 0 00.944-.945v-1.89a.945.945 0 00-.945-.946z"></path>
          <path
            fill="#FF5959"
            d="M18.417 20.512l1.418 4.726h4.726l-3.308 2.835L22.67 32.8l-4.254-2.835-4.253 2.835 1.418-4.726-3.309-2.835H17l1.418-4.726z"></path>
          <path
            fill="#FF5959"
            d="M18.415 28.543l-3.722 2.481-.532 1.772 4.254-2.835 4.253 2.835-.532-1.772-3.721-2.481z"></path>
          <path
            fill="#22CC7A"
            d="M27.4 13.417l-8.98 5.199-8.98-5.2V5.384H27.4v8.034z"></path>
          <path
            fill="#22CC7A"
            d="M10.858 9.674V5.383H9.44v8.034l8.98 5.199 1.117-.647-5.849-3.387a5.672 5.672 0 01-2.83-4.908z"></path>
          <path
            fill="#fff"
            d="M18.421 18.616l4.726-2.736V5.383h-9.452V15.88l4.726 2.736z"></path>
          <path
            fill="#fff"
            d="M13.695 14.59v1.293l4.726 2.736 1.117-.646-5.843-3.383z"></path>
          <path
            fill="#682082"
            d="M28.34 3.023H8.491a1.418 1.418 0 00-1.418 1.418v1.89A1.418 1.418 0 008.491 7.75h.473v5.672a.473.473 0 00.236.408l8.2 4.748a1.41 1.41 0 00.421 2.269l-1.175 3.917h-4.374a.472.472 0 00-.307.831l3.072 2.636-1.33 4.431a.472.472 0 00.715.53l3.994-2.663 3.99 2.661a.473.473 0 00.716-.529l-1.33-4.431 3.072-2.636a.473.473 0 00-.305-.83h-4.374l-1.175-3.916a1.41 1.41 0 00.42-2.269l8.201-4.749a.473.473 0 00.236-.409V7.75h.473a1.418 1.418 0 001.418-1.418v-1.89a1.418 1.418 0 00-1.418-1.419zm-9.924 17.014a.472.472 0 110-.944.472.472 0 010 .944zm4.253-4.426l-4.253 2.462-4.254-2.462V5.859h8.507v9.752zm-9.452-.548l-3.308-1.915V5.859h3.308v9.204zm6.616 10.645h3.45l-2.34 2.004a.473.473 0 00-.145.495l1.04 3.469-3.161-2.108a.473.473 0 00-.525 0l-3.16 2.108 1.039-3.47a.472.472 0 00-.146-.494l-2.337-2.004h3.45a.472.472 0 00.453-.337l.965-3.217.965 3.217a.473.473 0 00.452.337zm7.09-12.56l-3.309 1.915V5.86h3.308v7.289zm1.89-6.816a.472.472 0 01-.473.472h-.473V5.386a.472.472 0 00-.472-.472H9.436a.473.473 0 00-.472.472v1.418H8.49a.473.473 0 01-.473-.472v-1.89a.473.473 0 01.473-.473H28.34a.472.472 0 01.473.472v1.89z"></path>
        </g>
      </g>
    </svg>
  );
};

export default MedalIcon;
