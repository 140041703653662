import React, { FC, forwardRef, useEffect, useRef } from 'react';

import { ComunicationMethodModalProps } from './ComunicationMethodModal.props';
import { CommonModalRef } from '../CommonModal/CommonModal.props';
import CommonModal, { ModalRef } from '../CommonModal';

import question from 'assets/images/question.png';
import Button from 'components/primitives/Button';

const ComunicationMethodModalView = forwardRef<
  CommonModalRef,
  ComunicationMethodModalProps
>((props, ref) => {
  const { onContinue } = props;
  const comunicationMethodModalRef = useRef<ModalRef>(null);

  const isModalOpen = localStorage.getItem('showModal') === 'true';

  return (
    <CommonModal
      ref={comunicationMethodModalRef}
      isCloseButton={false}
      isDefaultOpen={isModalOpen}
      className="w-[37.5rem] h-[28.125rem] flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-4">
        <img src={question} />
        <div className="text-center text-gray-700 text-base font-semibold font-primary capitalize">
          Which communication method will we use?
        </div>
        <div className="w-[24rem] text-center">
          <span className="text-neutral-700 text-sm font-medium font-primary">
            We prioritize open communication for our users. Feel free to connect
            with the owner via the{' '}
          </span>
          <span className="text-neutral-700 text-sm font-bold font-primary">
            Chat
          </span>
          <span className="text-neutral-700 text-sm font-medium font-primary">
            {' '}
            button.
          </span>
        </div>
        <Button variant={'solid'} colorScheme={'primary'} onClick={onContinue}>
          Continue
        </Button>
      </div>
    </CommonModal>
  );
});

export default ComunicationMethodModalView;
