import React, { FC } from 'react';

import { TableProps } from './Table.props';
import Loading from '../Loading';

const TableView: FC<TableProps> = ({
  headers,
  isLoading,
  itemCount,
  builder,
  onClickItem,
  itemNotFoundComponent,
}) => {
  const indices = Array.from({ length: itemCount }, (v, i) => i);
  return (
    <table className="table table-zebra text-sm font-bold font-primary capitalize">
      <thead className="border-b bg-secondary-900 font-medium">
        <tr>
          {headers.map((header) => (
            <th key={header} className="text-white px-6 py-4">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td>
              <Loading />
            </td>
          </tr>
        ) : (
          indices.map((index) => {
            return (
              <tr
                onClick={() => onClickItem && onClickItem(index)}
                key={index}
                className="border-b hover select-none text-neutral-700 bg-secondary-100 cursor-pointer transition-all">
                {builder(index)}
              </tr>
            );
          })
        )}
      </tbody>
      <tfoot>
        {indices.length === 0 && (
          <tr className="select-none">
            <td colSpan={headers.length}>
              <div className="flex flex-wrap justify-center gap-10 text-secondary-900 font-semibold text-lg">
                {itemNotFoundComponent ?? <span>No items found</span>}
              </div>
            </td>
          </tr>
        )}
      </tfoot>
    </table>
  );
};

export default TableView;
