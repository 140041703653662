import { useRef, useState } from 'react';

import { RentalProductProps } from './RentalProduct.props';

import { useNavigate, useParams } from 'react-router-dom';
import RentalProductView from './RentalProduct.view';

import { useItem, useRentAvailability } from 'api/queries';

import { useMutation } from '@tanstack/react-query';
import { deleteItem } from 'api/services/item';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import { routesSettings } from 'constants/routes';
import useIsItemRented from 'hooks/useIsItemRented';
import $itemRentDetails from 'state/stores/rent-details/store';
import { queryClient } from 'utils';
import DateTimeZ from 'utils/dateTime';

const RentalProductContainer = (props: RentalProductProps) => {
  const navigate = useNavigate();

  let { id } = useParams();
  const successModalRef = useRef<CommonModalRef>(null);

  const onSuccess = () => {
    successModalRef.current?.onOpen?.();
    setTimeout(() => {
      successModalRef.current?.onClose!();
      navigate(routesSettings.LEND.path);
    }, 3000);
  };
  const { mutate: mutateDelete } = useMutation({
    mutationFn: (id: string) => deleteItem(id),
    onSuccess: (data) => {
      queryClient.invalidateQueries();
      onSuccess();
    },
    onError: (error: Error) => {
      console.error('Error deleting item', error);
    },
  });

  const { data, isLoading: isProductLoading } = useItem(id!);

  const [{ currentDate, futureDate }] = useState(() => {
    const currentDate: string = new DateTimeZ().defaultPayloadStringFormat(); // Current date
    let futureDateObject: DateTimeZ = new DateTimeZ().addMonths(6);
    const futureDate: string = futureDateObject.defaultPayloadStringFormat();
    return { currentDate, futureDate };
  });

  const { data: availability, isLoading: availabilityLoading } =
    useRentAvailability(id!, currentDate, futureDate);

  if (data) {
    $itemRentDetails.actions.setProduct(data);
    if (availability) {
      $itemRentDetails.actions.setRentAvailability(availability);
    }
  }

  const {
    isLoading: rentDetailsLoading,
    rentDetails,
    onGoingRents,
  } = useIsItemRented(id!);

  if (rentDetails) {
    $itemRentDetails.actions.setRentDetails(rentDetails);
  }

  if (onGoingRents && onGoingRents.length > 0) {
    $itemRentDetails.actions.setOnGoingRents(onGoingRents);
  }

  const isLoading =
    isProductLoading || availabilityLoading || rentDetailsLoading;

  return (
    <RentalProductView
      product={data!}
      isLoading={isLoading}
      deleteItem={mutateDelete}
    />
  );
};

export default RentalProductContainer;
