import { useItem } from 'api/queries';
import { useParams } from 'react-router-dom';

import { ProductQuestionsProps } from './ProductQuestions.props';

import ProductQuestionsView from './ProductQuestions.view';

const ProductQuestionsContainer = (props: ProductQuestionsProps) => {
  const productId = useParams().id!;

  const { data } = useItem(productId);

  return <ProductQuestionsView product={data} />;
};

export default ProductQuestionsContainer;
