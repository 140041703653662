import { useState } from 'react';

import { ItemHistoryProps } from './ItemHistory.props';

import {
  useItem,
  useOngoingRent,
  useRentAvailability,
  useRentDetailsById,
} from 'api/queries';
import { useParams } from 'react-router-dom';
import { $itemRentDetails } from 'state/stores/rent-details';
import DateTimeZ from 'utils/dateTime';
import ItemHistoryView from './ItemHistory.view';

const ItemHistoryContainer = (props: ItemHistoryProps) => {
  let { id } = useParams();
  const { data: rentDetails, isLoading: isRentDetailsLoading } =
    useRentDetailsById(id!);

  const { data: product, isLoading: isProductLoading } = useItem(
    rentDetails?.itemId!,
  );

  const [{ currentDate, futureDate }] = useState(() => {
    const currentDate: string = new DateTimeZ().defaultPayloadStringFormat(); // Current date
    let futureDateObject: DateTimeZ = new DateTimeZ().addMonths(6);
    const futureDate: string = futureDateObject.defaultPayloadStringFormat();
    return { currentDate, futureDate };
  });

  const { data: availability, isLoading: availabilityLoading } =
    useRentAvailability(id!, currentDate, futureDate);

  const { data: onGoingRents, isLoading: onGoingRentsLoading } = useOngoingRent(
    id!,
    currentDate,
  );

  if (product) {
    $itemRentDetails.actions.setProduct(product);
    if (availability) {
      $itemRentDetails.actions.setRentAvailability(availability);
    }
  }

  if (rentDetails) {
    $itemRentDetails.actions.setRentDetails(rentDetails);
  }

  if (onGoingRents && onGoingRents.length > 0) {
    $itemRentDetails.actions.setOnGoingRents(onGoingRents);
  }

  const isLoading =
    isProductLoading ||
    availabilityLoading ||
    isRentDetailsLoading ||
    onGoingRentsLoading;

  return <ItemHistoryView product={product!} isLoading={isLoading} />;
};

export default ItemHistoryContainer;
