import React, { FC } from 'react';

import { UserLocationFormViewProps } from './UserLocationForm.props';
import { ErrorMessage, Form, Formik } from 'formik';
import TextInput from 'components/primitives/TextInput';
import Button from 'components/primitives/Button';
import { initialData, validationSchema } from './UserLocationForm.utils';
import { $user } from 'state/stores/user';
import SelectInput from 'components/primitives/SelectInput';
import SelectOption from 'components/primitives/SelectOption';

const UserLocationFormView: FC<UserLocationFormViewProps> = ({
  controller,
  formModeOff = false,
  hideLabels,
  cityName,
  stateName,
}) => {
  const {
    onSubmit,
    isLoading = false,
    states,
    cities,
    onChangeState,
    selectedCityId,
    selectedStateId,
  } = controller!;

  const initialData = {
    state: selectedStateId,
    location: selectedCityId,
    address: selectedCityId,
  };

  // const addressObject = isAddressSet
  //   ? { address: user?.userDetails.address }
  //   : initialData;

  const LocationInputElements = () => {
    return (
      <div className="flex w-full items-start gap-4 justify-start lg:flex-row flex-col">
        <div className="w-full">
          <SelectInput
            isRequired
            defaultValue={selectedStateId}
            onChange={onChangeState}
            label={hideLabels ? '' : 'State'}
            key="states"
            name={stateName ?? 'state'}
            placeholder="Select State">
            {states.length > 0 && <option>Select State</option>}
            {states.map((state, index) => {
              return (
                <SelectOption
                  key={state._id + index}
                  value={state._id}
                  child={state.title!}
                />
              );
            })}
          </SelectInput>
        </div>
        <div className="w-full">
          <SelectInput
            isRequired
            label={hideLabels ? '' : 'City'}
            defaultValue={selectedCityId}
            key="cities"
            name={cityName ?? 'address'}
            placeholder="Select City">
            {cities.length > 0 && <option>Select City</option>}
            {cities.map((city, index) => {
              return (
                <SelectOption
                  selected={selectedCityId === city._id}
                  key={city._id + index}
                  value={city._id}
                  child={city.title!}
                />
              );
            })}
          </SelectInput>
        </div>
        {!formModeOff && (
          <Button
            className="rounded-lg"
            id="open-btn"
            type="submit"
            isLoading={isLoading}
            variant="solid"
            colorScheme="primary">
            Save
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className="w-full">
      {!formModeOff ? (
        <Formik
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          <Form>
            <LocationInputElements />
          </Form>
        </Formik>
      ) : (
        <LocationInputElements />
      )}
    </div>
  );
};

export default UserLocationFormView;
