import { LendProps } from './Lend.props';

import useConnectToStripeMutation from 'api/mutations/useConnectToStripeMutation';
import { $stripe } from 'state/stores/stripe';
import { $user } from 'state/stores/user';
import LendView from './Lend.view';

const LendContainer = (props: LendProps) => {
  const stripeId = $user.selectors.useStripeId();
  const isFetchingStripeStatus = $stripe.selectors.useIsFetchingData();

  const { mutate: connectStripeAccount, isPending } =
    useConnectToStripeMutation(stripeId!);

  return (
    <LendView
      stripe={{
        onClickConnectToStripe: connectStripeAccount,
        isMutationLoading: isPending,
        isFetchingStripeStatus: isFetchingStripeStatus ?? false,
      }}
    />
  );
};

export default LendContainer;
