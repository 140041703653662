import React from 'react';

function CallIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20">
      <mask
        id="mask0_2610_101114"
        style={{ maskType: 'alpha' }}
        width="21"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse">
        <path fill="#515151" d="M0.438 0H20.438V20H0.438z"></path>
      </mask>
      <g mask="url(#mask0_2610_101114)">
        <path
          fill="#515151"
          d="M17.063 17.5c-1.737 0-3.452-.378-5.146-1.135-1.695-.757-3.236-1.83-4.625-3.22-1.39-1.388-2.462-2.93-3.22-4.624-.756-1.695-1.135-3.41-1.135-5.146 0-.25.084-.458.25-.625a.848.848 0 01.626-.25h3.374c.195 0 .369.066.521.198a.761.761 0 01.271.469l.542 2.916c.028.223.02.41-.021.563a.876.876 0 01-.23.396L6.25 9.083c.278.514.608 1.01.99 1.49s.802.94 1.26 1.385c.43.43.882.83 1.354 1.198.472.368.972.705 1.5 1.01l1.959-1.958c.124-.125.288-.218.49-.28.2-.063.398-.08.593-.053l2.875.583a.965.965 0 01.479.302.733.733 0 01.188.49v3.375c0 .25-.084.458-.25.625a.848.848 0 01-.625.25zM5.457 7.5l1.375-1.375-.354-1.958H4.625c.07.57.167 1.132.292 1.687.125.556.305 1.104.541 1.646zm7.459 7.458a10.345 10.345 0 003.354.834v-1.834l-1.959-.396-1.395 1.396z"></path>
      </g>
    </svg>
  );
}

export default CallIcon;
