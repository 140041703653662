import { FC } from 'react';

import TotalPurchase from 'components/modules/TotalPurchase';
import CommonContainer from 'components/primitives/CommonContainer';
import { PaymentSuccessfullViewProps } from './PaymentSuccessfull.props';

const PaymentSuccessfullView: FC<PaymentSuccessfullViewProps> = ({
  product,
}) => {
  return (
    <div className="flex flex-col gap-8 justify-center items-center md:py-14">
      <div className="text-gray-700 text-3xl font-bold font-primary">
        Summary
      </div>
      <div className="w-2/3">
        <CommonContainer w={'w-32'}>
          <TotalPurchase product={product!} />
        </CommonContainer>
      </div>
      {/* <div className="w-2/3">
        <CommonContainer w={'w-32'}>
          <AddressForm />
        </CommonContainer>
      </div> */}
    </div>
  );
};

export default PaymentSuccessfullView;
