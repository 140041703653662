/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

export default function getStripe(stripeId: string): Promise<Stripe | null> {
  if (stripePromise) {
    return stripePromise;
  }

  const options = {
    stripeAccount: stripeId,
  };

  stripePromise = loadStripe(
    process.env.REACT_APP_PUBLIC_STRIPE_KEY as string,
    options,
  );

  return stripePromise;
}
