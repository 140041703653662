import React, { FC } from 'react';

import { PriceTagProps } from './PriceTag.props';
import pricetag from 'assets/images/priceTag.png';

const PriceTagView: React.FC<PriceTagProps> = ({ price }) => {
  return (
    <div className="h-20 -mr-[3.87rem] absolute right-3 top-0">
      <div className="relative">
        <img src={pricetag} alt="" />
        <span className="flex absolute top-1  w-40  h-full justify-center items-center text-neutral-700 text-2xl font-semibold font-fourth">
          ${price}/day
        </span>
      </div>
    </div>
  );
};

export default PriceTagView;
