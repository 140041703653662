import React from 'react';

function ShieldIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64">
      <g>
        <path
          fill="#22CC7A"
          d="M62 49a13 13 0 01-25.68 2.88c-.1-.46-.18-.94-.24-1.42A13.991 13.991 0 0136 49a13 13 0 0110.41-12.75c.853-.167 1.72-.25 2.59-.25.18-.01.36-.01.54 0A13 13 0 0162 49z"></path>
        <path
          fill="#fff"
          d="M47.75 55l-1.41-1.41-5.5-5.5 2.82-2.83 4.09 4.08 6.59-6.58 2.82 2.83-8 8L47.75 55z"></path>
        <path
          fill="#EEE"
          d="M50 16.54v14.39a28.763 28.763 0 01-.46 5.07c-.18-.01-.36-.01-.54 0-.86.002-1.717.085-2.56.25-.476.097-.947.22-1.41.37a13 13 0 00-8.95 13.84c.06.48.14 1 .24 1.42a13.004 13.004 0 001.06 2.95c-.47.33-.94.65-1.43 1L26 62l-9.95-6.22A29.37 29.37 0 012 30.93V16.54a8 8 0 005.19-7.49L26 2l18.81 7.05A8 8 0 0050 16.54z"></path>
        <path
          fill="#fff"
          d="M46 18.93v12a25.68 25.68 0 01-.34 4.07c-.2.54-.4 1.08-.63 1.62a13 13 0 00-8.95 13.84c-.4.43-.82.86-1.25 1.27-.32.22-.64.44-1 .64L26 57.28l-7.83-4.89A25.31 25.31 0 016 30.93v-12a12.12 12.12 0 004.83-7L26 6.27 41.17 12A12.12 12.12 0 0046 18.93z"></path>
        <path
          fill="#BDBDBD"
          d="M28.17 34h-8.83a4 4 0 01-3.94-3.28l-1-5.36a2 2 0 012-2.36H31l-.83 9.18a2 2 0 01-2 1.82z"></path>
        <path fill="#EEE" d="M16.5 39a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
        <path fill="#EEE" d="M29.5 39a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
        <path
          fill="#692082"
          d="M50.41 35.34c.222-1.46.336-2.934.34-4.41V16.54a.74.74 0 00-.49-.7 7.29 7.29 0 01-4.7-6.79.74.74 0 00-.49-.7l-18.81-7a.67.67 0 00-.52 0l-18.81 7a.74.74 0 00-.49.7 7.29 7.29 0 01-4.7 6.79.74.74 0 00-.49.7v14.39a30 30 0 0014.4 25.49l10 6.22a.78.78 0 00.8 0l9.94-6.22c.27-.16.52-.34.77-.51a13.75 13.75 0 1013.3-20.57h-.05zm-14.86 19.8l-9.55 6-9.55-6a28.53 28.53 0 01-13.7-24.21V17a8.76 8.76 0 005.17-7.42L26 2.8l18.08 6.78A8.76 8.76 0 0049.25 17v13.93a27.49 27.49 0 01-.34 4.31c-.881-.004-1.76.09-2.62.28 0-.09.07-.17.1-.26a.744.744 0 000-.14c.226-1.386.343-2.787.35-4.19v-12a.75.75 0 00-.32-.62 11.24 11.24 0 01-4.53-6.53.75.75 0 00-.47-.52L26.26 5.57a.67.67 0 00-.52 0l-15.17 5.69a.75.75 0 00-.47.52 11.24 11.24 0 01-4.53 6.53.75.75 0 00-.32.62v12A26.05 26.05 0 0017.77 53l7.83 4.89a.78.78 0 00.8 0L34.25 53c.34-.21.67-.43 1-.66a.39.39 0 00.1-.08c.08-.08.15-.16.24-.24.105.508.242 1.008.41 1.5 0 .15.11.29.17.44.06.15.15.42.24.62-.27.19-.56.42-.86.56zm8.06-18.79l-.53.24-.46.23-.5.28-.43.25-.48.32-.41.33-.45.35-.38.31-.42.39-.35.33c-.14.14-.26.29-.39.43s-.22.23-.32.35c-.1.12-.24.31-.36.46l-.29.37-.33.49-.25.39c-.11.17-.2.35-.29.52-.09.17-.16.27-.23.41-.07.14-.17.36-.25.54l-.19.42c-.08.19-.15.38-.22.57l-.15.43c-.07.2-.12.4-.17.6-.05.2-.09.29-.12.43s-.09.43-.13.64l-.09.42c0 .24-.06.48-.08.73-.02.25 0 .23-.05.35v2.3c-.3.33-.62.65-.94 1-.32.35-.59.4-.9.58L26 56.4l-7.43-4.65A24.54 24.54 0 016.75 30.93V19.31a12.75 12.75 0 004.7-6.78L26 7.07l14.55 5.46a12.75 12.75 0 004.7 6.78v11.62a25.24 25.24 0 01-.32 3.88c-.15.4-.3.8-.46 1.21l-.37.14-.49.19zM49 61.25a12.29 12.29 0 01-12-9.53c-.09-.44-.17-.9-.22-1.34a11.17 11.17 0 01-.03-1.38 12.24 12.24 0 017.58-11.33c.3-.12.61-.23.93-.34a13.316 13.316 0 013.74-.58c.14-.01.28-.01.42 0h.06a12.253 12.253 0 01-.48 24.5z"></path>
        <path
          fill="#692082"
          d="M57.69 45.061l-2.82-2.83a.74.74 0 00-1.06 0l-6.06 6.05-3.56-3.55a.74.74 0 00-1.06 0l-2.82 2.83a.74.74 0 000 1.06l6.91 6.91a.75.75 0 001.06 0l9.41-9.41a.74.74 0 000-1.06zm-9.94 8.88l-5.85-5.85 1.76-1.77 3.56 3.55a.742.742 0 001.06 0l6.06-6 1.76 1.77-8.35 8.3z"></path>
        <path
          fill="#692082"
          d="M38.75 19a.76.76 0 00-.75-.75h-4.44a2.76 2.76 0 00-2.67 2.08l-.48 1.92h-14a2.76 2.76 0 00-2.71 3.24l1 5.36a4.72 4.72 0 001.3 2.46 3.26 3.26 0 103.27 1.43h7.55a3.18 3.18 0 00-.52 1.75 3.25 3.25 0 104.26-3.07 2.67 2.67 0 00.4-1.18l.83-9.13.61-2.42a1.25 1.25 0 011.21-.95H38a.76.76 0 00.75-.74zM16.5 38.25a1.75 1.75 0 111.75-1.75 1.76 1.76 0 01-1.75 1.75zm-.36-7.67l-1-5.36a1.25 1.25 0 011.23-1.47h13.81l-.76 8.36a1.25 1.25 0 01-1.25 1.14h-8.83a3.25 3.25 0 01-3.2-2.67zm15.11 5.92a1.75 1.75 0 11-1.75-1.75 1.76 1.76 0 011.75 1.75z"></path>
        <path
          fill="#692082"
          d="M41 25.25h-5a.75.75 0 100 1.5h5a.75.75 0 100-1.5z"></path>
        <path
          fill="#692082"
          d="M39 29.25h-4a.75.75 0 100 1.5h4a.75.75 0 100-1.5z"></path>
      </g>
    </svg>
  );
}

export default ShieldIcon;
