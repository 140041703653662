import { useMutation } from '@tanstack/react-query';
import { rateOwner } from 'api/services/reviews';

const useRateOwnerMutation = (onSuccess?: any) =>
  useMutation({
    mutationFn: rateOwner,
    onSuccess: onSuccess,
  });

export default useRateOwnerMutation;
