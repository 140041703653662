import { useCallback, useEffect, useState } from 'react';
import { EditItemProps } from './EditItem.props';

import EditItemView from './EditItem.view';

import { useMutation } from '@tanstack/react-query';
import {
  QUERY_KEYS,
  refetchQuery,
  useCategories,
  useItem,
  useSubCategory,
} from 'api/queries';
import { updateItem } from 'api/services/item';
import SuccessModal from 'components/modals/SuccessModal';
import { ImageUploadHelper } from 'components/modules/ImagePicker/helpers/imageUploadHelper';
import { routesSettings } from 'constants/routes';
import ModalReference from 'helperClasses/modalReference';
import { useNavigate, useParams } from 'react-router';
import $session from 'state/stores/session/store';
import $user from 'state/stores/user/store';
import { ItemPayload } from 'types/item';
import { initialValues } from '../CreateItem/utils';

const EditItemContainer = (props: EditItemProps) => {
  const { data: categories, isLoading: isGetCategoriesLoading } =
    useCategories();

  const ownerName = $user.selectors.getFullName();

  const { id } = useParams();

  const itemQuery = useItem(id!);

  const navigate = useNavigate();

  const successModalRef = new ModalReference();

  const [selectedCategoryId, setSelectedCategoryID] = useState<string | ''>('');

  const { data: subcategories } = useSubCategory(selectedCategoryId);

  const onChangeCategory = useCallback((categoryId: string) => {
    setSelectedCategoryID(categoryId);
  }, []);

  //This will trigger fetching subcategories, once item data is fetched

  useEffect(() => {
    if (itemQuery.data) {
      setSelectedCategoryID(itemQuery.data.categories[0]._id);
    }
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async (payload: typeof initialValues) => {
      const ownerId = $session.actions.getUserId();

      const imageUploadHelper = new ImageUploadHelper(
        payload.photos,
        payload.featuredPhoto,
      );

      const imageUploadResponse = await imageUploadHelper.upload();

      const finalPayload: ItemPayload = {
        title: payload.title,
        owner: ownerName,
        ownerId: ownerId!,
        location: payload.location,
        modelNumber: payload.modelNumber,
        serialNumber: payload.serialNumber,
        guidelines: payload.guidelines,
        deliveryOptions: payload.deliveryOptions,
        price: parseInt(payload.rentalPrice),
        link: payload.ownersManual,
        description: payload.description,
        categories: [payload.category],
        includedInRental: payload.includedInRental,
        photos: imageUploadResponse?.photos,
        featuredPhoto: imageUploadResponse?.featuredPhoto,
        subCategories: [payload.subCategory],
      };

      const response = await updateItem(id!, finalPayload);

      QUERY_KEYS.invalidate(QUERY_KEYS.FETCH_ITEM_KEY);

      return response;
    },
    onSuccess: () => {
      successModalRef.open();
      setTimeout(async () => {
        successModalRef.close();
        await refetchQuery(QUERY_KEYS.FETCH_ITEM_KEY);
        navigate(routesSettings.MAIN_LEND_PRODUCT.getPath(id!));
      }, 3000);
    },
  });
  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div>
      <EditItemView
        itemQuery={itemQuery}
        subCategories={subcategories?.item ?? []}
        onChangeCategory={onChangeCategory}
        categories={categories?.items ?? []}
        onCancel={onCancel}
        isCategoryLoading={isGetCategoriesLoading}
        creationInProgress={isPending}
        onSubmit={mutate}
      />
      <SuccessModal
        ref={successModalRef.getReference()}
        title="Item successfully updated!"
      />
    </div>
  );
};

export default EditItemContainer;
