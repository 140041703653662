import LeftArrowIcon from 'components/icons/LeftArrow';
import RentalsIcon from 'components/icons/RentalsIcon';

import greenLogo from '../../../assets/images/green.png';

export const MobileHeader = () => {
  return (
    <div className="join w-full flex justify-start bg-slate-50">
      <button className="join-item w-full btn flex justify-start bg-slate-50">
        <LeftArrowIcon fill={'#22CC7A'} />
        <img src={greenLogo} />
        <RentalsIcon />
      </button>
    </div>
  );
};
