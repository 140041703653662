import { format } from 'date-fns/esm';
import { Review } from 'types/reviews';
import { number } from 'yup';

export class ReviewHelper {
  data: Review;

  constructor(data: Review) {
    this.data = data;
  }

  static rateNumbers = [5, 4, 3, 2, 1];

  formatedCreationDate() {
    const createdAtDate = new Date(this.data.createdAt);
    return format(createdAtDate, 'MMMM dd, yyyy');
  }

  static findAverageRate(data?: Review[]): number {
    if (!data) return 0;
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total += data[i].rate;
    }
    if (data.length === 0) return 0.0;
    return parseFloat((total / data.length).toFixed(1));
  }

  static getRatingPercentage(data?: Review[]): Map<number, number> {
    const map = new Map<number, number>();
    const totalReviews = data?.length ?? 0;
    if (totalReviews === 0) return new Map();
    for (const review of data!) {
      const { rate } = review;
      map.set(rate, (map.get(rate) || 0) + 1);
    }
    for (const rate of this.rateNumbers) {
      map.set(rate, ((map.get(rate) || 0) / totalReviews) * 100);
    }
    return map;
  }
}
