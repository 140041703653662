import { IconProps } from './type';

const ChevronLeft = (props: IconProps) => {
  return (
    <svg
      width={props.w ?? '6'}
      height={props.h ?? '9'}
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03272 4.5882L4.87276 7.42825C5.13112 7.68661 5.13112 8.10549 4.87276 8.36385C4.6144 8.62221 4.19552 8.62221 3.93716 8.36385L0.629316 5.056C0.370957 4.79764 0.370957 4.37876 0.629316 4.1204L3.93716 0.812553C4.19552 0.554194 4.6144 0.554194 4.87276 0.812553C5.13112 1.07091 5.13112 1.48979 4.87276 1.74815L2.03272 4.5882Z"
        fill="#92929D"
      />
    </svg>
  );
};

export default ChevronLeft;
