import React, { FC } from 'react';

import { CheckBoxProps } from './CheckBox.props';
import RequiredFieldMarker from '../RequiredFieldMarker';
import Label from '../Label';
import { ErrorMessage } from 'formik';

const CheckBoxView: FC<CheckBoxProps> = (props) => {
  const { name, labelProps, label, isRequired, children, leftIcon } = props;
  return (
    <label htmlFor={name} className={`${labelProps} flex flex-col mb-8`}>
      <Label name={name!} isRequired title={label!} />
      <div className="flex flex-row justify-between gap-5 mt-2">{children}</div>
      <div className={`h-6 mt-2 `}>
        <ErrorMessage
          component="div"
          className="text-red-500 text-sm"
          name={name!}
        />
      </div>
    </label>
  );
};

export default CheckBoxView;
