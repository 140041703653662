import React from 'react';
type HomeIconProps = {
  fill?: string | undefined;
};

const HomeIcon = (props: HomeIconProps) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <mask
        id="mask0_606_40649"
        style={{ maskType: 'alpha' }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_606_40649)">
        <path
          fill={fill ? fill : '#A6A6A6'}
          d="M4 19v-9a1.986 1.986 0 01.8-1.6l6-4.5c.35-.267.75-.4 1.2-.4.45 0 .85.133 1.2.4l6 4.5A1.985 1.985 0 0120 10v9c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0118 21h-3a.968.968 0 01-.713-.288A.968.968 0 0114 20v-5a.968.968 0 00-.287-.713A.968.968 0 0013 14h-2a.968.968 0 00-.713.287A.968.968 0 0010 15v5c0 .283-.096.52-.287.712A.967.967 0 019 21H6c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 014 19z"></path>
      </g>
    </svg>
  );
};

export default HomeIcon;
