import React, { FC } from 'react';

import { MobileMenuProps } from './MobileMenu.props';
import HomeIcon from 'components/icons/HomeIcon';
import RentIcon from 'components/icons/RentIcon';

const MobileMenuView: FC<MobileMenuProps> = (props) => {
  return (
    <ul className="menu w-full flex flex-row bg-primary-50 rounded-box items-center justify-center gap-4">
      <li>
        <a href="/home" className="flex flex-col">
          <HomeIcon />
          <p>Home</p>
        </a>
      </li>
      <li>
        <a className="flex flex-col">
          <RentIcon />
          <p>Rent</p>
        </a>
      </li>
      <li>
        <a className="flex flex-col">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            />
          </svg>
          <p>Lend</p>
        </a>
      </li>
      <li>
        <a className="flex flex-col">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            />
          </svg>
          <p>Profile</p>
        </a>
      </li>
    </ul>
  );
};

export default MobileMenuView;
