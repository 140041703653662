import { useQuery } from '@tanstack/react-query';

import { fetchItems } from 'api/services/item';

const FETCH_ITEMS_KEY = 'fetch-items';

export interface FetchItemsParams {
  excludeOwnerItems?: boolean;
  limit?: number;
  page?: number;
}

const useItemsQuery = (params: FetchItemsParams = {}) => {
  const query = useQuery({
    queryKey: [FETCH_ITEMS_KEY, params.page],
    queryFn: () => fetchItems(params),
  });

  return query;
};

export default useItemsQuery;

export { FETCH_ITEMS_KEY };
