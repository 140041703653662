import React, { FC } from 'react';

import { CommonContainerViewProps } from './CommonContainer.props';

const CommonContainerView: FC<CommonContainerViewProps> = ({
  w,
  h,
  removeShadow = false,
  children,
  padding,
  className,
  style,
}) => {
  return (
    <div
      style={style}
      className={`${className} flex bg-white ${w ? `max-${w}` : 'w-full'} ${
        h ? h : ' h-auto'
      } items-center md:phone-6 ${removeShadow ? '' : 'rounded-lg border border-gradient shadow-xl'} ${padding ?? 'p-8'}`}>
      {children}
    </div>
  );
};

export default CommonContainerView;
