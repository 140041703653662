import Button from 'components/primitives/Button';
import TextInput from 'components/primitives/TextInput';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';
import { initialValues } from './utils';
const AddInludeInputBuilder = () => {
  const formik = useFormikContext<typeof initialValues>(); // Specify the type here
  const [error, setError] = useState<string>('');

  const [tempIncludedInput, meta, tempIncludedHelper] = useField(
    'includedInRentalTemp',
  );

  const removeValueFromArray = (
    array: string[],
    valueToRemove: string,
  ): string[] => {
    return array.filter((value) => value !== valueToRemove);
  };

  return (
    <div>
      <div className="flex items-end justify-start">
        <TextInput
          key="includedInRental"
          hidden={true}
          name="includedInRental"
          label="Additional Items Included"
          placeholder={`Example: "Saw"`}
        />
        <div className="flex gap-3 items-end">
          <TextInput
            key="includedInRentalTemp"
            hideErrorComponent={true}
            name="includedInRentalTemp"
            label="Additional Items Included"
            placeholder={`Example: "Garden Hose"`}
          />
          <Button
            type="button"
            key="includedInRentalButton"
            className="ml-1"
            variant={'solid'}
            colorScheme={'primary'}
            onClick={() => {
              const includedRentals: string[] =
                formik.values.includedInRental || [];

              const value = tempIncludedInput.value;

              if (!value) return;
              if (!includedRentals.includes(value!)) {
                formik.setFieldValue('includedInRental', [
                  ...includedRentals,
                  value,
                ]);
                formik.setFieldValue('includedInRentalTemp', '');
              } else {
                setError('Already added');
                setTimeout(() => {
                  setError('');
                }, 3000);
              }
            }}>
            Add
          </Button>
        </div>
      </div>
      <div className="h-2">
        <span className="text-red-500 text-sm">{error}</span>
      </div>
      <div className="flex mb-8 mt-4 flex-wrap">
        {formik.values.includedInRental.map((e, index) => (
          <div key={e + index} className="dropdown">
            <div tabIndex={0} role="button" className="btn m-1 btn-sm">
              {e}
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
              <li
                onClick={() => {
                  const includedRentals: string[] =
                    formik.values.includedInRental || [];
                  const updatedArray = removeValueFromArray(includedRentals, e);
                  formik.setFieldValue('includedInRental', updatedArray);
                }}>
                <a>Remove</a>
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddInludeInputBuilder;
