import { QueryClientProvider } from '@tanstack/react-query';

import RootRoutes from 'routes';

import { ToastContainer } from 'react-toastify';

import ErrorBoundary from 'components/core/ErrorBoundary';

import { BrowserRouter } from 'react-router-dom';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import SiteBlocker from 'components/core/SiteBlocker';
import HeaderContextProvider from 'state/contexts/headerContext';
import { $session } from 'state/stores';
import { queryClient } from 'utils';

function App() {
  const isAuthenticated = $session.selectors.useIsAuthenticatedBySiteBlocker();
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <SiteBlocker />
        {isAuthenticated ? (
          <BrowserRouter>
            <HeaderContextProvider>
              <RootRoutes />
            </HeaderContextProvider>
          </BrowserRouter>
        ) : null}
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
