import { RateDataResponse } from 'types/rates';
import { api } from './instance';

export const fetchItemReviewRates = async () => {
  const { data } = await api.get<RateDataResponse>(`/reviews/rates/feature`);
  return data?.item;
};

export const fetchOwnerReviewRates = async () => {
  const { data } = await api.get<RateDataResponse>(`/reviews/rates/owner`);
  return data?.item;
};

export const fetchRenterReviewRates = async () => {
  const { data } = await api.get<RateDataResponse>(`/reviews/rates/renter`);
  return data?.item;
};
