import React, { FC, useState } from 'react';

import { LendTableViewProps } from './LendTable.props';
import BadgeView from 'components/primitives/StatusBadge/Badge.view';
import Loading from 'components/primitives/Loading';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { getNetworkImage } from 'utils/assets';
import CommonTable from 'components/primitives/CommonTable';
import { Product } from 'types/item';
import { QUERY_KEYS } from 'api/queries';
import { $itemRentDetails } from 'state/stores/rent-details';
import { format } from 'date-fns';
import Table from 'components/primitives/Table';
import NoContent from 'components/primitives/NoContent';
import View from 'components/core/View/View.view';
import Pagination from 'components/primitives/Pagination';
import NoItemsFound from 'components/vectors/NoItemsFound';

const LendTableView: FC<LendTableViewProps> = ({
  products,
  isLoading,
  filteredItems,
  totalPages,
  setPage,
  currentPage,
}) => {
  const navigate = useNavigate();

  const showProductDetails = (index: number) => {
    const productId = filteredItems[index];
    QUERY_KEYS.invalidate(QUERY_KEYS.FETCH_RENT_DETAILS_KEY);
    QUERY_KEYS.invalidate(QUERY_KEYS.FETCH_ONGIONG_RENTS_KEY);
    $itemRentDetails.actions.reset();
    navigate(routesSettings.MAIN_LEND_PRODUCT.getPath(productId._id!), {
      replace: false,
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <View.Container>
      <div className="space-y-8">
        <Table
          itemNotFoundComponent={
            <NoItemsFound description="We couldn’t find anything matching your search criteria. Try adjusting your filters or search terms. Click “Create Item” above to start adding today." />
          }
          headers={['Image', 'Name', 'Category', 'Status', 'Date Created']}
          itemCount={filteredItems.length ?? 0}
          onClickItem={(index) => showProductDetails(index)}
          builder={function (index: number): React.ReactNode {
            const product = filteredItems[index];
            return (
              <>
                <td className="whitespace-nowrap  px-6 py-4 font-medium w-4">
                  <img
                    className="w-10 h-10 rounded-md object-cover"
                    src={getNetworkImage(
                      product.featuredPhoto ?? product?.photos[0],
                    )}
                  />
                </td>
                <td className="whitespace-nowrap text-sm text-black-700 font-medium font-primary capitalize  px-6 py-4">
                  {product.title}
                </td>
                <td className="whitespace-nowrap text-sm text-gray-700 font-medium font-primary capitalize px-6 py-4 ">
                  {product?.categories[0].name}
                </td>
                <td className="whitespace-nowrap py-4 w-48">
                  <BadgeView
                    value={product.status}
                    textColor="white"
                    bgColor="#22CC7A"
                    className="rounded-full w-24"
                  />
                </td>
                <td className="whitespace-nowrap text-gray-700 font-medium font-primary capitalize px-6 py-4 w-64">
                  {format(
                    new Date(product.createdAt),
                    'MMMM dd, yyyy hh:mm:ss a',
                  )}
                </td>
              </>
            );
          }}
        />
        <div className="flex justify-end">
          <Pagination
            totalPages={totalPages!}
            currentPageNo={currentPage!}
            onPageChange={function (page: number): void {
              setPage && setPage(page);
            }}
          />
        </div>
      </div>
    </View.Container>
  );
};

export default LendTableView;
