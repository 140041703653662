import React, { FC, forwardRef } from 'react';

import { CopyToClipBoardModalProps } from './CopyToClipBoardModal.props';
import CommonModal from 'components/modals/CommonModal';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import TextInput from '../TextInput';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import ToastHelper from 'utils/toast';

const CopyToClipBoardModalView = forwardRef<
  CommonModalRef,
  CopyToClipBoardModalProps
>((props, ref) => {
  const { value = '', onSubmitComplete } = props;
  return (
    <CommonModal className="w-[90vw] md:w-[70vw] lg:w-[40vw]" ref={ref}>
      <h3 className="mb-4">Link</h3>
      <Formik
        initialValues={{ value }}
        onSubmit={() => {
          navigator.clipboard.writeText(value);
          ToastHelper.success('Copied to clipoard');
          onSubmitComplete && onSubmitComplete();
        }}>
        <Form>
          <TextInput
            onFocus={(e) => {
              e.target.select();
            }}
            name="value"
            hideErrorComponent
          />
          <Button
            id="open-btn"
            variant="solid"
            colorScheme="primary"
            className="btn-var-solid w-full h-auto btn-var-solid mt-5">
            Copy Link
          </Button>
        </Form>
      </Formik>
    </CommonModal>
  );
});

export default CopyToClipBoardModalView;
