import { MutationFunction } from '@tanstack/react-query';
import { UpdateUserResponse, UserDetail, UserDetailResponse } from 'types/user';
import { api } from './instance';
import {
  UserProfileResponse,
  UserProfileUpdatePayload,
} from 'types/userProfile';

export const updateUserProfile = async (
  id: string,
  payload: Partial<UserProfileUpdatePayload>,
) => {
  const { data } = await api.patch<UserProfileResponse>(
    `/profile/${id}`,
    payload,
  );

  return data?.item;
};
