export function simplifyDate(dateTimeString?: string): string {
  const date = new Date(dateTimeString ?? '');

  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(date);

  const day = date.getDate();
  const suffix =
    day === 1 || day === 21 || day === 31
      ? 'st'
      : day === 2 || day === 22
        ? 'nd'
        : day === 3 || day === 23
          ? 'rd'
          : 'th';

  return formattedDate.replace(/\d+(?=,)/, `${day}${suffix}`);
}

export function formatDate(dateTimeString?: string): string {
  const date = new Date(dateTimeString ?? '');
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear().toString().slice(-2);
  return `${day}/${month}/${year}`;
}

export function getYearFromDateString(dateTimeString?: string): string {
  const date = new Date(dateTimeString ?? '');
  return date.getFullYear().toString();
}

export function isDateBetween(startDate: Date, endDate: Date): boolean {
  const currentDate = new Date();
  return startDate <= currentDate && currentDate <= endDate;
}

export const convertUTCToLocalTime = (utcDateString: string): string => {
  const utcDate = new Date(utcDateString);
  const options: Intl.DateTimeFormatOptions = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const localDateString = utcDate.toLocaleString('en-US', options);
  return localDateString; // Removing the comma for better readability
};
