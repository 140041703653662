import { useQuery } from '@tanstack/react-query';
import useUpdateUserMutation from 'api/mutations/useUpdateUserMutation';
import { fetchUserDetail } from 'api/services/user';
import { UserDetail } from 'types/user';

const FETCH_USER_DETAIL_KEY = 'fetch-user-detail-key';

const useUserDetailQuery = (
  id: string,
  onSuccessUpdateUser?: () => void,
  onSuccess?: (userDetail: UserDetail) => void,
) => {
  const { data, isLoading } = useQuery({
    queryKey: [FETCH_USER_DETAIL_KEY, id],
    queryFn: async () => {
      const res = await fetchUserDetail(id);
      onSuccess && onSuccess(res);
      return res;
    },
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const { mutate: updateUser, isPending: isUpdatingUser } =
    useUpdateUserMutation(id, onSuccessUpdateUser);

  return { data, isLoading, updateUser };
};

export default useUserDetailQuery;

export { FETCH_USER_DETAIL_KEY };
