import {
  CategoriesResponse,
  Category,
  CategoryResponse,
  TopCategoryResponse,
} from 'types/category';
import { api } from './instance';

export const fetchCategories = async (): Promise<CategoriesResponse> => {
  try {
    const response = await api.get<CategoriesResponse>(`/categories`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch subcategories');
  }
};

export const fetchCategoryById = async (
  categoryId: string,
): Promise<Category> => {
  try {
    const response = await api.get<CategoriesResponse>(
      `/categories/${categoryId}`,
    );

    const { data } = response;
    if (!data) {
      throw new Error(`not found category id: ${categoryId}`);
    }

    const [category] = data.items;
    return category;
  } catch (error) {
    throw new Error('Failed to fetch category');
  }
};

export const fetchTopCategories = async () => {
  const { data } = await api.get<TopCategoryResponse>(`/rent/topCategories`);
  return data;
};
