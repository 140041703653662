import React from 'react';

type UploadIconProps = {
  fill?: string | undefined;
};

const UploadIcon = (props: UploadIconProps) => {
  const { fill } = props;
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1412_15548"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="33"
        height="32">
        <rect x="0.617188" width="32" height="32" fill="#22CC7A" />
      </mask>
      <g mask="url(#mask0_1412_15548)">
        <path
          d="M8.61979 26.6651C7.88646 26.6651 7.25868 26.404 6.73646 25.8818C6.21424 25.3595 5.95312 24.7318 5.95312 23.9984V21.3318C5.95312 20.954 6.0809 20.6373 6.33646 20.3818C6.59201 20.1262 6.90868 19.9984 7.28646 19.9984C7.66424 19.9984 7.9809 20.1262 8.23646 20.3818C8.49201 20.6373 8.61979 20.954 8.61979 21.3318V23.9984H24.6198V21.3318C24.6198 20.954 24.7476 20.6373 25.0031 20.3818C25.2587 20.1262 25.5753 19.9984 25.9531 19.9984C26.3309 19.9984 26.6476 20.1262 26.9031 20.3818C27.1587 20.6373 27.2865 20.954 27.2865 21.3318V23.9984C27.2865 24.7318 27.0253 25.3595 26.5031 25.8818C25.9809 26.404 25.3531 26.6651 24.6198 26.6651H8.61979ZM15.2865 10.4651L12.7865 12.9651C12.5198 13.2318 12.2031 13.3595 11.8365 13.3484C11.4698 13.3373 11.1531 13.1984 10.8865 12.9318C10.642 12.6651 10.5142 12.354 10.5031 11.9984C10.492 11.6429 10.6198 11.3318 10.8865 11.0651L15.6865 6.2651C15.8198 6.13177 15.9642 6.03733 16.1198 5.98177C16.2753 5.92622 16.442 5.89844 16.6198 5.89844C16.7976 5.89844 16.9642 5.92622 17.1198 5.98177C17.2753 6.03733 17.4198 6.13177 17.5531 6.2651L22.3531 11.0651C22.6198 11.3318 22.7476 11.6429 22.7365 11.9984C22.7253 12.354 22.5976 12.6651 22.3531 12.9318C22.0865 13.1984 21.7698 13.3373 21.4031 13.3484C21.0365 13.3595 20.7198 13.2318 20.4531 12.9651L17.9531 10.4651V19.9984C17.9531 20.3762 17.8253 20.6929 17.5698 20.9484C17.3142 21.204 16.9976 21.3318 16.6198 21.3318C16.242 21.3318 15.9253 21.204 15.6698 20.9484C15.4142 20.6929 15.2865 20.3762 15.2865 19.9984V10.4651Z"
          fill="#682082"
        />
      </g>
    </svg>
  );
};

export default UploadIcon;
