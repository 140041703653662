import { useQuery } from '@tanstack/react-query';
import { getOngoingRents } from 'api/services/rent';
import { getUserOverallRatings } from 'api/services/reviews';

const FETCH_USER_OVERALL_RATINGS = 'fetch-user-overall-ratings-key';

const useOverallUserRatingsQuery = (itemId: string) => {
  const query = useQuery({
    queryKey: [FETCH_USER_OVERALL_RATINGS, itemId],
    queryFn: () => getUserOverallRatings(itemId),
    enabled: !!itemId,
    gcTime: 0,
  });
  return query;
};

export default useOverallUserRatingsQuery;

export { FETCH_USER_OVERALL_RATINGS };
