import React, { FC } from 'react';

import { LoadingProps } from './Loading.props';

const LoaderPrimary = () => {
  return (
    <span className="loading loading-ring loading-lg text-primary-500"></span>
  );
};

const LoaderSecondary = () => {
  return (
    <span className="loading loading-ring loading-lg text-secondary-900"></span>
  );
};

const LoadingView: FC<LoadingProps> & {
  LoaderPrimary: FC;
  LoaderSecondary: FC;
} = () => {
  return (
    <div className="flex w-full h-full flex-col gap-8 justify-center items-center md:py-14">
      <LoaderPrimary />
    </div>
  );
};

LoadingView.LoaderPrimary = LoaderPrimary;
LoadingView.LoaderSecondary = LoaderSecondary;

export default LoadingView;
