import React from 'react';

function CheckCircleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <mask
        id="mask0_2610_101086"
        style={{ maskType: 'alpha' }}
        width="32"
        height="32"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H32V32H0z"></path>
      </mask>
      <g mask="url(#mask0_2610_101086)">
        <path
          fill="#15B06F"
          d="M14.133 18.4l-2.867-2.867c-.244-.244-.556-.367-.933-.367-.378 0-.69.123-.934.367-.244.245-.366.556-.366.934 0 .377.122.688.366.933l3.8 3.8c.267.267.578.4.934.4.355 0 .666-.134.933-.4l7.533-7.534c.245-.244.367-.555.367-.933s-.122-.689-.367-.933c-.244-.245-.555-.367-.933-.367s-.689.122-.933.367l-6.6 6.6zm1.866 10.933c-1.844 0-3.577-.35-5.2-1.05a13.465 13.465 0 01-4.233-2.85c-1.2-1.2-2.15-2.61-2.85-4.233-.7-1.622-1.05-3.356-1.05-5.2 0-1.845.35-3.578 1.05-5.2.7-1.622 1.65-3.034 2.85-4.234 1.2-1.2 2.611-2.15 4.233-2.85 1.623-.7 3.356-1.05 5.2-1.05 1.845 0 3.578.35 5.2 1.05 1.623.7 3.034 1.65 4.234 2.85 1.2 1.2 2.15 2.612 2.85 4.234.7 1.622 1.05 3.355 1.05 5.2 0 1.844-.35 3.578-1.05 5.2a13.464 13.464 0 01-2.85 4.233c-1.2 1.2-2.611 2.15-4.234 2.85-1.622.7-3.355 1.05-5.2 1.05z"></path>
      </g>
    </svg>
  );
}

export default CheckCircleIcon;
