import { FC, useState } from 'react';

import CommonContainer from 'components/primitives/CommonContainer';
import { RentedProductDetailsViewProps } from './RentedProductDetails.props';

import pricetag from 'assets/images/priceTag.png';

import View from 'components/core/View/View.view';
import Attachment from 'components/icons/Attachment';
import RrMessage from 'components/icons/RrMessage';
import AlertModal from 'components/modals/AlertModal';
import ComunicationMethodModal from 'components/modals/ComunicationMethodModal';
import Button from 'components/primitives/Button';
import ImageShowcase from 'components/primitives/ImageShowcase';
import { routesSettings } from 'constants/routes';
import ModalReference from 'helperClasses/modalReference';
import RentHelper from 'helperClasses/rent';
import useIsLenderView from 'hooks/useIsLenderView';
import { useNavigate } from 'react-router-dom';
import { useRentItemStore } from 'state/atoms';
import { $itemRentDetails } from 'state/stores/rent-details';
import { openUrlInNewTab, validateAndFormatUrl } from 'utils/common';
import { getYearFromDateString } from 'utils/date';
import DateTimeZ from 'utils/dateTime';
import ProductToRentView from '../ProductToRent/ProductToRentView';
import RentForm from '../RentForm';

const RentedProductDetailsView: FC<RentedProductDetailsViewProps> = ({
  isOwnerDataLoading,
}) => {
  const navigate = useNavigate();
  const chatPopup = new ModalReference();
  const onContinue = () => {
    localStorage.setItem('showModal', ' false');
    window.location.reload();
  };

  const onGoingRents = $itemRentDetails.selectors.useOnGoingRents();
  const product = $itemRentDetails.selectors.useProduct();
  const ownerData = $itemRentDetails.selectors.useItemOwner();
  const rendDetails = $itemRentDetails.selectors.useRentDetails();
  const isOwnersItem = $itemRentDetails.selectors.isOwnersItem();

  const isLenderView = useIsLenderView();

  const [renterIndex, setRenterIndex] = useState(0);

  let rentData =
    onGoingRents.length == 0 ? rendDetails : onGoingRents[renterIndex];

  const rentHelper = new RentHelper(rentData!);

  const isRentalCanceled = rentHelper.isRentCanceled();

  const nextRenter = () => {
    if (renterIndex < onGoingRents!.length - 1) {
      setRenterIndex(renterIndex + 1);
    }
  };

  var [, setTempRentInfo] = useRentItemStore();

  const handleRentClick = (payload: any) => {
    if (payload === null) return;
    const startDate: DateTimeZ = new DateTimeZ(payload[0]); // Current date
    const endDate: DateTimeZ = new DateTimeZ(payload[1]);

    setTempRentInfo({
      itemId: product?._id!,
      startDate: startDate.defaultPayloadStringFormat(),
      endDate: endDate.defaultPayloadStringFormat(),
    });

    navigate(
      routesSettings.MAIN_RENT_PRODUCT_RENT_DETAILS.getPath(product?._id!),
      {
        replace: false,
        state: {
          startDate: startDate,
          endDate: endDate,
        },
      },
    );
  };

  const previousRenter = () => {
    if (renterIndex > 0) {
      setRenterIndex(renterIndex - 1);
    }
  };

  const navigateToProfile = (profileId: string) => {
    navigate(`${routesSettings.MAIN_OWNER_PROFILE.getPath(profileId || '')}`);
  };

  const currentDate = new DateTimeZ();
  const startDate = new DateTimeZ(rentData?.startDate!);
  const endDate = new DateTimeZ(rentData?.endDate!);

  const isCurrentRenter = currentDate.isDateInRange(startDate, endDate);

  const daysDifference = startDate.differenceInDays(endDate);
  const pricePerDay = product?.price!;
  const serviceCharge = 50;
  const tax = 10;

  // Calculate the total cost
  const totalPrice = daysDifference * pricePerDay + serviceCharge + tax;

  const ownersManualLink = validateAndFormatUrl(product?.link!);

  const state = product?.location?.state?.title ?? '';
  const city = product?.location?.title ?? '';

  return (
    <div className="relative">
      <ComunicationMethodModal onContinue={onContinue} />
      <div className="grid lg:grid-cols-12 lg:space-x-8">
        <CommonContainer
          style={{
            display: 'inline-block',
          }}
          className="relative inline-block lg:col-span-8 col-span-12">
          <View.Renter>
            <div>
              <div className="h-20 -mr-[3.87rem] absolute right-[43px] top-[20px]">
                <div className="relative">
                  <img src={pricetag} alt="" />
                  <span className="flex absolute top-1  w-40  h-full justify-center items-center text-neutral-700 text-2xl font-semibold font-fourth">
                    ${product?.price}/day
                  </span>
                </div>
              </div>

              <div className="grid lg:grid-cols-12 gap-8">
                <div className="col-span-5">
                  <ImageShowcase
                    images={product?.photos ?? []}
                    contollButtonsSize={14}
                    featuredImage={product?.featuredPhoto ?? product?.photos[0]}
                  />
                </div>
                <div className="flex flex-col col-span-7">
                  <div className="flex flex-row justify-between  mb-10">
                    <div className="justify-start items-start max-w-md">
                      <h1 className="capitalize">{product?.title}</h1>
                      <button
                        onClick={() => {
                          openUrlInNewTab(product?.link!);
                        }}
                        className="btn btn-sm mt-4">
                        Owner's Manual
                        <Attachment w="16" h="16" />
                      </button>
                      {/* <a
                        onClick={() => {
                          openUrlInNewTab(product?.link!);
                        }}
                        target="_blank">
                        <span
                          className={`text-blue-700 text-sm font-bold font-fourth cursor-pointer`}>
                          {product?.link}
                        </span>
                      </a> */}
                    </div>
                  </div>
                  {!isOwnersItem && (
                    <RentForm
                      handleRentClick={handleRentClick}
                      submitButtonTitle="Rent Again"
                    />
                  )}
                  <div className="flex flex-row gap-20 items-center mt-8">
                    <div className="flex flex-col">
                      <div className="text-neutral-700 text-base font-semibold font-primary capitalize">
                        {product?.title} + Security Deposit + Taxes
                      </div>
                      {/* <div className="text-neutral-700 text-xs font-normal font-secondary leading-tight">
                        Rental duration from
                        {simplifyDate(rentData?.startDate)} to
                        {simplifyDate(rentData?.endDate)}
                      </div> */}
                    </div>
                    <div className="text-right text-neutral-700 text-base font-semibold font-primary">
                      ${totalPrice}
                    </div>
                  </div>
                  <div className="divider" />
                  <div>
                    <div className="text-neutral-700 text-base font-bold font-primary">
                      Location
                    </div>
                    <div className="text-neutral-700 text-sm font-normal font-secondary capitalize">
                      {city + (city ? ', ' : '')} {state}
                    </div>
                    <div className="divider" />

                    <div className="text-neutral-700 text-base font-bold font-primary">
                      Pickup selected
                    </div>
                    <div className="text-neutral-700 text-sm font-normal font-secondary">
                      <div className="space-y-4">
                        <ul>
                          {[
                            { key: 'pickup', title: 'Pickup' },
                            { key: 'delivery', title: 'Delivery' },
                            { key: 'publicMeetup', title: 'Public meeting' },
                          ].map((e, index) => {
                            const deliveryOption: any =
                              product?.deliveryOptions as any;
                            try {
                              const isChecked = deliveryOption[e.key];
                              return (
                                isChecked && (
                                  <li
                                    key={`pickup_${index}`}
                                    className="font-bold">
                                    {e.title}
                                  </li>
                                )
                              );
                            } catch (error) {
                              return null;
                            }
                          })}
                        </ul>
                      </div>
                      {`The owner we’ll be in touch with you to set a time and
                    location`}
                    </div>

                    <div className="divider" />
                  </div>
                </div>
              </div>
            </div>
          </View.Renter>
          <View.Lender>
            <ProductToRentView />
          </View.Lender>
        </CommonContainer>

        <div className="flex flex-col justify-between md:mt-8 lg:mt-0 space-y-8 lg:col-span-4 col-span-12">
          <CommonContainer>
            <div className="flex gap-2 justify-center items-center">
              <div className="text-center text-neutral-700 text-lg font-bold font-tertiary leading-7 tracking-tight">
                Rental Dates
              </div>
              <div className="text-center text-neutral-700 text-lg font-tertiary leading-7 tracking-tight">
                {startDate.formatDate()}
              </div>
              <div className=" text-neutral-700 text-lg  font-tertiary">to</div>
              <div className="text-center text-neutral-700 text-lg font-tertiary leading-7 tracking-tight">
                {endDate.formatDate()}
              </div>
            </div>
            {/* Rent Again Button */}
          </CommonContainer>

          {/* <CommonContainer> */}

          {/* </CommonContainer> */}

          {!isRentalCanceled && (
            <CommonContainer className="relative">
              <View.Lender>
                <div>
                  {isCurrentRenter && (
                    <div className=" absolute top-0 right-0 px-4 py-2 rounded-tr-[6px] shadow-md text-white text-lg font-bold rounded-bl-lg bg-[#E8B95C]">
                      Current Renter
                    </div>
                  )}
                </div>
              </View.Lender>
              <View.Lender>
                <div className="absolute w-full flex left-0 right-0 top-[22%] px-6 py-2 justify-between ">
                  <svg
                    style={{
                      opacity: renterIndex === 0 ? '0.3' : '1',
                    }}
                    className="cursor-pointer transform transition-transform hover:scale-110 hover:-translate-x-1"
                    onClick={previousRenter}
                    height="30"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.2998 11.2969L0.699804 6.69687C0.599804 6.59687 0.529138 6.48854 0.487804 6.37187C0.445804 6.25521 0.424805 6.13021 0.424805 5.99687C0.424805 5.86354 0.445804 5.73854 0.487804 5.62187C0.529138 5.50521 0.599804 5.39687 0.699804 5.29688L5.2998 0.696875C5.48314 0.513541 5.71647 0.421875 5.9998 0.421875C6.28314 0.421875 6.51647 0.513541 6.6998 0.696875C6.88314 0.880208 6.9748 1.11354 6.9748 1.39687C6.9748 1.68021 6.88314 1.91354 6.6998 2.09688L2.7998 5.99687L6.6998 9.89687C6.88314 10.0802 6.9748 10.3135 6.9748 10.5969C6.9748 10.8802 6.88314 11.1135 6.6998 11.2969C6.51647 11.4802 6.28314 11.5719 5.9998 11.5719C5.71647 11.5719 5.48314 11.4802 5.2998 11.2969Z"
                      fill="#075015"
                    />
                  </svg>

                  <svg
                    className="cursor-pointer transform transition-transform hover:scale-110 hover:translate-x-1"
                    // width="7"
                    height="30"
                    style={{
                      opacity:
                        renterIndex === onGoingRents?.length! - 1 ? '0.2' : '1',
                    }}
                    onClick={nextRenter}
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2.1001 11.2969L6.7001 6.69687C6.8001 6.59687 6.87076 6.48854 6.9121 6.37187C6.9541 6.25521 6.9751 6.13021 6.9751 5.99687C6.9751 5.86354 6.9541 5.73854 6.9121 5.62187C6.87076 5.50521 6.8001 5.39687 6.7001 5.29688L2.1001 0.696875C1.91676 0.513541 1.68343 0.421875 1.4001 0.421875C1.11676 0.421875 0.883431 0.513541 0.700098 0.696875C0.516765 0.880208 0.425097 1.11354 0.425097 1.39687C0.425097 1.68021 0.516765 1.91354 0.700098 2.09688L4.6001 5.99687L0.700098 9.89687C0.516765 10.0802 0.425097 10.3135 0.425097 10.5969C0.425097 10.8802 0.516765 11.1135 0.700098 11.2969C0.883431 11.4802 1.11676 11.5719 1.4001 11.5719C1.68343 11.5719 1.91676 11.4802 2.1001 11.2969Z"
                      fill="#075015"
                    />
                  </svg>
                </div>
              </View.Lender>

              <div className="flex flex-col gap-8 w-full px-4 mt-4">
                <div className="flex justify-center space-x-8 w-full">
                  {/* <div className="avatar">
                  <div className="w-20 rounded-full">
                    <img src={images.placeholderImage} />
                  </div>
                </div> */}
                  <div
                    className="flex flex-col items-center cursor-pointer"
                    onClick={() =>
                      navigateToProfile(
                        isOwnersItem ? rentData?.renter?._id! : ownerData?._id!,
                      )
                    }>
                    <div className="text-center text-neutral-700 font-medium font-['Poppins'] leading-7 tracking-wide capitalize">
                      {isOwnersItem
                        ? `${rentData?.renter?.fname ?? ''} ${rentData?.renter?.lname ?? ''}`
                        : `${ownerData?.fname ?? ''} ${ownerData?.lname ?? ''}`}
                    </div>
                    {/* <RateView value={5} isChecked={false} /> */}
                    <div className="opacity-60 text-center text-neutral-700 text-xs font-light font-['Poppins'] leading-none mt-2">
                      Member since{' '}
                      {!isOwnerDataLoading &&
                        getYearFromDateString(
                          isOwnersItem
                            ? rentData?.createdAt
                            : ownerData?.createdAt,
                        )}
                    </div>
                  </div>
                </div>

                <Button
                  onClick={() => chatPopup.open()}
                  variant={'solid'}
                  colorScheme={'primary'}>
                  Chat
                </Button>
              </div>
            </CommonContainer>
          )}
        </div>
      </div>

      <AlertModal
        ref={chatPopup.getReference()}
        icon={<RrMessage />}
        onClickButton={() => chatPopup.close()}>
        <p className="text-center">
          Please check the message sent to your registered mobile to contact
          the 
          {isLenderView ? 'renter' : 'owner'}
        </p>
      </AlertModal>
    </div>
  );
};

export default RentedProductDetailsView;
