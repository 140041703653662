import { IconProps } from './type';

const Attachment = (props: IconProps) => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={props.w ?? '100'}
      height={props.h ?? '100'}
      style={{ fill: '#000000' }}>
      <title>noun-link-94818-svg</title>

      <g id="Layer">
        <g id="Layer">
          <path
            id="Layer"
            // className="s0"
            d="m75.4 58.1l12.7-12.6c9.2-9.3 9.2-24.3 0-33.6-9.3-9.2-24.3-9.2-33.6 0l-18.3 18.3c-9.2 9.3-9.2 24.3 0 33.6q2.1 2 4.5 3.4l9.8-9.8c-1.9-0.4-3.7-1.3-5.1-2.8-4.2-4.2-4.2-11 0-15.2l18.3-18.3c4.2-4.2 11-4.2 15.2 0 4.2 4.2 4.2 11 0 15.2l-5.4 5.4c2.3 5.2 2.9 10.9 1.9 16.4z"
          />
        </g>
        <g id="Layer">
          <path
            id="Layer"
            // class="s0"
            d="m24.6 41.9l-12.7 12.6c-9.2 9.3-9.2 24.3 0 33.6 9.3 9.2 24.3 9.2 33.6 0l18.3-18.3c9.2-9.3 9.2-24.3 0-33.6q-2.1-2-4.5-3.4l-9.8 9.8c1.9 0.4 3.7 1.3 5.1 2.8 4.2 4.2 4.2 11 0 15.2l-18.3 18.3c-4.2 4.2-11 4.2-15.2 0-4.2-4.2-4.2-11 0-15.2l5.4-5.4c-2.3-5.2-2.9-10.9-1.9-16.4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Attachment;
