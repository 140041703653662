const rentalColors = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#ffffff',
  black: '#000',
  primary: {
    50: '#EBF3EC', //primary/Pearl Dew (bg-zinc-100) Backgrounds
    100: '#D2F5E1', // primary/Tranquil Breeze
    300: '#8EE3BA', // primary/Serene Meadow
    500: '#22CC7A', // primary/Enchanted Jade Primary
    900: '#155239', //primary/Deep Forest
  },
  secondary: {
    50: '#FAF1FF', //secondary/Misty Dreams
    100: '#F4E7FA', //secondary/Ethereal Haze
    200: '#DCD2DA', //secondary/Shadowed Lavender
    300: '#E7C0ED', //secondary/Whispering Lilacs
    400: '#D4A2DC', //pink-300
    900: '#682082', //secondary/Velvet Twilight (Button outline)
  },
  gray: {
    50: '#FFFFFF', //neutral/Pure Moonlight
    100: '#F0F0F0', //neutral/grey
    300: '#D9D9D9', // neutral/Silver Mist Light line color
    400: '#A6A6A6', // neutral/Tranquil Breeze (Disabled)
    500: '#737373', // neutral/Silent Echo (Texts)
    700: '#3F3F3F', // neutral/Enchanted Twilight (Titles)
  },
  category: {
    construction: '#D74E4E',
    yardEquipment: '#D47F32',
    recreation: '#BEA313',
    automotive: '#539456',
    childrens: '#50AC9C',
    cooking: '#489EB9',
    electronics: '#D47F32',
    events: '#995DD1',
    other: '#CC6094',
  },
  subcategory: {
    construction: '#FFEAEA',
    'yard equipment': '#FFF2E5',
    recreation: '#FFFCEA',
    automotive: '#E4FFE5',
    childrens: '#DEFFF9',
    cooking: '#E2F8FF',
    electronics: '#F1F4FF',
    events: '#F6EEFF',
    other: '#FFE7F2',
  },
  alerts: {
    success: '#15B06F',
    warning: '#FFCB45',
    error: '#F04747',
  },
};

export default rentalColors;

type SubcategoryColors = {
  [key: string]: string;
};
