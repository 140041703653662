import { useMutation } from '@tanstack/react-query';
import { stripeOnboarding } from 'api/services/stripe';
import { $session } from 'state/stores';

const useConnectToStripeMutation = (stripeId: string, returnUrl?: string) =>
  useMutation({
    mutationFn: async () => {
      $session.actions.setAuth();
      const defaultReturnUrl =
        returnUrl ??
        `${window.location.origin}${window.location.pathname}?fromStripe=true`;
      return stripeOnboarding(String(stripeId), defaultReturnUrl);
    },
    onSuccess: ({ url }) => {
      window.open(url, '_self');
    },
  });

export default useConnectToStripeMutation;
