import React, { FC } from 'react';

import { SelectOptionProps } from './SelectOption.props';

const SelectOptionView: FC<SelectOptionProps> = ({
  value,
  child,
  selected = false,
}) => {
  return (
    <option selected={selected} key={value} value={value}>
      {child}
    </option>
  );
};

export default SelectOptionView;
