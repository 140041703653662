import { FC } from 'react';

import { Form, Formik } from 'formik';

import AuthWrapper from 'components/modules/AuthWrapper';
import Button from 'components/primitives/Button';

import { initialValues, validationSchema } from './Login.utils';

import PasswordInput from 'components/primitives/PasswordInput';
import TextInput from 'components/primitives/TextInput';
import { LoginViewProps } from './Login.props';

const LoginView: FC<LoginViewProps> = ({
  onSubmit,
  isLoading,
  goToRegister,
  hideCoverImage,
}) => {
  const LoginForm = () => {
    return (
      <div>
        <h2 className="text-center">Log in to your account</h2>
        <p className="font-secondary text-center mt-1">
          Welcome back to Rapid Rentals!
        </p>
        <div className="justify-center items-center place-items-center m-4">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}>
            <Form className="flex flex-col justify-center ">
              <TextInput name="username" placeholder="Email" />
              <PasswordInput name="password" placeholder="Password" />
              <Button
                isLoading={isLoading}
                id="open-btn"
                type="submit"
                variant="solid"
                colorScheme="primary"
                className="w-full mt-5">
                Login
              </Button>
            </Form>
          </Formik>
        </div>

        <div className="flex flex-row justify-center mt-5 text-gray-600">
          <p className="text-sm">Don´t have an account?&nbsp;</p>
          <p
            onClick={goToRegister}
            className="text-sm text-primary-500 cursor-pointer">
            Sign up
          </p>
        </div>
      </div>
    );
  };
  return hideCoverImage ? (
    <LoginForm />
  ) : (
    <AuthWrapper>
      <LoginForm />
    </AuthWrapper>
  );
};

export default LoginView;
