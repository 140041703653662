import React, { FC, useState } from 'react';

import { RentedProductDetailsProps } from './RentedProductDetails.props';

import RentedProductDetailsView from './RentedProductDetails.view';
import { useUser } from 'api/queries';
import { $itemRentDetails } from 'state/stores/rent-details';

const RentedProductDetailsContainer: FC<RentedProductDetailsProps> = () => {
  const product = $itemRentDetails.selectors.useProduct();
  const { data: ownerData, isLoading: isOwnerDataLoading } = useUser(
    product?.ownerId!,
  );
  $itemRentDetails.actions.setItemOwner(ownerData!);
  return <RentedProductDetailsView isOwnerDataLoading={isOwnerDataLoading} />;
};

export default RentedProductDetailsContainer;
