import React, { FC } from 'react';

import { AuthWrapperProps } from './AuthWrapper.props';

import AuthWrapperView from './AuthWrapper.view';

const AuthWrapperContainer: FC<AuthWrapperProps> = (props) => {
  return <AuthWrapperView children={props.children} />;
};

export default AuthWrapperContainer;
