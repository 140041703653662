import React from 'react';
import { IconProps } from './type';

const DateIcon = (props: IconProps) => {
  const { w, h } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      fill="none"
      viewBox="0 0 24 24">
      <g>
        <mask
          id="mask0_1884_1906"
          style={{ maskType: 'alpha' }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_1884_1906)">
          <path
            fill="#fff"
            d="M5 22c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 013 20V6c0-.55.196-1.02.587-1.412A1.926 1.926 0 015 4h1V3c0-.283.096-.52.287-.712A.968.968 0 017 2c.283 0 .52.096.713.288.191.191.287.429.287.712v1h8V3c0-.283.096-.52.288-.712A.968.968 0 0117 2c.283 0 .52.096.712.288.192.191.288.429.288.712v1h1c.55 0 1.02.196 1.413.588.391.391.587.862.587 1.412v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0119 22H5zm0-2h14V10H5v10z"></path>
        </g>
      </g>
    </svg>
  );
};

export default DateIcon;
