import { useCallback, useState } from 'react';

import { RentProps } from './Rent.props';

import { useCategories, useItems } from 'api/queries';
import RentView from './Rent.view';

import useRentHistoryQuery from 'api/queries/useRentHistoryQuery';
import debounce from 'lodash.debounce';
import { useSearchParams } from 'react-router-dom';
import { $session } from 'state/stores';

const RentContainer = (props: RentProps) => {
  const ownerId = $session.actions.getUserId();
  const { data: rentHistoryQuery } = useRentHistoryQuery(ownerId!);

  let [searchParams] = useSearchParams();

  const [page, setPage] = useState(1);

  const categoryId = searchParams.get('categoryId');

  const { data: products, isLoading: isProductsLoading } = useItems({
    excludeOwnerItems: false,
    limit: 18,
    page,
  });

  const { data: categories, isLoading: isGetCategoriesLoading } =
    useCategories();

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    categoryId ?? null,
  );

  const [searchQuery, setSearchQuery] = useState<string>('');

  // Filter items based on selected category ID and seachQuery
  const filteredItems = products?.items.filter(
    (item) =>
      (selectedCategoryId
        ? item.categories[0]._id == selectedCategoryId
        : true) &&
      item.title.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()),
  );

  const isLoading = isProductsLoading || isGetCategoriesLoading;

  const debouncedOnSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 500), // 500 milliseconds debounce time
    [],
  );

  const handleSearch = useCallback(
    (value: string) => {
      debouncedOnSearch(value);
    },
    [debouncedOnSearch],
  );

  return (
    <>
      <RentView
        total={products?.total}
        totalPages={products?.totalPages}
        currentPage={page}
        setPage={setPage}
        isLoading={isLoading}
        products={products?.items}
        filteredProducts={filteredItems}
        categories={categories?.items}
        onSearch={handleSearch}
        selectedCategoryId={selectedCategoryId}
        onCategoryChange={setSelectedCategoryId}
        rentHistoryQuery={rentHistoryQuery?.items}
      />
    </>
  );
};

export default RentContainer;
