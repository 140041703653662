import { useRef } from 'react';

import { RentDetailsProps } from './RentDetails.props';

import { useMutation } from '@tanstack/react-query';
import { QUERY_KEYS, useItem } from 'api/queries';
import { rentItem } from 'api/services/rent';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import { routesSettings } from 'constants/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useRentItemStore } from 'state/atoms';
import { $itemRentDetails } from 'state/stores/rent-details';
import $session from 'state/stores/session/store';
import { RentPayload } from 'types/rent';
import { queryClient } from 'utils';
import RentDetailsView from './RentDetails.view';

const RentDetailsContainer = (props: RentDetailsProps) => {
  const productId = useParams().id!;

  const [{ startDate, endDate }] = useRentItemStore();

  const navigate = useNavigate();

  const successModalRef = useRef<CommonModalRef>(null);

  const { data } = useItem(productId);

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      const product = data;

      const renterId = $session.actions.getUserId();

      const payload: RentPayload = {
        renterId: renterId!,
        itemId: product?._id!,
        ownerId: product?.ownerId!,
        startDate: startDate!,
        endDate: endDate!,
      };

      return rentItem(payload);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.FETCH_RENT_DETAILS_KEY],
      });

      $itemRentDetails.actions.setRentCreationDate(data.createdAt);
      navigate(routesSettings.MAIN_PAYMENT.getPath(data._id), {
        replace: false,
      });
    },
  });

  const onCloseSuccessModal = () => {
    navigate(routesSettings.MAIN_RENT_PRODUCT.getPath(productId), {
      replace: false,
    });
  };

  return (
    <RentDetailsView
      onCloseSuccessModal={onCloseSuccessModal}
      successModalRef={successModalRef}
      product={data}
      isLoading={isPending}
      onClickPayment={() => mutate()}
    />
  );
};

export default RentDetailsContainer;
