import React, { FC, useState } from 'react';

import { CategorySectionViewProps } from './CategorySection.props';
import useIsMobile from 'hooks/useIsMobile';
import Card from 'components/primitives/Card';
import { Category, TopCategory } from 'types/category';
import LoadingView from 'components/primitives/Loading/Loading.view';
import Loading from 'components/primitives/Loading';
import { useNavigate } from 'react-router-dom';
import { routesSettings } from 'constants/routes';

const CategorySectionView: FC<CategorySectionViewProps> = ({
  isLoading,
  data,
}) => {
  const initialCardsToShow = 3;

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [cardsToShow, setCardsToShow] = useState(initialCardsToShow);

  const handleSeeAllClick = (allCards: any[]) => {
    if (cardsToShow === allCards.length) {
      setCardsToShow(initialCardsToShow);
    } else {
      setCardsToShow(allCards.length); // Show all cards
    }
  };

  const cardsTobeShown = (list: TopCategory[]) => {
    return isMobile ? list.slice(0, cardsToShow) : list;
  };

  const onClickCategory = (categoryId: string) => {
    navigate(`${routesSettings.RENT.getPath()}?categoryId=${categoryId}`, {
      replace: false,
    });
  };

  return (
    <div className="min-h-min w-full px-9 my-10 md:px-24 grid grid-flow-row place-items-center">
      <div className="w-fit">
        <div className="flex-between">
          <p className="font-primary text-gray-700 text-lg justify-between font-bold md:text-3xl">
            Top Categories
          </p>
          {isMobile && (
            <button onClick={() => handleSeeAllClick(data!)}>
              <h5 className="text-right text-green-900 font-normal font-secondary">
                See All
              </h5>
            </button>
          )}
        </div>
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="flex flex-wrap justify-center gap-10">
              {cardsTobeShown(data).map((topCategory, index) => {
                const item = topCategory.category;
                return (
                  <Card
                    key={`${item._id}-${index}`}
                    img={item.image}
                    title={item.name}
                    description={item.description}
                    isMobile={isMobile}
                    onCardClick={() => onClickCategory(item._id)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategorySectionView;
