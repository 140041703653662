import React, { FC, useRef } from 'react';

import { RateOwnerProps } from './RateOwner.props';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import { initialData, validationSchema } from './RateOwner.utils';
import SuccessModal from 'components/modals/SuccessModal';
import CommonModal from 'components/modals/CommonModal';
import { Form, Formik } from 'formik';
import FormikRating from 'components/form/FormikRating';
import FormikSelection from 'components/form/FormikSelection';
import FormikTextArea from 'components/form/FormikTextArea';
import Button from 'components/primitives/Button';
import ModalReference from 'helperClasses/modalReference';
import { QUERY_KEYS, userOwnerReviewRates } from 'api/queries';
import Loading from 'components/primitives/Loading';
import useRateOwnerMutation from 'api/mutations/useRateOwnerMutation';
import { ReviewPayload } from 'types/reviews';

const options = ['Polite', 'Gentle', 'Nice', 'Willing to help', 'Other'];

const RateOwnerView: FC<RateOwnerProps> = ({
  child,
  externalRef = new ModalReference(),
  hideTriggerElement = false,
  itemId,
  rentId,
  renterId,
}) => {
  const modalRef = externalRef;
  const successModalRef = new ModalReference();

  const { data: options, isLoading: isOptionsLoading } = userOwnerReviewRates();

  const showRatingDialog = () => {
    modalRef.open();
  };

  const { mutate: rate, isPending } = useRateOwnerMutation((data: any) => {
    onSuccess();
  });

  const onSubmit = (data: typeof initialData) => {
    //mutation will go here

    const renterCaracteristics: any = [];

    data.title.forEach((isChecked, index) => {
      if (isChecked) {
        renterCaracteristics.push(options![index]._id);
      }
    });

    const payload: ReviewPayload = {
      reviewerId: renterId,
      itemId: itemId,
      rentId: rentId,
      description: data.description,
      rate: data.value,
      renterCaracteristics: renterCaracteristics,
    };

    rate(payload);
  };

  const onSuccess = () => {
    modalRef.close();
    successModalRef.open();
    QUERY_KEYS.invalidate(QUERY_KEYS.FETCH_ITEM_REVIEWS);
  };

  const RatingForm = () => {
    return (
      <div className="px-8 lg:px-60 py-10 w-full flex flex-col justify-center items-center space-y-8">
        <h1 className="text-3xl">Rate the Owner</h1>
        <Formik
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            resetForm();
          }}
          initialValues={initialData}
          validationSchema={validationSchema}>
          {({ isValid }) => {
            return (
              <Form className="space-y-8 flex flex-col justify-center items-center">
                <FormikRating name="value" />
                {isOptionsLoading ? (
                  <Loading />
                ) : (
                  <FormikSelection
                    enableMultiSelect={true}
                    isLoading={isOptionsLoading}
                    name="title"
                    itemCount={options?.length ?? 0}
                    builder={(index) => {
                      return <span className="">{options![index].title}</span>;
                    }}
                  />
                )}
                <FormikTextArea
                  name="description"
                  placeholder="Here you can explain better"
                />
                <Button
                  isDisabled={!isValid}
                  variant="solid"
                  isLoading={isPending}
                  colorScheme="primary">
                  Send feedback
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  const TriggerElement = () => {
    if (hideTriggerElement) return null;
    return (
      <div className="cursor-pointer" onClick={showRatingDialog}>
        {child}
      </div>
    );
  };

  return (
    <div>
      <TriggerElement />
      <SuccessModal
        title="Feedback Sent!"
        description={`We're thrilled to have you on board. Rapid Rentals is all about making renting and lending easier and more enjoyable. Let's take you through the options you have on our platform.`}
        ref={successModalRef.getReference()}
      />
      <CommonModal ref={modalRef.getReference()}>
        <RatingForm />
      </CommonModal>
    </div>
  );
};

export default RateOwnerView;
