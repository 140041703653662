import { PaymentProps } from './Payment.props';

import { useRentPaymentDetails } from 'api/queries';
import { useParams } from 'react-router-dom';
import StripeProvider from 'state/providers/StripeProvider';
import PaymentView from './Payment.view';

const PaymentContainer = (props: PaymentProps) => {
  const { rentId } = useParams();
  const { data: rentPaymentDetails, isLoading } = useRentPaymentDetails(
    rentId!,
  );

  const orderId = rentPaymentDetails?.rentId;
  const paymentId = rentPaymentDetails?.paymentIntent;
  const ownerStripeId = rentPaymentDetails?.ownerStripeId;
  const clientSecret = rentPaymentDetails?.paymentClientSecret;

  return (
    <StripeProvider
      client_secret={clientSecret!}
      isLoading={isLoading}
      ownersStripeId={ownerStripeId!}
      orderId={orderId!}
      paymentId={paymentId!}>
      <PaymentView />
    </StripeProvider>
  );
};

export default PaymentContainer;
