import useRentHistoryQuery from 'api/queries/useRentHistoryQuery';
import { FC, useState } from 'react';
import { $session } from 'state/stores';

import { MyRentalHistoryProps } from './MyRentalHistory.props';

import MyRentalHistoryView from './MyRentalHistory.view';
import { useSearchParams } from 'react-router-dom';

const MyRentalHistoryContainer: FC<MyRentalHistoryProps> = ({
  searchQuery,
}) => {
  const ownerId = $session.actions.getUserId();

  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(parseInt(searchParams.get('page') ?? '1'));

  const { data: rentHistoryQuery, isLoading: isHistoryLoading } =
    useRentHistoryQuery(ownerId!, page, true);

  const filteredItems = rentHistoryQuery?.items.filter((rentedItem) =>
    rentedItem.item.title
      .toLocaleLowerCase()
      .includes(searchQuery.toLocaleLowerCase()),
  );

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  return (
    <section>
      <MyRentalHistoryView
        currentPage={page}
        totalPages={rentHistoryQuery?.totalPages}
        setPage={setCurrentPage}
        products={rentHistoryQuery?.items}
        isLoading={isHistoryLoading}
        filteredItems={filteredItems}
      />
    </section>
  );
};

export default MyRentalHistoryContainer;
