import { FC } from 'react';
import { Route } from 'react-router-dom';

import { routesSettings } from 'constants/routes';
import { withMotion } from 'utils';

// IMPORT ROUTE
import ProductDetails from 'routes/public/ProductDetails';

export const publicRouteConfig = {
  // AUTH ROUTE ENTRY
  AUTH_PRODUCT_DETAILS: {
    component: ProductDetails,
    path: routesSettings.AUTH_PRODUCT_DETAILS.path,
  },
} as const;

const PublicRoutes = () => {
  return Object.values(publicRouteConfig).map((route, index) => {
    return (
      <Route
        key={route.path + index}
        path={route.path}
        Component={withMotion(route.component) as FC}
      />
    );
  });
};

export default PublicRoutes;
