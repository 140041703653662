import { useQuery } from '@tanstack/react-query';
import { getRentDetailsByRentId } from 'api/services/rent';

const FETCH_RENT_DETAILS_BY_ID_KEY = 'fetch-rent-details-by-id-key';

const useRentDetailsByIdQuery = (id: string) => {
  const query = useQuery({
    queryKey: [FETCH_RENT_DETAILS_BY_ID_KEY, id],
    queryFn: () => getRentDetailsByRentId(id),
    gcTime: 0,
  });

  return query;
};

export default useRentDetailsByIdQuery;

export { FETCH_RENT_DETAILS_BY_ID_KEY };
