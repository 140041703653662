import React, { FC } from 'react';
import { TextInputProps } from './PasswordInput.props';
import { ErrorMessage, Field, FieldProps, useField } from 'formik';
import Label from '../Label';
import Eye from 'components/icons/Eye';
import EyeStriked from 'components/icons/EyeStriked';

const PasswordInputView: FC<TextInputProps> = (props) => {
  const {
    label,
    isRequired,
    name,
    disabled,
    placeholder,
    tooltipMessage,
    hidden = false,
    children,
    isReadOnly,
    hideErrorComponent = false,
    innerRef,
    className,
    onKeyUp,
  } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className={hidden ? 'hidden' : 'w-full'}>
      {label && (
        <Label
          name={name}
          isRequired={isRequired}
          toolTipMessage={tooltipMessage}
          title={label!}
        />
      )}
      <Field
        id={label}
        type={showPassword ? 'text' : 'password'}
        className={`${className} w-full`}
        name={name}
        innerRef={innerRef}
        render={({ field }: FieldProps) => (
          <div className="w-full relative items-center">
            <input
              {...field}
              onPaste={handlePaste}
              type={showPassword ? 'text' : 'password'}
              placeholder={placeholder ? placeholder : ''}
              className={`text-input input ${className}`}
              disabled={disabled || isReadOnly}
              onKeyUp={onKeyUp}
            />
            <div className="absolute top-[14px] right-[14px]">
              <button type="button" onClick={togglePasswordVisibility}>
                {showPassword ? <Eye /> : <EyeStriked />}
              </button>
            </div>
          </div>
        )}
      />
      {children}
      {!hideErrorComponent && (
        <div className={`h-5 my-1`}>
          <ErrorMessage
            component="div"
            className="text-red-500 text-sm"
            name={name}
          />
        </div>
      )}
    </div>
  );
};

export default PasswordInputView;
