import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import { useRef } from 'react';

class ModalReference {
  private ref = useRef<CommonModalRef>(null);
  open = () => {
    this.ref.current?.onOpen();
  };
  close = () => {
    this.ref.current?.onClose!();
  };
  getReference = () => this.ref;
}

export default ModalReference;
