import imageCompression from 'browser-image-compression';

class ImageHelper {
  static async compress(imageFile: File) {
    try {
      const options = {
        maxSizeMB: 0.8,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      return imageFile;
    }
  }
}

export default ImageHelper;
