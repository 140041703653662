import { forwardRef, useRef } from 'react';

import View from 'components/core/View/View.view';
import AlertModal from 'components/modals/AlertModal';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import SuccessModal from 'components/modals/SuccessModal';
import ProductReviewsSection from 'components/modules/ProductReviewsSection';
import ProductToRentView from 'components/modules/ProductToRent';
import Questions from 'components/modules/Questions';
import RentedProductDetails from 'components/modules/RentedProductDetails';
import TimeLineTracker from 'components/modules/TimeLineTracker';
import CommonContainer from 'components/primitives/CommonContainer';
import CopyToClipBoardModal from 'components/primitives/CopyToClipBoardModal';
import Loading from 'components/primitives/Loading';
import ThreeDots from 'components/primitives/ThreeDots';
import { routesSettings } from 'constants/routes';
import ModalReference from 'helperClasses/modalReference';
import useIsMobile from 'hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import useRentItemStore from 'state/atoms/rentInfoAtom';
import $itemRentDetails from 'state/stores/rent-details/store';
import DateTimeZ from 'utils/dateTime';
import { MobileHeader } from './MobileHeader';
import { RentalProductViewProps } from './RentalProduct.props';

const RentalProductView = forwardRef<CommonModalRef, RentalProductViewProps>(
  ({ product, isLoading, deleteItem }, ref) => {
    const isProductRented = $itemRentDetails.selectors.isItemRented();
    const isOwnersItem = $itemRentDetails.selectors.isOwnersItem();

    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const copyToClipBoardModalRef = new ModalReference();

    const shareLink = `${window.location.origin}/item/details/${product?._id}`;

    var [, setTempRentInfo] = useRentItemStore();

    const alertModalRef = useRef<CommonModalRef>(null);

    const handleRentClick = (payload: any) => {
      if (payload === null) return;
      const startDate: DateTimeZ = new DateTimeZ(payload[0]); // Current date
      const endDate: DateTimeZ = new DateTimeZ(payload[1]);

      setTempRentInfo({
        itemId: product._id,
        startDate: startDate.defaultPayloadStringFormat(),
        endDate: endDate.defaultPayloadStringFormat(),
      });

      navigate(
        routesSettings.MAIN_RENT_PRODUCT_RENT_DETAILS.getPath(product?._id),
        {
          replace: false,
          state: {
            startDate: startDate,
            endDate: endDate,
          },
        },
      );
    };

    const onClickEdit = () => {
      navigate(routesSettings.MAIN_EDIT_ITEM.getPath(product?._id));
    };

    if (isLoading) return <Loading />;

    return (
      <>
        {isMobile ? <MobileHeader /> : <></>}
        <View.Container>
          <div className="space-y-8">
            {isProductRented ? (
              <div className="space-y-8">
                <RentedProductDetails />
                <TimeLineTracker />
              </div>
            ) : (
              <div>
                <div className="flex justify-end px-4 py-2">
                  <ThreeDots>
                    <li>
                      <a onClick={() => copyToClipBoardModalRef.open()}>
                        Share
                      </a>
                    </li>
                    {isOwnersItem && (
                      <>
                        <li>
                          <a onClick={onClickEdit}>Edit</a>
                        </li>
                        <li>
                          <a onClick={() => alertModalRef?.current?.onOpen?.()}>
                            Delete
                          </a>
                        </li>
                      </>
                    )}
                  </ThreeDots>
                </div>
                <CommonContainer>
                  <ProductToRentView handleRentClick={handleRentClick} />
                </CommonContainer>
              </div>
            )}

            <CommonContainer>
              <ProductReviewsSection productId={product?._id} />
            </CommonContainer>

            <CommonContainer>
              <Questions product={product} />
            </CommonContainer>

            <SuccessModal ref={ref} title="Item Successfully Deleted!" />
            <AlertModal
              ref={alertModalRef}
              onClickButton={() => deleteItem(product._id)}
              title="Are you sure you want to delete the selection?"
              description="You will not be able to recover its contents. If you want to republish it you will have to create it from scratch."
              buttonTitle="Delete Selection"
              buttonIsLoading={false}
            />
          </div>
        </View.Container>
        <CopyToClipBoardModal
          value={shareLink}
          onSubmitComplete={() => {
            copyToClipBoardModalRef.close();
          }}
          ref={copyToClipBoardModalRef.getReference()}
        />
      </>
    );
  },
);

export default RentalProductView;
