import { useMutation } from '@tanstack/react-query';
import { FETCH_USER_DETAIL_KEY } from 'api/queries/useUserQuery';
import { updateUser as updateUser } from 'api/services/user';
import { UserProfile } from 'types/userProfile';
import { queryClient } from 'utils';

const UPDATE_USER_DETAIL_KEY = 'update-user-detail-key';
const useUpdateUserMutation = (
  id: string,
  onSuccess?: (response: any) => void,
) => {
  return useMutation({
    mutationKey: [UPDATE_USER_DETAIL_KEY],
    mutationFn: async (payload: Partial<UserProfile>) => {
      const response = await updateUser(id, payload);
      return { ...response, ...payload };
    },
    onSuccess: (response: any) => {
      if (onSuccess) {
        queryClient.invalidateQueries({ queryKey: [FETCH_USER_DETAIL_KEY] });
        onSuccess(response);
      }
    },
  });
};

export default useUpdateUserMutation;
