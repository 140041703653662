import { FC } from 'react';
import 'react-calendar/dist/Calendar.css';
import './';

import { ProductToRentViewProps } from './ProductToRent.props';

import 'react-datepicker/dist/react-datepicker.css';

import View from 'components/core/View/View.view';
import ImageShowcase from 'components/primitives/ImageShowcase';
import PriceTag from 'components/primitives/PriceTag';
import BadgeView from 'components/primitives/StatusBadge';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { $itemRentDetails } from 'state/stores/rent-details';
import { openUrlInNewTab } from 'utils/common';

import Attachment from 'components/icons/Attachment';
import ProductRating from '../ProductRating';
import RentForm from '../RentForm';

const ProductToRentView: FC<ProductToRentViewProps> = ({
  isRentNowDisabled = false,
  handleRentClick,
  averageRating,
  hideRating = false,
}) => {
  const product = $itemRentDetails.selectors.useProduct();
  const category = product?.categories[0];
  const subCategory = product?.subCategories[0];

  const navigate = useNavigate();

  const onOwnerClick = () => {
    navigate(
      `${routesSettings.MAIN_OWNER_PROFILE.getPath(product?.ownerId || '')}`,
    );
  };

  const state = product?.location?.state?.title ?? '';
  const city = product?.location?.title ?? '';

  return (
    <div className="relative grid w-full lg:grid-flow-col lg:grid-cols-12 grid-cols-1 min-h-[500px] gap-8">
      <PriceTag price={product?.price} />
      <div className="lg:col-span-5">
        <ImageShowcase
          featuredImage={product?.featuredPhoto ?? product?.photos[0]}
          images={product?.photos ?? []}
        />
      </div>
      <div className="lg:col-span-7 md:col-span-5 space-y-4">
        <div className="space-y-2">
          <div className="flex gap-4">
            <div
              className="max--3/5 tooltip capitalize"
              data-tip={product?.title}>
              <h1 className="truncate max-w-xs">{product?.title}</h1>
            </div>
            <View.Lender>
              <BadgeView
                value={product?.status}
                textColor="white"
                bgColor="#22CC7A"
                className="rounded-full w-24"
              />
            </View.Lender>
          </div>
        </div>
        {!hideRating && <ProductRating />}
        <div className="font-secondary flex items-center">
          <span className="text-gray-700">Posted by</span>&nbsp;
          <button onClick={onOwnerClick}>
            <span className="text-primary-500 capitalize">
              {product?.owner}
            </span>
          </button>
        </div>
        <div className="flex flex-wrap gap-4">
          <span>
            <BadgeView
              value={category?.name}
              textColor={category?.textColor}
              bgColor={category?.bgColor}
              className={'rounded'}
            />
          </span>
          <span>
            <BadgeView
              value={subCategory?.name}
              textColor={subCategory?.textColor}
              bgColor={subCategory?.bgColor}
              className={'rounded'}
            />
          </span>
        </div>
        <View.Renter>
          <RentForm
            handleRentClick={handleRentClick}
            isRentNowDisabled={isRentNowDisabled}
          />
        </View.Renter>
        <div className="flex flex-col space-y-4">
          <h1 className="font-semibold font-primary text-[20px]">
            Description
          </h1>
          <span className="font-secondary text-lg">{product?.description}</span>
          <div className="flex gap-4 text-lg">
            <div>
              <span className="font-bold">Model Number</span> :{' '}
              {product?.modelNumber}
            </div>

            <View.Lender>
              <div>
                <span className="font-bold">Serial Number</span> :{' '}
                {product?.serialNumber}
              </div>
            </View.Lender>
          </div>

          <div>
            <button
              onClick={() => {
                openUrlInNewTab(product?.link!);
              }}
              className="btn btn-sm">
              Owner's Manual
              <Attachment w="16" h="16" />
            </button>
          </div>
        </div>

        <div className="space-y-4">
          <h1 className="font-semibold font-primary text-[20px]">
            Included In Rental
          </h1>
          <div className="flex flex-wrap gap-4">
            {product?.includedInRental.map((e, index) => {
              return (
                <div
                  key={`included_${index}`}
                  className="dropdown-content menu py-2 px-4 bg-[#EDEDED] rounded-lg capitalize font-bold">
                  {e.toLocaleUpperCase()}
                </div>
              );
            })}
          </div>
        </div>
        <div className="space-y-4">
          <h1 className="font-semibold font-primary text-[20px]">Guidelines</h1>
          <p className="font-secondary text-lg">{product?.guidelines}</p>
        </div>

        <div className="space-y-4">
          <h1 className="font-semibold font-primary text-[20px]">
            Pickup/Delivery Preferences
          </h1>
          <ul>
            {[
              { key: 'pickup', title: 'Pickup' },
              { key: 'delivery', title: 'Delivery' },
              { key: 'publicMeetup', title: 'Public meeting' },
            ].map((e, index) => {
              const deliveryOption: any = product?.deliveryOptions as any;
              try {
                const isChecked = deliveryOption[e.key];
                return isChecked && <li key={`pickup_${index}`}>{e.title}</li>;
              } catch (error) {
                return null;
              }
            })}
          </ul>
        </div>

        <div className="space-y-4">
          <h1 className="font-semibold font-primary text-[20px]">Location</h1>
          <p className="font-secondary text-lg capitalize">
            {city + (city ? ', ' : '')} {state}
          </p>
        </div>

        <div>
          <a
            href={routesSettings.MAIN_TERMS_AND_CONDITIONS.path}
            target="_blank">
            See <span className="text-[#3769CC]">terms and conditions</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductToRentView;
