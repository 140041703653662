import React, { FC, useCallback, useRef, useState } from 'react';

import { FileInputViewProps } from './FileInput.props';
import UploadIcon from 'components/icons/UploadIcon';
import { ErrorMessage, useField } from 'formik';
import DeleteIcon from 'components/icons/DeleteIcon';
import Label from '../Label';
import { getNetworkImage } from 'utils/assets';

const FileInputView: FC<FileInputViewProps> = (props) => {
  const { labelProps, label, isRequired, name, hint } = props;

  const [field, _, helpers] = useField(name);

  const imagesSelected = () => field.value.length > 0;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = useCallback(
    (_prevFiles: File[]) => (e: any) => {
      if (e.target.files && e.target.files.length > 0) {
        const files = e.target.files;
        helpers.setValue([...files, ..._prevFiles]);
      }
    },
    [helpers],
  );

  const deleteImage = (fileData: File) => {
    const currentPhotos = field.value || [];
    const fileIndex = currentPhotos.findIndex(
      (file: { name: string }) => file.name === fileData.name,
    );

    if (fileIndex !== -1) {
      const updatedPhotos = [
        ...currentPhotos.slice(0, fileIndex),
        ...currentPhotos.slice(fileIndex + 1),
      ];

      helpers.setValue(updatedPhotos);
    }
  };

  const onInputClick = useCallback(() => {
    inputRef?.current?.click();
  }, []);

  return (
    <>
      <div className={`w-full text-gray-700 ${labelProps}`}>
        <Label name={name} isRequired title={label!} />
        <div className="w-full">
          <label
            htmlFor="dropzone-file"
            className={`w-full flex justify-center items-start bg-purple-100 rounded-lg border border-secondary-900 border-dashed ${imagesSelected() ? 'hidden' : 'cursor-pointer'} `}>
            {!imagesSelected() && (
              <div className="flex flex-col justify-center items-center gap-2 py-20">
                <UploadIcon fill={'#682082'} />
                <div className="text-secondary-900 text-base font-semibold font-primary">
                  Upload your pictures (min 3)
                </div>
                <span className="text-center text-xs">{hint}</span>
              </div>
            )}
            <input
              id="dropzone-file"
              type="file"
              key="files"
              ref={inputRef}
              className="hidden"
              multiple
              onChange={handleFileChange(field.value)}
            />
          </label>

          {field.value &&
            Array.isArray(field.value) &&
            field.value.length > 0 && (
              <div className="flex flex-wrap gap-4 pt-2">
                <div
                  style={{
                    height: '91px',
                    width: '91px',
                  }}
                  className="w-full flex-col h-14  bg-purple-100 rounded-lg border border-secondary-900 border-dashed flex justify-center items-center cursor-pointer"
                  onClick={() => inputRef?.current?.click()}>
                  <UploadIcon fill={'#682082'} />
                  <div className="text-secondary-900 text-center text-xs font-semibold font-primary pt-1">
                    Upload your pictures
                  </div>
                </div>
                {field.value.map((file: any, index: number) => {
                  return (
                    <div key={index} className="relative">
                      <img
                        style={{
                          height: '91px',
                          width: '91px',
                        }}
                        className="rounded object-cover"
                        alt=""
                        src={
                          typeof file == 'string'
                            ? getNetworkImage(file)
                            : URL.createObjectURL(file)
                        }
                      />
                      <button
                        type="button"
                        className="absolute top-1 right-1"
                        onClick={() => deleteImage(file)}>
                        <DeleteIcon h="30px" w="30px" />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          <div className="h-6 mt-2">
            <ErrorMessage
              component="div"
              className="text-red-500 text-sm"
              name={name}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FileInputView;
