import { Tokens } from 'types/auth';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
  id: string | null;
  isAuth: boolean;
  userType: 'owner' | 'customer' | null;
  tokens: null | Tokens;
  username: string | null;
  isAuthenticatedBySiteBlocker: boolean;
};

const STORAGE_KEY = 'RAPID-RENTALS-SESSION';

const useSessionStore = create(
  persist(
    immer<State>(() => ({
      id: null,
      isAuth: false,
      tokens: null,
      username: null,
      userType: null,
      isAuthenticatedBySiteBlocker: false,
    })),
    {
      name: STORAGE_KEY,
    },
  ),
);

const selectors = {
  useIsAuth: () => useSessionStore((state) => state.isAuth),
  useUserType: () => useSessionStore((state) => state.userType),
  useIsAuthenticatedBySiteBlocker: () =>
    useSessionStore((state) => {
      return state.isAuthenticatedBySiteBlocker;
    }),
};

const actions = {
  set: useSessionStore.setState,
  setIsAuthenticatedBySiteBlocker: () => {
    const set = useSessionStore.setState;
    set((state) => {
      state.isAuthenticatedBySiteBlocker = true;
    });
  },
  setSession: ({
    id,
    tokens,
    username,
    isAuth = true,
  }: {
    id: string;
    tokens: Tokens;
    username: string;
    isAuth?: boolean;
  }) => {
    const set = useSessionStore.setState;
    set((state) => {
      state.id = id;
      state.tokens = tokens;
      state.username = username;
      state.isAuth = isAuth;
    });
  },
  setAuth: () => {
    const set = useSessionStore.setState;
    set((state) => {
      state.isAuth = true;
    });
  },
  connectStripe: () => {
    // temp connect stripe. replace when API is ready
    actions.set((state) => {
      state.userType = 'owner';
    });
  },
  clearSession: () => {
    const set = useSessionStore.setState;
    set((state) => {
      state.isAuth = false;
      state.tokens = null;
      state.username = null;
      state.userType = null;
      state.id = null;
    });
  },
  // getters
  getTokens: () => useSessionStore.getState().tokens,
  getToken: () => useSessionStore.getState().tokens?.jwtToken,
  getUsername: () => useSessionStore.getState().username,
  getUserId: () => useSessionStore.getState().id,
};

/**
 * **Session Store**
 *
 * this store is used to store session tokens
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $session = {
  actions,
  selectors,
} as const;

export default $session;
