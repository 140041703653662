import { useQuery } from '@tanstack/react-query';
import { fetchItemsByOwnerId } from 'api/services/item';

const FETCH_OWNER_ITEMS_KEY = 'fetch-owner-items-key';

export type OwnerItemOptions = {
  page?: number;
  limit?: number;
};

const useOwnerItemQuery = (id: string, options?: OwnerItemOptions) => {
  const query = useQuery({
    queryKey: [FETCH_OWNER_ITEMS_KEY, id, options?.page],
    queryFn: () => fetchItemsByOwnerId(id, options),
  });

  return query;
};

export default useOwnerItemQuery;

export { FETCH_OWNER_ITEMS_KEY };
