import React, { FC } from 'react';
import LoadingView from '../Loading/Loading.view';

import { AvatarProps } from './Avatar.props';

const AvatarView: FC<AvatarProps> = ({
  className,
  imgSrc,
  firstLetter,
  isLoading,
  noBorder,
  children,
}) => {
  const PlaceHolder = () => {
    if (isLoading) return <LoadingView />;
    return imgSrc ? (
      <img src={imgSrc} />
    ) : (
      <span className="text-3xl">{firstLetter}</span>
    );
  };

  return (
    <>
      <div
        className={`avatar ${className} placeholder ring ring-primary-500 rounded-full`}>
        <div
          className={`${className} bg-primary-300 text-white rounded-full w-24 capitalize font-bold`}>
          <PlaceHolder />
        </div>
        {children}
      </div>
    </>
  );
};

export default AvatarView;
