import imagePlaceHolder from 'assets/images/image_placeholder.webp';
import coverImage from 'assets/images/cover.jpg';
import defaultAvatar from 'assets/images/default-avatar.jpeg';
import taxAndServices from 'assets/images/tax_png.png';
import stripeLogo from 'assets/images/stripelogo.png';
import stripeLogoWhite from 'assets/images/stripeLogoWhite.png';

export default {
  placeholderImage: imagePlaceHolder,
  coverImage,
  defaultAvatar,
  taxAndServices,
  stripeLogo,
  stripeLogoWhite,
};
