import React from 'react';
import { IconProps } from './type';

const WingsShieldIcon = (props: IconProps) => {
  const { fill, w, h } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${w ? w : '37'}`}
      height={`${h ? h : '37'}`}
      fill="none"
      viewBox="0 0 64 64">
      <g>
        <g>
          <path
            fill="#D9D9D9"
            d="M19 30H6.123a4 4 0 01-3.88-3.03L1 22h18v8z"></path>
          <path
            fill="#D9D9D9"
            d="M8.123 27a4 4 0 01-3.88-3.03L3.75 22H1l1.243 4.97A4 4 0 006.123 30H19v-3H8.123z"></path>
          <path
            fill="#D9D9D9"
            d="M23 38H10.123a4 4 0 01-3.88-3.03L5 30h18v8z"></path>
          <path
            fill="#D9D9D9"
            d="M12.123 35a4 4 0 01-3.88-3.03L7.75 30H5l1.243 4.97a4 4 0 003.88 3.03H23v-3H12.123z"></path>
          <path
            fill="#D9D9D9"
            d="M27 46H14.123a4 4 0 01-3.88-3.03L9 38h18v8z"></path>
          <path
            fill="#D9D9D9"
            d="M16.123 43a4 4 0 01-3.88-3.03L11.75 38H9l1.243 4.97a4 4 0 003.88 3.03H27v-3H16.123z"></path>
          <path
            fill="#D9D9D9"
            d="M45 30h12.877a4 4 0 003.88-3.03L63 22H45v8z"></path>
          <path
            fill="#D9D9D9"
            d="M59.877 27H54.76a1.99 1.99 0 01-1.984-2.188c.096-.933.159-1.87.188-2.812H45v8h12.877a4 4 0 003.88-3.03l.131-.521c-.61.359-1.304.55-2.011.551z"></path>
          <path
            fill="#D9D9D9"
            d="M41 38h12.877a4 4 0 003.88-3.03L59 30H41v8z"></path>
          <path
            fill="#D9D9D9"
            d="M55.877 35h-2.706a1.991 1.991 0 01-1.917-2.565c.25-.8.473-1.612.668-2.435H41v8h12.877a4 4 0 003.88-3.03l.131-.521c-.61.359-1.304.55-2.011.551z"></path>
          <path
            fill="#D9D9D9"
            d="M37 46h12.877a4 4 0 003.88-3.03L55 38H37v8z"></path>
          <path
            fill="#D9D9D9"
            d="M51.877 43h-2.093a1.994 1.994 0 01-1.746-2.961c.37-.667.721-1.346 1.052-2.039H37v8h12.877a4 4 0 003.88-3.03l.131-.521c-.61.359-1.304.55-2.011.551z"></path>
          <path
            fill="#22CC7A"
            d="M32 9l19 7v4.788a40 40 0 01-16.272 32.2L32 55l-2.728-2.01A40 40 0 0113 20.788V16l19-7z"></path>
          <path
            fill="#fff"
            d="M16 21.063V18l16-6 16 6v3.063a36 36 0 01-15.6 29.665L32 51l-.395-.272A36.001 36.001 0 0116 21.063z"></path>
          <path
            fill="#22CC7A"
            d="M32 21l2 7h7l-5 4 2 7-6-4.292L26 39l2-7-5-4h7l2-7z"></path>
          <path
            fill="#682082"
            d="M48.352 17.062l-16-6a1.014 1.014 0 00-.7 0l-16 6A1 1 0 0015 18v3.062a36.993 36.993 0 0016.038 30.491l.4.271a1 1 0 001.132 0l.4-.27A36.994 36.994 0 0049 21.06V18a1 1 0 00-.648-.937zm-1.352 4a34.992 34.992 0 01-15 28.723 34.994 34.994 0 01-15-28.724v-2.369l15-5.625 15 5.625v2.37z"></path>
          <path
            fill="#682082"
            d="M31.038 20.727L29.247 27H23a1 1 0 00-.625 1.78l4.48 3.585-1.817 6.361a1 1 0 001.544 1.087l5.419-3.875 5.418 3.875a.999.999 0 001.544-1.087l-1.818-6.361 4.48-3.584A1 1 0 0041.002 27h-6.246l-1.793-6.274a1 1 0 00-1.924 0zm2 7.548a1 1 0 00.963.726h4.149l-2.774 2.219a.999.999 0 00-.337 1.055l1.212 4.246-3.668-2.62a1 1 0 00-1.164 0l-3.67 2.62 1.213-4.246a1 1 0 00-.337-1.055L25.852 29H30a1 1 0 00.962-.725l1.039-3.634 1.038 3.634z"></path>
          <path
            fill="#682082"
            d="M63.788 21.385A1 1 0 0063 21H51.994c0-.07.006-.14.006-.212V16a1 1 0 00-.654-.938l-19-7a.993.993 0 00-.692 0l-19 7A1 1 0 0012 16v4.788c0 .071.006.141.006.212H1a1.001 1.001 0 00-.97 1.242l1.242 4.97A4.977 4.977 0 004.11 30.56l1.162 4.652A4.977 4.977 0 008.11 38.56l1.162 4.652A4.993 4.993 0 0014.122 47H21.5a40.741 40.741 0 007.183 6.8l2.727 2.01a1 1 0 001.186 0l2.724-2.01A40.738 40.738 0 0042.5 47h7.374a4.993 4.993 0 004.85-3.788l1.166-4.652a4.977 4.977 0 002.838-3.348l1.162-4.652a4.977 4.977 0 002.838-3.348l1.242-4.97a1 1 0 00-.182-.857zM3.212 26.728L2.28 23h9.786c.114 2.017.378 4.023.79 6H6.123a3 3 0 01-2.911-2.272zm4 8L6.28 31h7.038a41.306 41.306 0 002.047 6h-5.242a3 3 0 01-2.911-2.272zM14.122 45a3 3 0 01-2.91-2.272L10.28 39h6.02a41.218 41.218 0 003.647 6h-5.824zm20.013 7.186L32 53.758l-2.135-1.572A39.152 39.152 0 0114 20.788V16.7l18-6.632L50 16.7v4.091a39.152 39.152 0 01-15.865 31.395zm18.653-9.458A3 3 0 0149.877 45h-5.82a41.218 41.218 0 003.643-6h6.02l-.932 3.728zm4-8A3 3 0 0153.877 37h-5.242a41.294 41.294 0 002.047-6h7.038l-.932 3.728zm4-8A3 3 0 0157.877 29h-6.733c.412-1.977.676-3.983.79-6h9.786l-.932 3.728z"></path>
        </g>
      </g>
    </svg>
  );
};

export default WingsShieldIcon;
