import React, { FC, useEffect, useState } from 'react';
import { PassworkValidatingStatusProps } from './PassworkValidatingStatus.props';
import { useField, useFormikContext } from 'formik';
import { initialValues } from 'routes/auth/Login/Login.utils';
import Checked from 'components/icons/Checked';
import Cancel from 'components/icons/Cancel';

const PassworkValidatingStatusView: FC<PassworkValidatingStatusProps> = ({
  hide = false,
}) => {
  const formik = useFormikContext<typeof initialValues>();
  const [validationResults, setValidationResults] = useState({
    hasValidLength: false,
    hasBothCases: false,
    hasNumber: false,
    hasSpecialCharacter: false,
  });

  useEffect(() => {
    const password = formik.values.password;
    const newValidationResults = {
      hasValidLength: password.length >= 8 && password.length <= 30,
      hasBothCases: /[a-z]/.test(password) && /[A-Z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecialCharacter: /[-!@#$%^&*()+]/.test(password),
    };
    setValidationResults(newValidationResults);
  }, [formik.values.password]);

  const getCheckedIcon = (status: boolean) => {
    return status ? <Checked w="18" h="18" /> : <Cancel w="18" h="18" />;
  };

  return (
    <>
      {hide ? (
        <div>
          <div className="space-y-2 mt-2 mb-2">
            <div className="flex justify-start item-center gap-2">
              {getCheckedIcon(validationResults.hasValidLength)}
              <span className="text-xs">Contain 8 to 30 characters</span>
            </div>
            <div className="flex justify-start item-center gap-2">
              {getCheckedIcon(validationResults.hasBothCases)}
              <span className="text-xs">
                Contain both lower and uppercase letters{' '}
              </span>
            </div>
            <div className="flex justify-start item-center gap-2">
              {getCheckedIcon(validationResults.hasNumber)}
              <span className="text-xs">Contain 1 number</span>
            </div>
            <div className="flex justify-start item-center gap-2">
              {getCheckedIcon(validationResults.hasSpecialCharacter)}
              <span className="text-xs">
                Contain 1 special character '-!@#$%^&*()+'{' '}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-8"></div>
      )}
    </>
  );
};

export default PassworkValidatingStatusView;
