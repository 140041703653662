import { useQuery } from '@tanstack/react-query';
import { getRentPaymentDetails } from 'api/services/rent';

const FETCH_RENT_PAYMENT_DETAILS_KEY = 'fetch-rent-payment-details-key';

const useRentPaymentDetailsQuery = (rentId: string) => {
  // api data should not be cached
  const query = useQuery({
    queryKey: [FETCH_RENT_PAYMENT_DETAILS_KEY],
    queryFn: () => getRentPaymentDetails(rentId),
    staleTime: 0, // Data is always considered stale
    gcTime: 0,
  });

  return query;
};

export default useRentPaymentDetailsQuery;

export { FETCH_RENT_PAYMENT_DETAILS_KEY };
