import React from 'react';

function HomeOutlineIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20">
      <mask
        id="mask0_2610_101108"
        style={{ maskType: 'alpha' }}
        width="21"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse">
        <path fill="#515151" d="M0.438 0H20.438V20H0.438z"></path>
      </mask>
      <g mask="url(#mask0_2610_101108)">
        <path
          fill="#515151"
          d="M5.448 15.833h2.5v-5h5v5h2.5v-7.5l-5-3.75-5 3.75v7.5zM3.78 17.5v-10l6.667-5 6.667 5v10H11.28v-5H9.615v5H3.78z"></path>
      </g>
    </svg>
  );
}

export default HomeOutlineIcon;
