import React from 'react';
type RentIconProps = {
  fill?: string | undefined;
};

const RentIcon = (props: RentIconProps) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      fill="none"
      viewBox="0 0 22 20">
      <path
        fill={fill ? fill : '#A6A6A6'}
        d="M3 20a.967.967 0 01-.713-.288A.968.968 0 012 19c0-.283.095-.52.287-.712A.967.967 0 013 18h6c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.967.967 0 019 20H3zm13-1c-.55 0-1.021-.196-1.413-.587A1.926 1.926 0 0114 17h-3a.968.968 0 01-.713-.288A.968.968 0 0110 16c-.034-1.5.433-2.8 1.4-3.9.966-1.1 2.166-1.767 3.6-2V1c0-.283.095-.52.287-.713A.968.968 0 0116 0c.283 0 .52.096.712.287.192.192.288.43.288.713v9.1c1.45.233 2.654.9 3.612 2 .958 1.1 1.42 2.4 1.388 3.9 0 .283-.096.52-.288.712A.968.968 0 0121 17h-3c0 .55-.192 1.02-.575 1.413A1.914 1.914 0 0116 19zM6 17a.967.967 0 01-.713-.288A.968.968 0 015 16V9H1a.927.927 0 01-.788-.387.966.966 0 01-.162-.863l1.875-7c.066-.217.187-.396.362-.537A.945.945 0 012.9 0h6.225c.233 0 .437.07.612.212s.296.321.363.538l1.875 7a.966.966 0 01-.163.863.928.928 0 01-.787.387H7v7c0 .283-.096.52-.288.712A.967.967 0 016 17z"></path>
    </svg>
  );
};

export default RentIcon;
