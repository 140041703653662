import React from 'react';

const ArrowDropdownIcon = ({ isDropdownOpen }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
      className={`h-5 w-5 ml-2 transform ${isDropdownOpen ? 'rotate-180' : ''}`}>
      <path d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 011.414 1.414l-4 4A1 1 0 0110 12z"></path>
    </svg>
  );
};

export default ArrowDropdownIcon;
