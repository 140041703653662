import { useQuery } from '@tanstack/react-query';
import { getRentDetails } from 'api/services/rent';

const FETCH_RENT_DETAILS_KEY = 'fetch-rent-details-key';

const useRentDetailsQuery = (id: string, date: string) => {
  const query = useQuery({
    queryKey: [FETCH_RENT_DETAILS_KEY, id],
    queryFn: () => getRentDetails(id, date),
    gcTime: 0,
  });
  return query;
};

export default useRentDetailsQuery;

export { FETCH_RENT_DETAILS_KEY };
