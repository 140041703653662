import React, { useEffect } from 'react';
import { FC } from 'react';
import NavbarLogo from 'components/primitives/NavbarLogo';
import useIsMobile from 'hooks/useIsMobile';
import { routesSettings } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import Button from 'components/primitives/Button';
import { useHeaderContext } from 'state/contexts/headerContext';
import BetaFlag from 'components/icons/BetaFlag';

const LoginHeader: FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { isAuthButtonVisible } = useHeaderContext();

  const onClickSignUp = () => {
    navigate(routesSettings.AUTH_SIGNUP.path, {
      replace: true,
    });
  };

  const onClickSignIn = () => {
    navigate(routesSettings.AUTH_LOGIN.path, {
      replace: true,
    });
  };

  const isBeta = process.env.REACT_APP_IS_BETA;

  return (
    <header className="h-16 border p-2 shadow-lg navbar px-24">
      <div className="navbar nav-pills flex justify-between relative">
        <div className="mr-4">
          <NavbarLogo />
        </div>
        {isBeta && (
          <div className="absolute bottom-[11px] -left-[1px]">
            <BetaFlag />
          </div>
        )}
        <ul className="grid-flow-col gap-10 xl:gap-16 lg:gap-10 hidden lg:flex">
          {/* <li>
            <h5 className="text-gray-700">About us</h5>
          </li>
          <li>
            <h5>How it works</h5>
          </li>
          <li>
            <h5>Protection</h5>
          </li>
          <li>
            <h5>Help</h5>
          </li> */}
        </ul>
        {isMobile ? null : !isAuthButtonVisible ? null : (
          <div className="gap-4">
            <Button
              variant="outline"
              colorScheme="primary"
              onClick={onClickSignUp}
              className="w-32">
              Sign Up
            </Button>
            <Button
              variant="solid"
              colorScheme="primary"
              onClick={onClickSignIn}
              className="w-32">
              Login
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};

export default LoginHeader;
