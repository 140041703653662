import React from 'react';

type EditIconProps = {
  w?: string;
  h?: string;
  fill?: string;
  children?: any;
};

function EditIcon({ w, h, fill, children }: EditIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w ? w : '189'}
      height={h ? h : '80'}
      fill="none"
      viewBox="0 0 24 24">
      <mask
        id="mask0_2610_101120"
        style={{ maskType: 'alpha' }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse">
        <path fill={fill ? fill : `#22CC7A`} d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_2610_101120)">
        <path
          fill="#682082"
          d="M5 19h1.425L16.2 9.225 14.775 7.8 5 17.575V19zm-2 2v-4.25L16.2 3.575c.2-.183.42-.325.663-.425.241-.1.495-.15.762-.15s.525.05.775.15c.25.1.467.25.65.45L20.425 5c.2.183.346.4.438.65a2.165 2.165 0 010 1.512 1.874 1.874 0 01-.438.663L7.25 21H3zM15.475 8.525l-.7-.725L16.2 9.225l-.725-.7z">
          {children}
        </path>
      </g>
    </svg>
  );
}

export default EditIcon;
