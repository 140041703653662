import { useUser, useUserOverallRating } from 'api/queries';
import useUserProfile from 'api/queries/useUserProfile';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import { useEffect, useRef, useState } from 'react';

import { $session } from 'state/stores';
import { $user } from 'state/stores/user';
import ProfileTabContent from './ProfileTabContent';

const ProfileTabContentContainer = () => {
  const userId = $session.actions.getUserId();
  const { updateUser, isLoading: isUserLoading } = useUser(userId!);

  const [isEditing, setIsEditing] = useState(false);

  const user = $user.selectors.useUser();

  const [showModal, setShowModal] = useState(false);
  const [userPhone, setUserPhone] = useState('');

  const successModalRef = useRef<CommonModalRef>(null);

  const { data: userRatings } = useUserOverallRating(userId!);

  const { profileQuery, updateProfile, isLoading, isUpdatingProfile } =
    useUserProfile({
      userId,
      onUserProfileUpdateSuccess: () => {
        if (!isUpdatingProfile || !isLoading || !isUserLoading) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            setIsEditing(false);
          }, 3000);
        }

        //close modal after three seconds
      },
    });

  const userProfile = profileQuery;

  const userLocation = $user.selectors.useLocation();

  const initialValues = {
    displayName:
      user?.fname && user?.lname ? `${user.fname} ${user.lname}` : '',
    phoneNumber: userPhone || '',
    email: user?.email || '',
    cityZipCode: userProfile?.cityZipCode || '',
    city: userProfile?.city || '',
    about: userProfile?.about || '',
    avatar: userProfile?.avatar || null,
    address: userLocation.cityId || '',
    state: userLocation.stateId || '',
  };

  useEffect(() => {
    setUserPhone(user?.phone || '');
  }, [user?.phone]);

  const onProfileUpdate = (e: any) => {
    if (!user) return console.log('no user');
    const payload = { ...e, _id: user.profileId };

    const { _id, ...updatedUser } = {
      ...user,
      phone: e.phoneNumber,
    };

    updateProfile(payload);

    updateUser(updatedUser);

    setUserPhone(e.phoneNumber);
  };

  return (
    <ProfileTabContent
      onProfileUpdate={onProfileUpdate}
      isEditing={isEditing}
      isLoading={isLoading}
      userRatings={userRatings!}
      isUpdatingProfile={isUpdatingProfile}
      isUserLoading={isUserLoading}
      user={user!}
      userProfile={userProfile}
      initialValues={initialValues}
      successModalRef={successModalRef}
      showModal={showModal}
      setShowModal={setShowModal}
      toggleEditMode={() => setIsEditing((prev) => !prev)}
    />
  );
};

export default ProfileTabContentContainer;
