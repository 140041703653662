import React, { FC } from 'react';

import { TooltipProps } from './Tooltip.props';

const TooltipView: FC<TooltipProps> = (props) => {
  const { message, children } = props;
  return (
    <div className="relative group">
      {children}
      {message && (
        <div className="absolute bottom-full left-1/2 text-center transform -translate-x-1/2 p-3 w-48 bg-primary-50 text-gray-700 font-secondary text-xs rounded-md invisible opacity-0 transition-opacity duration-300 group-hover:visible group-hover:opacity-100 cursor-pointer">
          {message}
        </div>
      )}
    </div>
  );
};

export default TooltipView;
