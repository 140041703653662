import React, { FC, useEffect, useRef } from 'react';

import { ConnectedAccount } from 'types/stripe';
import { StripeHandlerProps } from './StripeHandler.props';
import { $user } from 'state/stores/user';
import { useQuery } from '@tanstack/react-query';
import { getStripeAccount } from 'api/services/stripe';
import { useParams } from 'react-router-dom';
import { $stripe } from 'state/stores/stripe';
import { $session } from 'state/stores';

const checkIfDetaialsSubmitted = (c: ConnectedAccount) => {
  if (c?.details_submitted) {
    return true;
  } else if (c?.tos_acceptance) {
    if ('date' in c?.tos_acceptance && c?.tos_acceptance?.date !== null) {
      return true;
    }
  } else {
    return false;
  }

  return false;
};

const StripeHandlerContainer: FC<StripeHandlerProps> = ({ children }) => {
  // const history;
  const stripeId = $user.selectors.useStripeId();

  const refetchCountRef = useRef(0);

  const { fromStripe } = useParams();

  const queryKey = [`fetch-stripe-account`, stripeId];

  const queryFn = async (): Promise<ConnectedAccount> => {
    $stripe.actions.setFetchingDataStatus(true);
    const account = await getStripeAccount(String(stripeId));

    const _isAccountEnabled = Boolean(account?.charges_enabled);
    const _isDetailsSubmitted = checkIfDetaialsSubmitted(account);

    if (_isAccountEnabled && _isDetailsSubmitted) {
      $stripe.actions.setFetchingDataStatus(false);
      return Promise.resolve(account);
    }

    $stripe.actions.setFetchingDataStatus(false);
    return account;
  };

  const { data: account } = useQuery({
    queryKey,
    queryFn,
  });

  if (account && Boolean(account)) {
    $stripe.actions.setAccountData(account);
  }

  useEffect(() => {
    const onStripeRedirect = async () => {
      // history.replace({ search: '' });
    };

    if (fromStripe !== 'true') {
      return;
    }

    onStripeRedirect();
  }, []);

  return <>{children}</>;
};

export default StripeHandlerContainer;
