import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  hideHeader: boolean;
};

const useSettingStore = create(
  immer<State>(() => ({
    hideHeader: false,
  })),
);

const selectors = {
  useHeaderHidden: () => useSettingStore((state) => state.hideHeader),
};

const actions = {
  hideHeader: () => {
    const set = useSettingStore.setState;
    set((state) => {
      state.hideHeader = true;
    });
  },
  unhideHeader: () => {
    const set = useSettingStore.setState;
    set((state) => {
      state.hideHeader = false;
    });
  },
};

/**
 * **Setting Store**
 *
 * this store is used to store setting data
 *
 * **"$"** is a convention to indicate that this is a store
 */
const $setting = {
  actions,
  selectors,
};

export default $setting;
