import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const DEFAULT_VALUE = {
  client_secret: null as string | null,
  start_date: null as string | null,
  endDate: null as string | null,
};

const storage = createJSONStorage<any>(() => localStorage);

const checkoutInfoAtom = atomWithStorage<typeof DEFAULT_VALUE>(
  'checkout-store',
  DEFAULT_VALUE,
  storage,
);

const useCheckoutInfoStore = () => {
  const atom = useAtom(checkoutInfoAtom);

  return atom;
};

export default useCheckoutInfoStore;
