import Avatar from 'components/primitives/Avatar';
import CommonContainer from 'components/primitives/CommonContainer';
import { FC } from 'react';

import FormikRating from 'components/form/FormikRating';
import Card from 'components/primitives/Card';
import ListCard from 'components/primitives/ListCard';
import NoCategoryItemsFound from 'components/vectors/NoCategoryItemsFound';
import NoItemsFound from 'components/vectors/NoItemsFound';
import useIsMobile from 'hooks/useIsMobile';
import { Product } from 'types/item';
import { getNetworkImage } from 'utils/assets';
import { OwnerProfileViewProps } from './OwnerProfile.props';

const OwnerProfileView: FC<OwnerProfileViewProps> = ({
  owner,
  products,
  filteredItems,
  categories,
  selectedCategoryId,
  onSearch,
  onCategoryChange,
  onCardClick,
  userRatings,
}) => {
  const isMobile = useIsMobile();
  const handleCategoryClick = (categoryId: string) => {
    if (onCategoryChange) {
      onCategoryChange(categoryId);
    }
  };

  const categoryCount = new Map();

  const userRating =
    userRatings?.ownerRatingDetails ??
    0 + userRatings?.renterRatingDetails! ??
    0;

  products?.forEach((e) => {
    const categoryId = `${e.categories[0]._id}`;
    if (categoryCount.has(categoryId)) {
      const freq = categoryCount.get(categoryId);
      categoryCount.set(categoryId, freq + 1);
    } else {
      categoryCount.set(categoryId, 1);
    }
  });

  const getCategoryCount = (id: string) =>
    categoryCount.has(id) ? categoryCount.get(id) : 0;

  return (
    <div className="mx-24 my-12 space-y-10">
      <CommonContainer className="flex items-between justify-center">
        <div className="flex md:flex-row w-full flex-col gap-2 justify-between items-start">
          <div className="flex">
            <Avatar imgSrc={getNetworkImage(owner?.avatar)} className="w-28" />
            <div className="flex flex-col items-start text-left mx-3">
              <div className="text-gray-700 text-2xl ml-1 font-medium font-tertiary leading-7 tracking-wide capitalize">
                {owner?.displayName}
              </div>
              <div className="opacity-60 ml-1 text-neutral-700 text-sm font-light font-secondary">
                Member since {owner?.createdAt.slice(0, 4)}
              </div>
              <FormikRating.RatingWidget
                size="22"
                value={userRating != null ? Math.round(userRating / 2) : 0}
              />
            </div>
          </div>
          <div className="flex items-start w-full text-neutral-500 font-secondary text-start text-lg ml-10 ">
            <span>{owner?.about}</span>
          </div>
        </div>
      </CommonContainer>
      <div>
        <span className="font-bold font-primary text-neutral-700 text-2xl">
          Available Items
        </span>
        <form className="w-full flex py-5">
          <label className="flex w-full justify-center items-center">
            <input
              onChange={(e) => onSearch && onSearch(e.target.value)}
              className="input w-1/2 border border-gray-300"
              placeholder="Search"
            />
          </label>
        </form>
        <section className="flex flex-row flex-wrap justify-center gap-4 px-9 md:px-24 cursor-pointer overflow-x-auto mt-12">
          <div
            key={`category-all`}
            onClick={() => handleCategoryClick('')}
            className={`badge badge-primary-100 ${selectedCategoryId == '' ? 'bg-primary-300 text-primary-900' : 'bg-primary-50 text-primary-900'} p-3 text-sm border-none font-primary font-bold capitalize`}>
            All ({products?.length})
          </div>
          {categories?.map((category, index) => {
            const isCategorySelected = selectedCategoryId === category._id;
            const colorSchemeBasedOnSelection = isCategorySelected
              ? 'bg-primary-300 text-primary-900'
              : 'bg-primary-50 text-primary-900';
            const fileteredById = filteredItems?.filter(
              (item) => item.categories[0]._id === category._id,
            );

            return (
              <div
                key={`category-${category._id}-${index}`}
                onClick={() => handleCategoryClick(category._id)}
                className={`badge badge-primary-100 p-3 ${colorSchemeBasedOnSelection} text-sm border-none font-primary font-bold capitalize`}>
                {category.name} ({getCategoryCount(category._id)}){' '}
              </div>
            );
          })}
        </section>
        <ListCard
          itemNotFoundPlaceholder={
            selectedCategoryId ? (
              <NoCategoryItemsFound w="95%" />
            ) : (
              <NoItemsFound w="95%" />
            )
          }>
          {filteredItems?.map((product: Product, index: any) => (
            <Card
              key={`${product.title}-${index}`}
              onCardClick={() => onCardClick(product._id)}
              isMobile={isMobile}
              img={getNetworkImage(product.featuredPhoto ?? product.photos[0])}
              title={product.title}
              id={product._id}
              description={product.description}
            />
          ))}
        </ListCard>
      </div>
    </div>
  );
};

export default OwnerProfileView;
