import { FC, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import LoginHeader from 'components/core/LoginHeader';

import { routesSettings } from 'constants/routes';
import { withMotion } from 'utils';

import { match } from 'path-to-regexp';

// IMPORT ROUTE
import AccountCreated from 'routes/auth/AccountCreated';
import ConfirmIdentity from 'routes/auth/ConfirmIdentity';
import Login from 'routes/auth/Login';
import Signup from 'routes/auth/Signup';
import PublicRoutes, { publicRouteConfig } from 'routes/public';

const routeConfig = {
  // AUTH ROUTE ENTRY
  AUTH_ACCOUNT_CREATED: {
    component: AccountCreated,
    path: routesSettings.AUTH_ACCOUNT_CREATED.path,
  },
  AUTH_CONFIRM_IDENTITY: {
    component: ConfirmIdentity,
    path: routesSettings.AUTH_CONFIRM_IDENTITY.path,
  },
  AUTH_SIGNUP: {
    component: Signup,
    path: routesSettings.AUTH_SIGNUP.path,
  },
  AUTH_LOGIN: {
    component: Login,
    path: routesSettings.AUTH_LOGIN.path,
  },
} as const;

const AuthRoutes: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const publicRoutes = publicRouteConfig;

  useEffect(() => {
    const authPaths: string[] = Object.values(routeConfig).map((v) => v.path);
    const publicRoutePaths: string[] = Object.values(publicRoutes).map(
      (v) => v.path,
    );
    const isRouteMatched = [...authPaths, ...publicRoutePaths].some((path) => {
      const matchFunction = match(path, { decode: decodeURIComponent });
      return matchFunction(location.pathname);
    });
    if (location.pathname === '/' || !isRouteMatched) {
      navigate(routeConfig.AUTH_LOGIN.path, { replace: true });
    }
  }, []);

  return (
    <>
      <LoginHeader />
      <Routes>
        {Object.values(routeConfig).map((route, index) => {
          return (
            <Route
              key={route.path + index}
              path={route.path}
              Component={withMotion(route.component) as FC}
            />
          );
        })}
        {PublicRoutes()}
      </Routes>
    </>
  );
};

export default AuthRoutes;
