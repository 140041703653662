'use client';
import View from 'components/core/View/View.view';
import { CommonModalRef } from 'components/modals/CommonModal/CommonModal.props';
import Questions from 'components/modules/Questions';
import CommonContainer from 'components/primitives/CommonContainer';
import { forwardRef } from 'react';
import { ProductQuestionsViewProps } from './ProductQuestions.props';

const ProductQuestionsView = forwardRef<
  CommonModalRef,
  ProductQuestionsViewProps
>(({ product }) => {
  return (
    <View.Container>
      <CommonContainer>
        <Questions
          product={product}
          hideSeeAll
          showAllQuestions
          enableBackButton
        />
      </CommonContainer>
    </View.Container>
  );
});

export default ProductQuestionsView;
