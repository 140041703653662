'use client';

import { FC } from 'react';

import { withMotion } from 'utils';

import { ErrorFallbackProps } from './ErrorFallback.props';

const ErrorFallback: FC<ErrorFallbackProps> = ({
  statusCode = 500,
  message = 'Something went wrong',
}) => {
  return (
    <div className="flex z-10 justify-center items-center h-screen bg-red-200">
      <div className="w-full max-w-2xl bg-white p-10 rounded-xl shadow-md flex justify-center items-center space-x-6">
        <h1 className="text-3xl font-bold">{statusCode}</h1>
        <p className="text-center text-2xl font-bold text-red-500">
          {String(message)}
        </p>
      </div>
    </div>
  );
};

export default withMotion(ErrorFallback);
