import { getStorageURL } from 'api/services/instance';
import images from 'assets/images';

export const getNetworkImage = (path?: string) => {
  const storageUrl = getStorageURL();
  if (path) {
    return storageUrl + path;
  } else {
    return images.placeholderImage;
  }
};
