import { useQuery } from '@tanstack/react-query';
import { fetchItem } from 'api/services/item';
import { fetchRenterReviewRates } from 'api/services/rates';

const FETCH_RENTER_REVIEW_RATES = 'fetch-renter-review-rates';

const useRenterReviewRatesQuery = () => {
  const query = useQuery({
    queryKey: [FETCH_RENTER_REVIEW_RATES],
    queryFn: () => fetchRenterReviewRates(),
  });

  return query;
};

export default useRenterReviewRatesQuery;

export { FETCH_RENTER_REVIEW_RATES };
