import React, { FC } from 'react';

import { ProductReviewsSectionProps } from './ProductReviewsSection.props';
import Loading from 'components/primitives/Loading';
import rentalColors from 'styles/colors';
import useItemReviews from 'hooks/useItemReviews';
import FormikRating from 'components/form/FormikRating';
import { ReviewHelper } from 'helperClasses/reviews';
import LinearPercentage from 'components/primitives/LinearPercentage';
import Star from 'components/icons/Star';
import images from 'assets/images';
import { getNetworkImage } from 'utils/assets';
import ReviewsNotFound from 'components/vectors/ReviewsNotFound';

const ProductReviewsSectionView: FC<ProductReviewsSectionProps> = ({
  productId,
}) => {
  const { reviews, isLoading } = useItemReviews(productId);
  if (isLoading) return <Loading />;

  const reviewHelper = ReviewHelper;
  const averageRate = reviewHelper.findAverageRate(reviews);
  const ratingPercentageMap = reviewHelper.getRatingPercentage(reviews);
  const rateNumbers = reviewHelper.rateNumbers;
  const zeroReviews = reviews?.length == 0;

  if (productId === 'undefined') return null;
  return (
    <div className="w-full space-y-8">
      <div className="flex flex-row gap-2">
        <h1>Reviews</h1>
      </div>

      {zeroReviews ? (
        <div className="flex justify-center items-center gap-[10%]">
          <div className="space-y-4">
            <h3 className="font-[500] leading-5">
              No reviews or ratings have
              <br /> been submitted for this item.
            </h3>
            <h3 className="text-secondary-900 font-[700]">
              Be the first
              <br /> to share your feedback!
            </h3>
          </div>
          <ReviewsNotFound />
        </div>
      ) : (
        <div className="grid lg:grid-cols-12 gap-8">
          <div className="lg:col-span-3 space-y-4">
            <div className="flex items-center gap-2">
              <h1
                style={{
                  color: rentalColors.gray[500],
                }}
                className="text-[28px] font-[600]">
                {averageRate.toFixed(1)}
              </h1>
              <FormikRating.RatingWidget
                size="16"
                value={averageRate != null ? Math.round(averageRate) : 0}
              />
            </div>
            <div>
              {rateNumbers.map((num, index) => {
                return (
                  <div
                    className="flex gap-3 items-center"
                    key={`_rates_${index}`}>
                    <LinearPercentage
                      percent={ratingPercentageMap.get(num) ?? 0}
                    />
                    <span className="text-lg text-[#a6a6a6] font-[600]">
                      {num}
                    </span>
                    <Star size="30" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="lg:col-span-9 space-y-4 max-h-60 overflow-y-scroll">
            {reviews?.map((reviewData, index) => {
              const reviewHelper = new ReviewHelper(reviewData);
              const review = reviewHelper.data;
              return (
                <div className="space-y-2" key={`_review_${index}`}>
                  <div className="flex gap-4 items-start text-md justify-between">
                    <div className="flex gap-4 items-start">
                      <div className="avatar placeholder">
                        <div className="avatar">
                          <div className="w-8 rounded-full">
                            <img
                              src={
                                review?.reviewerDetails?.avatar != null
                                  ? getNetworkImage(
                                      review?.reviewerDetails?.avatar,
                                    )
                                  : images.defaultAvatar
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="font-bold text-lg capitalize">
                          {review?.reviewerDetails?.displayName ?? 'User Name'}
                        </div>
                        <p className="">{review.description}</p>
                      </div>
                    </div>
                    <div>{reviewHelper.formatedCreationDate()}</div>
                  </div>
                  <div className="divider divider-end" />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductReviewsSectionView;
