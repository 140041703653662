import React from 'react';
import { IconProps } from './type';

const CertificateIcon = (props: IconProps) => {
  const { w, h } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${w ? w : '37'}`}
      height={`${h ? h : '37'}`}
      fill="none"
      viewBox="0 0 37 37">
      <g>
        <g>
          <path
            fill="#A6A6A6"
            d="M32.056 5.27H4.303a.957.957 0 00-.957.957v19.14c0 .528.428.957.957.957h27.753a.957.957 0 00.957-.957V6.227a.957.957 0 00-.957-.957z"></path>
          <path
            fill="#fff"
            d="M31.099 9.098v13.398l-1.914 1.914H7.174L5.26 22.496V9.098l1.914-1.914h22.01L31.1 9.098z"></path>
          <path
            fill="#fff"
            d="M29.185 22.5a1.436 1.436 0 00-.853-1.31 1.432 1.432 0 00-1.31-2.269 1.433 1.433 0 00-2.269-1.31 1.432 1.432 0 00-2.62 0 1.432 1.432 0 00-2.269 1.31 1.431 1.431 0 00-1.31 2.268 1.433 1.433 0 000 2.62c-.129.18-.215.387-.252.605h10.282a1.446 1.446 0 00-.252-.604 1.435 1.435 0 00.853-1.31z"></path>
          <path
            fill="#fff"
            d="M5.26 22.497l1.914 1.914h4.2a9.585 9.585 0 01-6.114-6.114v4.2z"></path>
          <path
            fill="#22CC7A"
            d="M27.75 33.027l-3.35-1.914-3.35 1.914V22.5h6.7v10.527z"></path>
          <path
            fill="#22CC7A"
            d="M27.75 22.5h-6.7v5.476a1.418 1.418 0 001.272.643c.277-.015.544-.11.768-.273a1.432 1.432 0 002.62 0 1.435 1.435 0 001.561.084c.193-.112.357-.267.479-.454V22.5z"></path>
          <path
            fill="#FFCB45"
            d="M30.142 22.5a1.436 1.436 0 00-.853-1.31 1.431 1.431 0 00-1.31-2.269 1.432 1.432 0 00-2.269-1.31 1.432 1.432 0 00-2.62 0 1.432 1.432 0 00-2.269 1.31 1.431 1.431 0 00-1.31 2.268 1.433 1.433 0 000 2.62 1.432 1.432 0 001.31 2.27 1.432 1.432 0 002.269 1.31 1.433 1.433 0 002.62 0 1.431 1.431 0 002.269-1.31 1.432 1.432 0 001.31-2.27 1.435 1.435 0 00.853-1.31z"></path>
          <path
            fill="#FFCB45"
            d="M24.4 25.848a3.35 3.35 0 100-6.7 3.35 3.35 0 000 6.7z"></path>
          <path
            fill="#FFCB45"
            d="M25.834 24.41a3.347 3.347 0 01-2.974-4.887 3.35 3.35 0 104.511 4.512 3.332 3.332 0 01-1.537.376z"></path>
          <path
            fill="#682082"
            d="M32.056 4.793H4.303a1.436 1.436 0 00-1.436 1.435v19.14a1.436 1.436 0 001.436 1.436h16.131c.036.112.082.22.138.323v5.898a.477.477 0 00.718.415l3.11-1.778 3.11 1.778a.48.48 0 00.718-.415v-5.898c.055-.103.102-.211.138-.323h3.69a1.435 1.435 0 001.435-1.435V6.229a1.436 1.436 0 00-1.435-1.436zm-10.76 21.334a.48.48 0 00-.526-.527.954.954 0 01-.872-1.512.478.478 0 00-.191-.718.954.954 0 010-1.745.478.478 0 00.191-.718.954.954 0 01.872-1.512.479.479 0 00.527-.526.954.954 0 011.512-.873.48.48 0 00.718-.192.953.953 0 011.744 0 .478.478 0 00.718.192.955.955 0 011.512.873.479.479 0 00.526.526.954.954 0 01.873 1.512.479.479 0 00.191.718.954.954 0 010 1.745.478.478 0 00-.191.718.954.954 0 01-.873 1.512.479.479 0 00-.526.527.955.955 0 01-1.512.872.478.478 0 00-.718.192.953.953 0 01-1.744 0 .478.478 0 00-.72-.193.953.953 0 01-1.511-.873v.002zM30.6 22.32a1.908 1.908 0 00-.682-1.301 1.907 1.907 0 00-1.478-2.561 1.907 1.907 0 00-2.561-1.479 1.91 1.91 0 00-2.958 0 1.907 1.907 0 00-2.561 1.479 1.908 1.908 0 00-1.479 2.561 1.9 1.9 0 00-.042 2.914H7.372L5.738 22.3V9.298l1.634-1.634h21.615l1.633 1.634v13.001l-.02.02zm-5.96 8.375a.479.479 0 00-.476 0L21.53 32.2v-4.21c.444.183.94.193 1.392.027a1.908 1.908 0 002.958 0c.45.166.948.156 1.392-.028v4.21l-2.632-1.504zm7.894-5.326a.479.479 0 01-.478.478H29.61a1.95 1.95 0 00.177-.24c.23-.402.304-.876.209-1.33l1.441-1.441c.09-.09.14-.211.14-.338V9.099a.479.479 0 00-.14-.338l-1.914-1.914a.479.479 0 00-.338-.14H7.174a.479.479 0 00-.339.14L4.921 8.761a.479.479 0 00-.14.339v13.398c0 .127.05.248.14.338l1.914 1.914c.09.09.212.14.339.14H18.78c.028.252.107.497.232.718.053.084.112.164.177.24H4.303a.479.479 0 01-.479-.48V6.229a.479.479 0 01.479-.478h27.753a.478.478 0 01.478.478v19.14z"></path>
          <path
            fill="#682082"
            d="M24.4 26.328a3.828 3.828 0 100-7.656 3.828 3.828 0 000 7.656zm0-6.7a2.871 2.871 0 110 5.744 2.871 2.871 0 010-5.743z"></path>
          <path
            fill="#682082"
            d="M16.744 21.063h-6.7a.479.479 0 100 .957h6.7a.478.478 0 100-.957z"></path>
          <path
            fill="#682082"
            d="M18.658 18.191h-8.613a.478.478 0 100 .957h8.613a.478.478 0 100-.957z"></path>
          <path
            fill="#682082"
            d="M21.529 15.32H10.045a.478.478 0 100 .957h11.484a.478.478 0 100-.957z"></path>
          <path
            fill="#682082"
            d="M26.314 12.45h-16.27a.478.478 0 100 .956h16.27a.479.479 0 000-.957z"></path>
          <path
            fill="#682082"
            d="M14.83 10.535h6.7a.479.479 0 000-.957h-6.7a.479.479 0 100 .957z"></path>
        </g>
      </g>
    </svg>
  );
};

export default CertificateIcon;
