import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { FormikSelectionProps } from './FormikSelection.props';
import { useField } from 'formik';
import Loading from 'components/primitives/Loading';

type SelectionOptionProp = {
  key: string;
  onClick: () => void;
  isSelected: boolean;
  child: ReactNode;
};

const Item: FC<SelectionOptionProp> = ({
  onClick,
  isSelected = false,
  child,
}) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      className={`${isSelected ? 'border border-primary-500 bg-primary-100' : 'border border-[##EFEFEF] '} rounded-lg cursor-pointer px-4 py-2 text-sm text-[#6D7370] select-none`}>
      {child}
    </div>
  );
};

const FormikSelectionView: FC<FormikSelectionProps> & {
  Item: FC<SelectionOptionProp>;
} = ({ itemCount, name, isLoading, enableMultiSelect = false, builder }) => {
  const indices = Array(itemCount).fill(false);

  const [selectedIndices, setSelectedIndices] = useState<boolean[]>(indices);

  const [field, meta, helper] = useField(name);

  const handleClick = (index: number) => {
    const selectedItemIndices = Array.from(selectedIndices);
    if (!enableMultiSelect) {
      for (let i = 0; i < selectedItemIndices.length; i++) {
        selectedItemIndices[i] = false;
      }
    }
    //handle multiselection
    selectedItemIndices[index] = !selectedItemIndices[index];
    setSelectedIndices(selectedItemIndices);
    helper.setValue(selectedItemIndices);
  };

  if (isLoading && isLoading) return <Loading />;

  return (
    <div className="flex flex-wrap gap-4 justify-center">
      {selectedIndices.map((isSelected, index) => {
        return (
          <Item
            key={`${index}`}
            onClick={() => handleClick(index)}
            isSelected={isSelected}
            child={builder(index)}
          />
        );
      })}
    </div>
  );
};

FormikSelectionView.Item = Item;

export default FormikSelectionView;
