import { useQuery } from '@tanstack/react-query';
import { fetchTopCategories } from 'api/services/category';

const FETCH_TOP_CATEGORIES_KEY = 'fetch-top-categories';

const useTopCategoryQuery = () => {
  const query = useQuery({
    queryKey: [FETCH_TOP_CATEGORIES_KEY],
    queryFn: fetchTopCategories,
  });

  return query;
};

export default useTopCategoryQuery;

export { FETCH_TOP_CATEGORIES_KEY };
