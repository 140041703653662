import { FC } from 'react';
import Countdown from 'react-countdown';
import { CountDownTimerProps } from './CountDownTimer.props';

const CountDownTimerView: FC<CountDownTimerProps> = ({
  durationInMinutes = 0,
  fromDate,
  onComplete,
}) => {
  //add minutes to createdAt
  const date = fromDate ? new Date(fromDate) : new Date();
  date.setMinutes(date.getMinutes() + durationInMinutes);

  const formatTwoDigits = (num: number): string => {
    return num.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };

  return (
    <div>
      <Countdown
        onComplete={() => onComplete && onComplete()}
        date={date}
        renderer={({ hours, minutes, seconds, completed }) => {
          if (completed) {
            // Render a complete state
            return <div>Complete</div>;
          } else {
            // Render a countdown
            return (
              <span className=" text-secondary-900 font-semibold">
                {formatTwoDigits(minutes)}:{formatTwoDigits(seconds)}
              </span>
            );
          }
        }}
      />
    </div>
  );
};

export default CountDownTimerView;
