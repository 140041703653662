import { IconProps } from './type';

const AlertIcon = (props: IconProps) => {
  return (
    <svg
      width="42"
      height="92"
      viewBox="0 0 42 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8452 84.6868L11.8662 84.7078L11.888 84.7278C12.2296 85.0422 12.5848 85.3313 12.9531 85.5953C13.2978 86.0725 13.6873 86.5287 14.1218 86.9632L14.1427 86.9841L14.1645 87.0042C16.3558 89.0202 19.1037 89.9976 22.2982 89.9976C25.4273 89.9976 28.1085 89.0129 30.2253 86.9843L30.2362 86.9739L30.2469 86.9632C32.3439 84.8661 33.3952 82.2619 33.3952 79.242C33.3952 76.2267 32.3461 73.6514 30.2362 71.624C29.8763 71.265 29.4997 70.9372 29.107 70.6405C28.7677 70.1879 28.3852 69.7566 27.9597 69.3477C25.8481 67.2417 23.1636 66.2101 20.0216 66.2101C16.81 66.2101 14.0549 67.2369 11.8664 69.3376C9.74948 71.3666 8.69693 73.9454 8.69693 76.9657C8.69693 79.9856 9.74818 82.5898 11.8452 84.6868Z"
        fill="white"
        stroke="#682082"
      />
      <path
        d="M20.0216 86.2986C17.1383 86.2986 14.7481 85.426 12.8512 83.6808C11.0302 81.8598 10.1196 79.6214 10.1196 76.9657C10.1196 74.31 11.0302 72.1096 12.8512 70.3644C14.7481 68.5433 17.1383 67.6328 20.0216 67.6328C22.8291 67.6328 25.1433 68.5433 26.9644 70.3644C28.7854 72.1096 29.6959 74.31 29.6959 76.9657C29.6959 79.6214 28.7854 81.8598 26.9644 83.6808C25.1433 85.426 22.8291 86.2986 20.0216 86.2986Z"
        fill="#FF5959"
      />
      <path
        d="M31.0122 8.59766H9.89428L17.5434 54.4051H23.7881L31.0122 8.59766ZM4.03284 0.507812C1.56058 0.507812 -0.319717 2.72814 0.087471 5.16663L8.7535 57.0639C9.07464 58.987 10.7353 60.3977 12.6836 60.405C13.3675 61.6631 14.6988 62.4949 16.1987 62.4949H28.9971C30.9657 62.4949 32.6416 61.0626 32.9483 59.118L41.1329 7.22078C41.5149 4.79826 39.6472 2.60689 37.1972 2.59769C36.5244 1.36375 35.2165 0.507812 33.6819 0.507812H4.03284Z"
        fill="#682082"
      />
      <path
        d="M6.19873 7.16742L14.8591 58.1674C14.9408 58.6482 15.3573 59 15.845 59H28.6481C29.1392 59 29.5577 58.6433 29.6355 58.1584L37.8147 7.15835C37.9122 6.55066 37.4428 6 36.8273 6H7.18462C6.56544 6 6.09507 6.55698 6.19873 7.16742Z"
        fill="#FFFEFF"
      />
      <path
        d="M3.19873 5.16742L11.8591 56.1674C11.9408 56.6482 12.3573 57 12.845 57H25.6481C26.1392 57 26.5577 56.6433 26.6355 56.1584L34.8147 5.15835C34.9122 4.55066 34.4428 4 33.8273 4H4.18462C3.56544 4 3.09507 4.55698 3.19873 5.16742Z"
        fill="#FF5959"
      />
    </svg>
  );
};

export default AlertIcon;
