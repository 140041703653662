import React from 'react';

type LeftArrowIconProps = {
  fill?: string | undefined;
};

const LeftArrowIcon = (props: LeftArrowIconProps) => {
  const { fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="w-[20px] h-[20px] text-gray-800 dark:text-white"
      viewBox="0 0 8 14">
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 1L1.3 6.326a.91.91 0 000 1.348L7 13"></path>
    </svg>
  );
};

export default LeftArrowIcon;
