import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const DEFAULT_VALUE = {
  itemId: null as string | null | undefined,
  startDate: null as string | null | undefined,
  endDate: null as string | null | undefined,
};

const storage = createJSONStorage<any>(() => localStorage);

const checkoutInfoAtom = atomWithStorage<typeof DEFAULT_VALUE>(
  'rent-item-store',
  DEFAULT_VALUE,
  storage,
);

const useRentItemStore = () => {
  const atom = useAtom(checkoutInfoAtom);
  return atom;
};

export default useRentItemStore;
