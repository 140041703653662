import { format } from 'date-fns';
import { userItemReviews } from 'api/queries';

const useItemReviews = (productId: string) => {
  const { data, isLoading } = userItemReviews(productId);
  const reviews = data;
  return {
    reviews,
    isLoading,
  };
};

export default useItemReviews;
