import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createQuestion,
  deleteQuestion,
  getQuestionsByItemId,
  updateQuestionsByQuestionId,
} from 'api/services/questions';
import { QuestionPayload, UpdateQuestionParams } from 'types/question';

const FETCH_QUESTIONS_KEY = 'fetch-questions';

const useQuestions = (id: string, onSuccess?: () => void) => {
  const { data: questionsData, isLoading: isQuestionsLoading } = useQuery({
    queryKey: [FETCH_QUESTIONS_KEY, id],
    queryFn: () => getQuestionsByItemId(id),
  });

  const { mutate: createItemQuestion, isPending: isLoadingCreate } =
    useMutation({
      mutationFn: async (payload: QuestionPayload) => {
        const response = await createQuestion(payload);
        return response;
      },
      onSuccess: () => {
        if (onSuccess) {
          return onSuccess();
        }
      },
    });

  const { mutate: updateQuestion, isPending: isLoadingUpdate } = useMutation({
    mutationFn: async ({ payload, questionId }: UpdateQuestionParams) => {
      const response = await updateQuestionsByQuestionId(payload, questionId);
      return response;
    },
    onSuccess: () => {
      if (onSuccess) {
        return onSuccess();
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const { mutateAsync: mutateDelete, isPending: isLoadingDelete } = useMutation(
    {
      mutationFn: (questionId: string) => deleteQuestion(questionId),
      onSuccess: (data) => {
        if (onSuccess) {
          return onSuccess();
        }
      },
      onError: (error: Error) => {
        console.error('Error deleting item', error);
      },
    },
  );

  return {
    questionsData,
    isLoadingCreate,
    isLoadingUpdate,
    isQuestionsLoading,
    isLoadingDelete,
    createItemQuestion,
    mutateDelete,
    updateQuestion,
  };
};

export default useQuestions;

export { FETCH_QUESTIONS_KEY };
