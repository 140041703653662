import { useQuery } from '@tanstack/react-query';
import { fetchSubCategories } from 'api/services/subCategory';
import { Category } from 'types/category';
import { SubCategoriesResponse } from 'types/subCategory';

const FETCH_SUB_CATEGORIES_KEY = 'fetch-sub-categories-key';

type Payload = {
  onSuccess: (categories: SubCategoriesResponse) => void;
};
const useSubCategoryQuery = (categoryId?: string, payload?: Payload) => {
  return useQuery({
    queryKey: [FETCH_SUB_CATEGORIES_KEY, categoryId],
    queryFn: async () => {
      const response = await fetchSubCategories(categoryId);
      payload?.onSuccess && payload?.onSuccess(response);
      return response;
    },
  });
};

export default useSubCategoryQuery;

export { FETCH_SUB_CATEGORIES_KEY };
