import { IconProps } from './type';

const ArrowRight = (props: IconProps) => {
  return (
    <svg
      className="cursor-pointer"
      width={props.size ?? '20'}
      height={props.size ?? '20'}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.1001 11.2969L6.7001 6.69687C6.8001 6.59687 6.87076 6.48854 6.9121 6.37187C6.9541 6.25521 6.9751 6.13021 6.9751 5.99687C6.9751 5.86354 6.9541 5.73854 6.9121 5.62187C6.87076 5.50521 6.8001 5.39687 6.7001 5.29688L2.1001 0.696875C1.91676 0.513541 1.68343 0.421875 1.4001 0.421875C1.11676 0.421875 0.883431 0.513541 0.700098 0.696875C0.516765 0.880208 0.425097 1.11354 0.425097 1.39687C0.425097 1.68021 0.516765 1.91354 0.700098 2.09688L4.6001 5.99687L0.700098 9.89687C0.516765 10.0802 0.425097 10.3135 0.425097 10.5969C0.425097 10.8802 0.516765 11.1135 0.700098 11.2969C0.883431 11.4802 1.11676 11.5719 1.4001 11.5719C1.68343 11.5719 1.91676 11.4802 2.1001 11.2969Z"
        fill={props.color ?? '#075015'}
      />
    </svg>
  );
};

export default ArrowRight;
