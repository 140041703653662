import { FC } from 'react';
import NavbarLogo from 'components/primitives/NavbarLogo';
import HomeIcon from 'components/icons/HomeIcon';
import RentIcon from 'components/icons/RentIcon';
import MenuHeader from 'components/modules/MenuHeader';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useIsMobile from 'hooks/useIsMobile';
import LendIcon from 'components/icons/LendIcon';
import { $setting } from 'state/stores/settings';
import BetaFlag from 'components/icons/BetaFlag';
import { routesSettings } from 'constants/routes';
import { useHeaderContext } from 'state/contexts/headerContext';

//TODO: header mobile, handle isLoggedIn with API
const HeaderView: FC = () => {
  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path: string): boolean => {
    return location.pathname === path || location.pathname.includes(`${path}/`);
  };

  const isBeta = process.env.REACT_APP_IS_BETA;

  const navigateToHome = () => {
    if (isActive('/home')) return;
    navigate(routesSettings.HOME.path, {
      replace: true,
    });
  };

  const { isMainHeaderEnabled } = useHeaderContext();

  return (
    <>
      {isMobile ? null : isMainHeaderEnabled ? (
        <header className="h-16 border p-2 shadow-lg navbar px-24 z-10">
          <div className="navbar nav-pills flex justify-between">
            <div className="mr-4 relative">
              <div onClick={navigateToHome} className="cursor-pointer">
                <NavbarLogo />
              </div>
              {isBeta && (
                <div className="absolute -bottom-[2px] -left-[9px]">
                  <BetaFlag />
                </div>
              )}
            </div>
            <ul className="grid grid-flow-col gap-40">
              <li className="w-[4.375rem] h-6 font-sm">
                <Link
                  to="/home"
                  className={`nav-item nav-link inline-flex items-center gap-2`}
                  role="tab"
                  aria-label="/home">
                  <div className="w-6 h-6 relative flex-col justify-start items-start flex">
                    <HomeIcon fill={isActive('/home') ? '#22CC7A' : ''} />
                  </div>
                  <h6
                    className="text-neutral-400"
                    style={{ color: isActive('/home') ? '#22CC7A' : '' }}>
                    Home
                  </h6>
                </Link>
              </li>
              <li className="w-[4.375rem] h-6">
                <Link
                  to="/rent"
                  className={`nav-item nav-link inline-flex items-center gap-2`}
                  role="tab">
                  <div className="w-6 h-6 relative flex-col justify-start items-start flex">
                    <RentIcon fill={isActive('/rent') ? '#22CC7A' : ''} />
                  </div>
                  <h6
                    className="text-gray-400"
                    style={{ color: isActive('/rent') ? '#22CC7A' : '' }}>
                    Rent
                  </h6>
                </Link>
              </li>
              <li className="w-[4.375rem] h-6">
                <Link
                  to="/lend"
                  className={`nav-item nav-link inline-flex items-center gap-2`}
                  role="tab">
                  <div className="w-6 h-6 relative flex-col justify-start items-start flex">
                    <LendIcon fill={isActive('/lend') ? '#22CC7A' : ''} />
                  </div>
                  <h6
                    className="text-gray-400"
                    style={{ color: isActive('/lend') ? '#22CC7A' : '' }}>
                    Lend
                  </h6>
                </Link>
              </li>
            </ul>
            <div className="grid grid-flow-col gap-4">
              <MenuHeader />
            </div>
          </div>
        </header>
      ) : null}
    </>
  );
};

export default HeaderView;
