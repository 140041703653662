import parsePhoneNumberFromString from 'libphonenumber-js';
import * as Yup from 'yup';

const phoneRegExp = /^(\+1|1)?(\d{10})$/;

export const validationSchema = Yup.object().shape({
  fname: Yup.string().required('First name is required'),
  lname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .test('is-valid-phone', 'Phone number is not valid', function (value) {
      const { countrySymbol } = this.parent;
      if (!value || !countrySymbol || value.length < 7) return false;
      const phoneNumber = parsePhoneNumberFromString(value, countrySymbol);
      return phoneNumber && phoneNumber.isPossible() && phoneNumber.isValid();
    }),
  password: Yup.string()
    .required('Password is required')
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/,
      'Password too weak',
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,}$/,
      'Password too weak',
    )
    .required('Repeat password is required'),
});

export const initialValues = {
  fname: '',
  lname: '',
  email: '',
  phone: '',
  countryCode: '+1',
  countrySymbol: 'US',
  password: '',
  confirmPassword: '',
};
