// /location/get-states

import { useQuery } from '@tanstack/react-query';
import { fetchCities, fetchStates } from 'api/services/location';

const FETCH_STATES_KEY = 'fetch-states-key';
const FETCH_CITIES_BY_ID_KEY = 'fetch-cities-by-id-key';

const useStatesQuery = () => {
  const query = useQuery({
    queryKey: [FETCH_STATES_KEY],
    queryFn: fetchStates,
  });
  return query;
};

const useCitiesQuery = (stateId?: string) => {
  const query = useQuery({
    queryKey: [FETCH_CITIES_BY_ID_KEY, stateId],
    queryFn: () => fetchCities(stateId),
    enabled: !!stateId,
  });
  return query;
};

export default {
  useStates: useStatesQuery,
  useCitiesById: useCitiesQuery,
};
