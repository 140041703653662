import React, { FC } from 'react';

import NavbarLogo from 'components/primitives/NavbarLogo';

import { IntroContentProps } from './IntroContent.props';
import Button from 'components/primitives/Button';

const IntroContentView: FC<IntroContentProps> = (props) => {
  const { item, index, position, onNext, onPrev } = props;

  return (
    <div
      key={`${item.id}-${index}`}
      style={{ display: index === position ? 'block' : 'none' }}>
      <div
        id={item.id}
        className="carousel-item flex flex-col md:flex-row items-center">
        <img src={item.img} className="w-[80%] md:w-full" />
        <div className="mt-3 text-center justify-start">
          <div className="hidden md:flex">
            <NavbarLogo />
          </div>
          <p className="text-start text-neutral-700 text-xl font-semi-bold md:font-bold font-['Montserrat']">
            {item.title}
          </p>
          <div className="text-start mt-2 py-3">
            <p className="md:w-64 text-gray-500 text-sm font-secondary">
              {item.text}
            </p>
          </div>
          <div className="grid grid-flow-row gap-5 items-center px-4 py-3">
            <Button
              id={item.id}
              variant="solid"
              colorScheme="primary"
              onClick={() => onNext(index)}>
              {item.buttonContent1}
            </Button>
            {item.buttonContent2 ? (
              <Button
                id={item.id}
                variant="outline"
                colorScheme="primary"
                className="btn-var-outline"
                onClick={() => onPrev(index)}>
                <div className="text-center text-primary hover:text-white text-sm font-bold">
                  {item.buttonContent2}
                </div>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroContentView;
