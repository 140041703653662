import { $session } from 'state/stores';
import { UserProfile, UserProfileResponse } from 'types/userProfile';
import { api, getApiURL } from './instance';
import ImageHelper from 'helperClasses/ImageHelper';

export const fetchUserProfile = async (id: string) => {
  const { data } = await api.get<UserProfileResponse>(`/profile/user/${id}`);
  return data?.item;
};

export const fetchUsersProfiles = async () => {
  const { data } = await api.get<UserProfileResponse[]>(`/profile/`);

  return data;
};

export const patchUserProfile = async (userProfile: Partial<UserProfile>) => {
  const { _id, ...restUserProfile } = userProfile;

  const { data } = await api.patch<UserProfileResponse>(
    `/profile/${_id}`,
    restUserProfile,
  );

  return data?.item;
};

export const uploadProfileImage = (
  file: any,
  profileId?: string,
  onProgress?: (progress: number) => void,
) => {
  return new Promise<string>(async (resolve, reject) => {
    const formData = new FormData();

    if (file.size > 5 * 1024 * 1024) {
      reject(new Error('Image file size should be less than 5 MB'));
    }

    const fileToUpload = await ImageHelper.compress(file);

    //check if file is greater than 5 mb

    formData.append('image', fileToUpload);

    const URL = getApiURL();

    const jwtToken = $session.actions.getTokens()?.jwtToken || '';

    const url = `${URL}/profile/${profileId}/photo`;

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;

        onProgress?.(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        const response = JSON.parse(xhr.response);

        const file = response?.item as string;

        resolve(file);
      } else {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${
              JSON.parse(xhr.response)?.message
            }`,
          ),
        );
      }
    };

    xhr.send(formData);
  });
};
