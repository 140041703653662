import React, { FC, useRef, useState } from 'react';

import { introContent } from 'utils/mockData';

import { useSearchParams } from 'react-router-dom';

import CommonModal, { ModalRef } from '../CommonModal';
import IntroContent from 'components/modules/IntroContent';

import { IntroModalViewProps } from './IntroModal.props';

const IntroModalView: FC<IntroModalViewProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOnboarding = searchParams.get('onboarding') === 'true';

  const introModalRef = useRef<ModalRef>(null);

  const [position, setPosition] = useState(0);
  const isLastSetp = position + 1 === introContent.length;

  const onNext = (idx: number) => {
    if (isLastSetp) {
      searchParams.delete('onboarding');
      setSearchParams(searchParams);
    }

    setPosition(idx === introContent.length - 1 ? 0 : idx + 1);
  };

  const onPrev = (idx: number) => {
    setPosition(idx === introContent.length - 1 ? 0 : idx - 1);
  };

  return (
    <CommonModal
      ref={introModalRef}
      isCloseButton={false}
      isDefaultOpen={isOnboarding}
      className="w-11/12">
      <div className="carousel flex-1 min-h-[450px] flex flex-col items-center justify-between gap-5">
        <div className="mt-8">
          {introContent.map((item, index) => {
            return (
              <IntroContent
                key={`${item.id}-${index}`}
                item={item}
                index={index}
                position={position}
                onNext={onNext}
                onPrev={onPrev}
                isLastSetp={isLastSetp}
              />
            );
          })}
        </div>
        <div className="grid grid-flow-col gap-1 md:gap-2">
          {introContent.map((_, index) => (
            <div
              key={`${index}`}
              className="badge badge-xs bg-gray-300 border-none w-[15px] h-[15px]"
              style={{
                backgroundColor: index === position ? '#737373' : '#D9D9D9',
              }}
            />
          ))}
        </div>
      </div>
    </CommonModal>
  );
};

export default IntroModalView;
