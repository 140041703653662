import { Component, ErrorInfo, ReactNode } from 'react';
import { isDev } from 'utils/environment';

// TODO: Implement Fallback UI for Production

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage?: string;
  errorStack?: string;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
      errorStack: '',
    };
  }

  static getDerivedStateFromError(_: Error, errorInfo: ErrorInfo): State {
    // Update state so the next render will show the fallback UI.

    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service

    console.error(error, errorInfo);

    this.setState({
      errorMessage: error?.message,
      errorStack: errorInfo.componentStack ?? undefined,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          {isDev ? (
            <div className="grid h-screen place-items-center">
              <div className="card grid gap-4">
                <h1 className="text-red-500 text-center">Error Status</h1>
                <h2>{this.state.errorMessage}</h2>
                <pre>Component Stack:{this.state.errorStack}</pre>
              </div>
            </div>
          ) : (
            <>{/* SHOW A NICE FALLBACK UI for Deployed apps */}</>
          )}
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
