import React from 'react';
type RentIconProps = {
  fill?: string | undefined;
};

const LendIcon = (props: RentIconProps) => {
  const { fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <g>
        <mask
          id="mask0_1133_1080"
          style={{ maskType: 'alpha' }}
          width="24"
          height="24"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse">
          <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
        </mask>
        <g mask="url(#mask0_1133_1080)">
          <path
            fill={fill ? fill : '#A6A6A6'}
            d="M12.925 11.05L8.675 6.8 10.1 5.4l2.825 2.825 5.675-5.65 1.4 1.4-7.075 7.075zM14 22.5l-7-1.95V11h1.95l6.2 2.3c.55.2.996.55 1.338 1.05.341.5.512 1.05.512 1.65h-2c-.7 0-1.25-.025-1.65-.075-.4-.05-.75-.125-1.05-.225l-2-.65-.3.95 1.575.575c.467.183.892.3 1.275.35.383.05.833.075 1.35.075H19c1.1 0 1.875.18 2.325.538.45.358.675.845.675 1.462v1l-8 2.5zM1 22V11h4v11H1z"></path>
        </g>
      </g>
    </svg>
  );
};

export default LendIcon;
