import React, { FC, useState } from 'react';

import { TabsLayoutProps } from './TabsLayout.props';
import ProfileTabContentContainer from 'routes/main/Profile/profileTab/ProfileTabContent.container';

const TabsLayoutView: FC<TabsLayoutProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const getTabStyle = (tab: string) => ({
    borderBottom: activeTab === tab ? '2px solid #682082' : 'none',
  });
  return (
    <ProfileTabContentContainer />
    // <div className="w-full flex justify-center items-center mt-16 flex-col">
    //   <div role="tablist" className="tabs tabs-bordered flex flex-row">
    //     {tabs.map((tab) => (
    //       <a
    //         key={tab.id}
    //         role="tab"
    //         className={`tab w-[35rem] font-primary font-semibold ${activeTab === tab.id ? 'text-secondary-900' : 'text-gray-700'}`}
    //         style={getTabStyle(tab.id)}
    //         onClick={() => handleTabClick(tab.id)}>
    //         {tab.label}
    //       </a>
    //     ))}
    //   </div>

    //   {tabs.find((tab) => tab.id === activeTab)?.component}
    // </div>
  );
};

export default TabsLayoutView;
