import React, { FC, useState } from 'react';

import { LendTableProps } from './LendTable.props';

import LendTableView from './LendTable.view';
import $session from 'state/stores/session/store';
import { Product } from 'types/item';
import { useCategories, useOwnerItem } from 'api/queries';
import useCategoryQuery from 'api/queries/useCategoryQuery';
import { $stripe } from 'state/stores/stripe';
import UserLocationForm from '../UserLocationForm';
import { $user } from 'state/stores/user';
import useConnectToStripeMutation from 'api/mutations/useConnectToStripeMutation';

const LendTableContainer: FC<LendTableProps> = ({ searchQuery }) => {
  const ownerId = $session.actions.getUserId();
  const [page, setPage] = useState(1);
  let items: Product[] = [];
  const { data, isFetching } = useOwnerItem(ownerId!, {
    page,
  });

  const stripeId = $user.selectors.useStripeId();
  const isFetchingStripeStatus = $stripe.selectors.useIsFetchingData();

  const { mutate: connectStripeAccount, isPending } =
    useConnectToStripeMutation(stripeId!);

  items = data?.items ?? [];

  const isConnectedToStripe = $stripe.selectors.useIsDetailsSubmitted();
  const isAddressSet = $user.selectors.useIsAddressSet();

  const filteredItems = items?.filter((item) =>
    item.title.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()),
  );

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  if (!isConnectedToStripe) {
    return (
      <section className="">
        <div className="py-14">
          <h2 className="text-center pb-12 text-[24px]">
            Just two steps away to become an owner <br /> and start posting your
            items!
          </h2>
          <div className="md:flex h-full justify-center items-start sm:px-10 md:px-18 lg:px-52">
            <div className="w-full flex flex-col justify-center items-center px-10 space-y-4">
              <h4 className="text-center text-[18px]">1. Add your city</h4>
              <p className="text-center px-8 text-[#848484] text-[14px]">
                This data will be used for sending merch, and as a default
                public meeting location. You can change it later.
              </p>
              <UserLocationForm hideLabels />
            </div>
            <div className="w-[4px] h-[139px] mx-10 bg-gray-200 hidden md:block"></div>

            <div
              className={
                isAddressSet
                  ? 'w-full'
                  : 'pointer-events-none opacity-50 w-full'
              }>
              <div className="w-full flex flex-col justify-center items-center px-10 space-y-4">
                <h4 className="text-center text-[18px]">
                  2. Connect your Stripe account
                </h4>
                <p className="text-center px-8 text-[#848484] text-[14px]">
                  To ensure our platform is safe and secure, all payment
                  information is handled by Stripe. Additional identity
                  verification my be required by regulatory obligations.
                </p>
                <button
                  onClick={() => connectStripeAccount()}
                  className="btn bg-indigo-600 text-white rounded-lg border-none hover:bg-secondary-900">
                  Connect Stripe account
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section>
      <LendTableView
        totalPages={data?.totalPages}
        currentPage={page}
        products={items}
        isLoading={isFetching}
        setPage={setCurrentPage}
        filteredItems={filteredItems}
      />
    </section>
  );
};

export default LendTableContainer;
