import React, { FC } from 'react';

import { BlurProps } from './Blur.props';

const BlurView: FC<BlurProps> = ({ children, hintTitle, messageChild }) => {
  return (
    <div className="w-full h-full relative">
      {children}
      <div className="w-full h-[80%] bg-[#ffffff89] backdrop-blur-[3px] absolute bottom-0 flex justify-center items-center">
        <span className="font-bold text-gray-600 text-3xl">
          {messageChild && messageChild}
          {(messageChild && !messageChild && hintTitle) ?? 'Coming soon'}
        </span>
      </div>
    </div>
  );
};

export default BlurView;
