import styled from '@emotion/styled';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export const CustomCalendar = styled(Calendar)`
  ${({ brandColor }: { brandColor?: string }) => `
  min-width: 250px;
  width: 100% !important;
  border: none !important;
  color: #000;
  

  .react-calendar__navigation {
    position: relative;
  }

  .react-calendar__navigation__arrow {
    position: absolute;
    align-items: center;
    justify-content: center;
    min-width: auto !important;
  }
  
  
  .react-calendar__navigation__prev-button {
    top: 5px;
    right: 40px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .react-calendar__navigation__next-button {
    top: 5px;
    right: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .react-calendar__navigation__label {
    text-align: left;
    padding-left: 15px;
    font-weight: bold;
  }

  abbr[title] {
    color: #92929D;
    font-size: 12px;
    text-decoration: none;
  }

  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr); /* 7 equally spaced columns */
    justify-items: center; 
    align-items: center;
    row-gap: 10px;
  }


.react-calendar__tile--rangeStart {
    height: 40px;
    background: ${brandColor};
    min-width: 40px !important;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 40px;
  }
  
  .react-calendar__tile--rangeEnd {
    height: 40px;
     background: ${brandColor};
    min-width: 40px !important;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 40px;
  }

  .react-calendar__tile {
    height: 40px;
    min-width: 40px !important;
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: 40px;

    &:enabled:focus {
      background: ${brandColor};
      color: #fff;
      border-radius: 40px;
    }

    &:enabled:hover {
      background: #fff;
      border-radius: 40px;
      border: 1px solid ${brandColor};
      color: navy;
    }

    @media (max-width: 400px) {
      height: 35px;
    }
  }

  .react-calendar__tile--now {
    background: #fff;
    font-weight: bold;
    color: ${brandColor} !important;
    border-radius: 40px;

    abbr {
      border-bottom: 2px solid ${brandColor};
    }

    &:not(:enabled) {
      &:hover {
        background: #fff !important;
      }
    }
  }

  .react-calendar__tile--active {
    background: ${brandColor} !important;
    color: #fff !important;
    border-radius: 40px;

    &:hover {
      background: ${brandColor};
      color: #fff;
      border-radius: 40px;
    }
  }

  .react-calendar__month-view__days__day[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 0px !important;
  }
  `}
`;
