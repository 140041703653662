import { FC } from 'react';

import images from 'assets/images';
import ShieldIcon from 'components/icons/ShieldIcon';
import { useRentItemStore } from 'state/atoms';
import { getNetworkImage } from 'utils/assets';
import { simplifyDate } from 'utils/date';
import { calculatePrice } from 'utils/product';
import { TotalPurchaseProps } from './TotalPurchase.props';

const TotalPurchaseView: FC<TotalPurchaseProps> = ({ product }) => {
  var [{ startDate, endDate }] = useRentItemStore();

  const startDateTime = new Date(startDate!);
  const endDateTime = new Date(endDate!);

  const { tax, actualRentalPrice, totalPrice, daysDifference } = calculatePrice(
    product?.price!,
    startDateTime,
    endDateTime,
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-5 items-center justify-between">
        <div className="flex gap-5 items-center">
          <img
            className="w-12 h-12 rounded object-cover"
            src={getNetworkImage(product?.featuredPhoto ?? product?.photos[0])}
          />
          <div className="flex flex-col">
            <h5 className="capitalize">{product?.title}</h5>
            <div className="text-gray-700 text-sm font-normal font-secondary leading-tight">
              {`Rental duration from ${simplifyDate(startDateTime.toISOString())} to ${simplifyDate(endDateTime.toISOString())}`}
            </div>
          </div>
        </div>
        <div className="text-primary-500 text-base font-semibold font-primary">
          ${product?.price}/day x {daysDifference} days
        </div>

        <div className="text-right text-gray-700 text-base font-semibold font-primary">
          ${actualRentalPrice}
        </div>
      </div>
      <div className="divider" />
      <div className="flex gap-5">
        <ShieldIcon />
        <div className="w-full">
          <div className="text-neutral-700 text-base font-semibold font-primary">
            {product?.title} Security Deposit
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-neutral-700 text-sm font-normal font-['Open Sans'] leading-tight">
                Security hold deposit for security reasons.{' '}
              </span>
            </div>
            <div className="text-right text-neutral-700 text-base font-semibold font-['Montserrat']">
              $50
            </div>
          </div>
        </div>
      </div>

      <div className="divider" />
      <div className="flex gap-4">
        <img
          src={images.taxAndServices}
          alt=""
          style={{
            width: '56px',
            height: '56px',
          }}
        />
        <div className="w-full">
          <div className="text-neutral-700 text-base font-semibold font-['Montserrat']">
            Tax and Services
          </div>
          <span className="text-neutral-700 text-sm font-normal font-['Open Sans'] leading-tight">
            This fee covers all state and local taxes, credit card processing
            fees, as well as Rapid Rentals Protection Plan (RRPP)
          </span>
          <div className="text-right text-neutral-700 text-base font-semibold font-['Montserrat']">
            ${tax.toFixed(1)}
          </div>
        </div>
      </div>
      <div className="divider" />

      <div className="text-right text-neutral-700 text-2xl font-bold font-['Montserrat']">
        Total: ${totalPrice.toFixed(1)}
      </div>
    </div>
  );
};

export default TotalPurchaseView;
