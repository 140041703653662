import { FetchItemsParams } from 'api/queries/useItemsQuery';
import { OwnerItemOptions } from 'api/queries/useOwnerItemQuery';
import { $session } from 'state/stores';
import {
  ItemPayload,
  ItemResponse,
  ItemsResponse,
  OwnerItemsResponse,
} from 'types/item';
import { api, getApiURL } from './instance';

const URL = `${process.env.REACT_APP_API_URL}`;

export const fetchItems = async ({
  excludeOwnerItems = true,
  limit = 100,
  page = 1,
}: FetchItemsParams = {}) => {
  const { data } = await api.get<ItemsResponse>(`/items`, {
    params: {
      excludeOwnerItems,
      limit,
      page,
    },
  });
  return data;
};

export const fetchItem = async (id: string) => {
  const { data } = await api.get<ItemResponse>(`/items/${id}`);
  return data?.item;
};

export const deleteItem = async (id: string) => {
  const { data } = await api.delete(`${URL}/items/${id}`);
  return data?.item;
};

export const createItem = async (payload: ItemPayload) => {
  const { data } = await api.post<ItemResponse>(`/items`, payload);
  return data?.item;
};

export const updateItem = async (id: string, payload: ItemPayload) => {
  const { data } = await api.patch<ItemResponse>(`/items/${id}`, payload);
  return data?.item;
};

export const fetchItemsByOwnerId = async (
  id: string,
  options: OwnerItemOptions = { limit: 10, page: 1 },
) => {
  const { data } = await api.get<OwnerItemsResponse>(
    `/items/findByOwner/${id}`,
    {
      params: {
        ...options,
      },
    },
  );
  return data;
};

export const uploadItemImages = (
  files: File[],
  onProgress?: (progress: number) => void,
) => {
  return new Promise<string[]>((resolve, reject) => {
    if (files === undefined || files.length === 0) {
      Promise.resolve([]);
    }

    const formData = new FormData();

    files.forEach((file) => {
      formData.append('images', file);
    });

    const URL = getApiURL();

    const jwtToken = $session.actions.getTokens()?.jwtToken || '';

    const url = `${URL}/items/photos`;

    const xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${jwtToken}`);

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;

        onProgress?.(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        const response = JSON.parse(xhr.response);

        const files = response?.item as string[];

        resolve(files);
      } else {
        reject(
          new Error(
            `Request failed. Status: ${xhr.status}. Content: ${
              JSON.parse(xhr.response)?.message
            }`,
          ),
        );
      }
    };

    xhr.send(formData);
  });
};
