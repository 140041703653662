import React from 'react';

import * as Yup from 'yup';

import CertificateIcon from 'components/icons/CertificateIcon';
import LockerIcon from 'components/icons/LockerIcon';
import MedalIcon from 'components/icons/MedalIcon';
import PhoneCallIcon from 'components/icons/PhoneCallIcon';
import SearchIcon from 'components/icons/SearchIcon';
import TrophyIcon from 'components/icons/TrophyIcon';
import WingShieldIcon from 'components/icons/WingsShieldIcon';
import WorkerIcon from 'components/icons/WorkerIcon';

import ErrorIcon from 'components/icons/ErrorIcon';
import CheckCircleIcon from 'components/icons/CheckCircleIcon';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().matches(
    /^(\(\d{3}\) \d{7}|\d{10})$/,
    'Please enter a valid phone number',
  ),
});
export const badgesContent = [
  {
    icon: <WingShieldIcon w="4rem" h="4rem" />,
    title: 'Rental Expert',
  },
  {
    icon: <MedalIcon w="4rem" h="4rem" />,
    title: 'Customer Trust',
  },
  {
    icon: <CertificateIcon w="4rem" h="4rem" />,
    title: 'Enterprising Starter',
  },
  {
    icon: <TrophyIcon w="4rem" h="4rem" />,
    title: 'Efficient Manager',
  },
];

export const securityContent = [
  {
    icon: <LockerIcon w="4rem" h="4rem" />,
    title: `2-Step Verification`,
  },
  {
    icon: <SearchIcon w="4rem" h="4rem" />,
    title: `Recovery Email`,
  },
  {
    icon: <PhoneCallIcon w="4rem" h="4rem" />,
    title: `Recovery Phone`,
  },
  {
    icon: <WorkerIcon w="4rem" h="4rem" />,
    title: `Confirmed indentity`,
  },
];

export const stripeInfoContent = [
  {
    title: 'Strippe Connect',
    text: 'You need to connect Stripe so we can send you payments, please click the button and set up Stripe',
    icon: () => <ErrorIcon />,
    buttonText: 'Connect Stripe Account',
  },
  {
    title: 'Strippe Connect',
    text: 'You need to connect Stripe so we can send you payments, please click the button and set up Stripe',
    icon: () => <CheckCircleIcon />,
    buttonText: 'Configure Stripe Account',
  },
];
